export const DistrictInitialStates = {
  isLoading: false,
  isAdded: false,
  isSubmitting: false,
  error: false,
  districts: [],
  districtData: {},
  districtOptions: [],
  totalRecords: 0,
};
