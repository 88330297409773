/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import {
  programAddSuccess,
  programAddFailed,
  ProgramActions,
  programListSuccess,
  programListFailed,
  programOptionListFailed,
  programOptionListSuccess,
  viewProgramFailed,
  viewProgramSuccess,
  updateProgramSuccess,
  updateProgramFailed,
  requestProgramList,
  updateProgramStatusSuccess,
  failedDeleteProgram,
} from '../actions/Program';
import { showError, showSuccess } from '../../helpers/Toast';

/** @format */

const addProgramLogic = createLogic({
  type: ProgramActions.ADD_PROGRAM_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PROGRAM.service,
      ApiRoutes.ADD_PROGRAM.url,
      ApiRoutes.ADD_PROGRAM.method,
      ApiRoutes.ADD_PROGRAM.authenticate,
      undefined,
      action.payload,
    );
    if (!isError) {
      dispatch(programAddSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(programAddFailed());
      done();
    }
  },
});
/**
 *
 */
const getProgramsLogic = createLogic({
  type: ProgramActions.PROGRAM_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROGRAMS.service,
      ApiRoutes.GET_PROGRAMS.url,
      ApiRoutes.GET_PROGRAMS.method,
      ApiRoutes.GET_PROGRAMS.authenticate,
      action.payload,
      undefined,
    );
    if (isError) {
       if (action.payload.search !== '') {
         showError('Invalid search');
       } else {
         showError(messages[0]);
       }
      dispatch(programListFailed());
      done();
    }

    dispatch(
      programListSuccess({
        programs: responseData.data,
        totalRecords: responseData.totalRecords,
      }),
    );
    done();
  },
});
/**
 *
 */
const viewProgramLogic = createLogic({
  type: ProgramActions.VIEW_PROGRAM_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_PROGRAM.service,
      ApiRoutes.VIEW_PROGRAM.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_PROGRAM.method,
      ApiRoutes.VIEW_PROGRAM.authenticate,
      undefined,
      undefined,
    );
    if (isError) {
      showError(messages[0]);
      dispatch(viewProgramFailed());
      done();
    }

    dispatch(
      viewProgramSuccess({
        programData: responseData.data,
      }),
    );
    done();
  },
});
/**
 *
 */
const getProgramOptionsListLogic = createLogic({
  type: ProgramActions.PROGRAM_OPTION_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.PROGRAM_OPTIONS_LIST.service,
      ApiRoutes.PROGRAM_OPTIONS_LIST.url,
      ApiRoutes.PROGRAM_OPTIONS_LIST.method,
      ApiRoutes.PROGRAM_OPTIONS_LIST.authenticate,
      action.payload,
      undefined,
    );
    if (isError) {
      showError(messages[0]);
      dispatch(programOptionListFailed());
      done();
    }

    dispatch(
      programOptionListSuccess({
        programOptions: responseData.data,
      }),
    );
    done();
  },
});
/**
 *
 */
const updateProgramLogic = createLogic({
  type: ProgramActions.UPDATE_PROGRAM_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROGRAM.service,
      ApiRoutes.UPDATE_PROGRAM.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_PROGRAM.method,
      ApiRoutes.UPDATE_PROGRAM.authenticate,
      undefined,
      action.payload.values,
    );
    if (!isError) {
      dispatch(updateProgramSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateProgramFailed());
      done();
    }
  },
});

/**
 ---------------------------
    Delete DISTRICT RECORDS
 ---------------------------
 */

const deleteProgramsLogic = createLogic({
  type: ProgramActions.DELETE_PROGRAM_RECORD_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    let serviceAndUrl = {
      service: ApiRoutes.DELETE_PROGRAM.service,
      url: ApiRoutes.DELETE_PROGRAM.url.replace(':id', action.payload.id),
    };
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      serviceAndUrl.service,
      serviceAndUrl.url,
      ApiRoutes.DELETE_PROGRAM.method,
      ApiRoutes.DELETE_PROGRAM.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(requestProgramList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(failedDeleteProgram({ validationErrors }));
      done();
    }
  },
});


/**
 *
 */
const deleteProgramLogic = createLogic({
  type: ProgramActions.DELETE_PROGRAM_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_PROGRAM.service,
      ApiRoutes.DELETE_PROGRAM.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_PROGRAM.method,
      ApiRoutes.DELETE_PROGRAM.authenticate,
      undefined,
      undefined,
    );
    if (isError) {
      dispatch(viewProgramFailed(messages[0]));
      done();
    }
    dispatch(requestProgramList());
    dispatch(messages[0]);
    done();
  },
});

/**
 *
 */
const updateProgramStatusLogic = createLogic({
  type: ProgramActions.UPDATE_PROGRAM_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROGRAM_STATUS.service,
      ApiRoutes.UPDATE_PROGRAM_STATUS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_PROGRAM_STATUS.method,
      ApiRoutes.UPDATE_PROGRAM_STATUS.authenticate,
      undefined,
      { status: action.payload.status },
    );
    if (isError) {
      dispatch(viewProgramFailed(messages[0]));
      done();
    }
    dispatch(
      requestProgramList({
        ...action.payload.query,
      }),
    );
    dispatch(updateProgramStatusSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const ProgramLogics = [
  addProgramLogic,
  getProgramsLogic,
  viewProgramLogic,
  updateProgramLogic,
  getProgramOptionsListLogic,
  deleteProgramLogic,
  deleteProgramsLogic,
  updateProgramStatusLogic,
];
