/** @format */

import { handleActions } from 'redux-actions';
import { FreeDemoActions } from '../actions';
import { FreeDemoInitialStates } from '../states/FreeDemo';

export const FreeDemoReducer = handleActions(
  {
    [FreeDemoActions.REQUEST_FREE_DEMO_LIST]: (
      state = FreeDemoInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [FreeDemoActions.FREE_DEMO_LIST_SUCCESS]: (
      state = FreeDemoInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      freeDemoList: action.payload.freeDemoList,
      totalRecords: action.payload.totalRecords,
    }),
    [FreeDemoActions.FREE_DEMO_LIST_FAILURE]: (
      state = FreeDemoInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [FreeDemoActions.VIEW_FREE_DEMO_REQUEST]: (
      state = FreeDemoInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [FreeDemoActions.VIEW_FREE_DEMO_SUCCESS]: (
      state = FreeDemoInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      freeDemoData: action.payload.freeDemoData,
    }),
    [FreeDemoActions.VIEW_FREE_DEMO_FAILURE]: (
      state = FreeDemoInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  FreeDemoInitialStates
);
