export const LinkageReqInitialStates = {
  isLoading: false,
  isUpdated: false,
  isSubmitting: false,
  requestListed: false,
  error: false,
  requests: [],
  requestData: {},
  organizations: [],
  linkedOrganizations: [],
};
