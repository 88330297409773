/** @format */

import { handleActions } from 'redux-actions';
import { SupportActions } from '../actions';
import { SupportInitialStates } from '../states/Support';

export const SupportReducer = handleActions(
  {
    [SupportActions.REQUEST_SUPPORTS_LIST]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SupportActions.SUPPORT_LIST_SUCCESS]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      supportList: action.payload.supportList,
      totalRecords: action.payload.totalRecords,
    }),
    [SupportActions.SUPPORT_LIST_FAILURE]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [SupportActions.VIEW_SUPPORT_REQUEST]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SupportActions.VIEW_SUPPORT_SUCCESS]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      supportData: action.payload.supportData,
    }),
    [SupportActions.VIEW_SUPPORT_FAILURE]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [SupportActions.SUPPORT_STATUS_UPDATE_REQUEST]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [SupportActions.SUPPORT_STATUS_UPDATE_SUCCESS]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
    [SupportActions.SUPPORT_STATUS_UPDATE_FAILED]: (
      state = SupportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  SupportInitialStates
);
