import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestStatesList } from './redux/actions';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const { statesOptions } = useSelector((state) => state.geoGraphicsReducer);

  const checkAuth = (countryID) => {
    let statesOpt = [];
    statesOptions.forEach(({ _id, name, countryid }) => {
      if (parseInt(countryid) === parseInt(countryID)) {
        statesOpt.push({
          label: name,
          value: parseInt(_id),
        });
      }
    });
    return statesOpt;
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestStatesList());
  }, []);

  const value = {
    checkAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
