import { createLogic } from 'redux-logic';

import {
  NotificationActions,
  notificationListSuccess,
  notificationListFailed,
  updateNotificationCountSuccess,
  updateNotificationCountFailed,
  updateNotificationStatusSuccess,
  updateNotificationStatusFailed,
} from '../actions';
import { showError } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

const getNotificationLogic = createLogic({
  type: NotificationActions.REQUEST_NOTIFICATIONS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NOTIFICATION.service,
      ApiRoutes.GET_NOTIFICATION.url,
      ApiRoutes.GET_NOTIFICATION.method,
      ApiRoutes.GET_NOTIFICATION.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(notificationListFailed());
      done();
    }
    dispatch(
      notificationListSuccess({
        notifications: responseData.data,
        totalRecords: responseData.totalRecords,
        unReadRecords: responseData.unReadRecords,
      })
    );
    done();
  },
});

const updateNotificationStatusLogic = createLogic({
  type: NotificationActions.UPDATE_NOTIFICATION_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.UPDATE_NOTIFICATION_STATUS.service,
        ApiRoutes.UPDATE_NOTIFICATION_STATUS.url,
        ApiRoutes.UPDATE_NOTIFICATION_STATUS.method,
        ApiRoutes.UPDATE_NOTIFICATION_STATUS.authenticate,
        action.payload,
        undefined
      );

    if (isError) {
      dispatch(updateNotificationStatusFailed());
      done();
    }
    console.log(
      'responseDataresponseDataresponseDataresponseData',
      responseData
    );
    dispatch(
      updateNotificationStatusSuccess({
        unReadRecords: responseData.unReadRecords,
      })
    );
    done();
  },
});

const updateNotificationCountLogic = createLogic({
  type: NotificationActions.UPDATE_NOTIFICATION_COUNT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_NOTIFICATION_COUNT.service,
      ApiRoutes.UPDATE_NOTIFICATION_COUNT.url,
      ApiRoutes.UPDATE_NOTIFICATION_COUNT.method,
      ApiRoutes.UPDATE_NOTIFICATION_COUNT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(updateNotificationCountSuccess());
      done();
    }
    dispatch(updateNotificationCountFailed({}));
    done();
  },
});

export const NotificationLogics = [
  getNotificationLogic,
  updateNotificationStatusLogic,
  updateNotificationCountLogic,
];
