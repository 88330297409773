import { handleActions } from 'redux-actions';
import { BadgesAndCertificatesActions } from '../actions';
import { BadgesAndCertificatesInitialStates } from '../states/BadgeAndCertificate';

export const BadgesAndCertificatesReducer = handleActions(
  {
    [BadgesAndCertificatesActions.REQUEST_BADGES_AND_CERTIFICATES_LIST]: (
      state = BadgesAndCertificatesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [BadgesAndCertificatesActions.BADGES_AND_CERTIFICATES_LIST_SUCCESS]: (
      state = BadgesAndCertificatesInitialStates,
      action
    ) => (
      {
        ...state,
        isLoading: false,
        badges: action.payload.badges,
        certificates: action.payload.certificates,
      }
    ),
    [BadgesAndCertificatesActions.BADGES_AND_CERTIFICATES_LIST_FAILURE]: (
      state = BadgesAndCertificatesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  BadgesAndCertificatesInitialStates
);
