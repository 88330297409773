/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';
import {
  EssayActions,
  essayListFailed,
  essayListSuccess,
  viewEssaySuccess,
  viewEssayFailed,
  addEssaySuccess,
  addEssayFailed,
  updateEssaySuccess,
  updateEssayFailed,
} from '../actions/Essay';
import { logger } from '../../helpers';

const getEssayLogic = createLogic({
  type: EssayActions.ESSAY_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ESSAY.service,
      ApiRoutes.GET_ESSAY.url,
      ApiRoutes.GET_ESSAY.method,
      ApiRoutes.GET_ESSAY.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      if (action.payload.search !== '') {
        showError('Invalid search');
      } else {
        showError(messages[0]);
      }
      dispatch(essayListFailed());
      done();
    }

    dispatch(
      essayListSuccess({
        essay: responseData && responseData.data ? responseData.data : [],
        totalRecords:
          responseData && responseData.totalRecords
            ? responseData.totalRecords
            : 0,
      })
    );
    done();
  },
});

const viewEssayLogic = createLogic({
  type: EssayActions.VIEW_ESSAY_REQUEST,
  async process(data, dispatch, done) {
    logger('viewEssayLogic', viewEssayLogic);
    const { action } = data;
    logger('action', action);
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_ESSAY.service,
      ApiRoutes.VIEW_ESSAY.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_ESSAY.method,
      ApiRoutes.VIEW_ESSAY.authenticate,
      action.payload,
      undefined
    );
    logger('data', data);
    if (isError) {
      showError(messages[0]);
      dispatch(viewEssayFailed());
      done();
    }

    dispatch(
      viewEssaySuccess({
        essay: responseData ? responseData.data[0] : [],
      })
    );
    done();
  },
});

const addEssayLogic = createLogic({
  type: EssayActions.ADD_ESSAY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_ESSAY.service,
      ApiRoutes.ADD_ESSAY.url,
      ApiRoutes.ADD_ESSAY.method,
      ApiRoutes.ADD_ESSAY.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addEssaySuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addEssayFailed());
      done();
    }
  },
});

const updateEssayLogic = createLogic({
  type: EssayActions.UPDATE_ESSAY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_ESSAY.service,
      ApiRoutes.UPDATE_ESSAY.url.replace(':id', action.payload._id),
      ApiRoutes.UPDATE_ESSAY.method,
      ApiRoutes.UPDATE_ESSAY.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(updateEssaySuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateEssayFailed());
      done();
    }
  },
});

export const EssayLogics = [
  getEssayLogic,
  viewEssayLogic,
  addEssayLogic,
  updateEssayLogic,
];
