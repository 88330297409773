/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import {
  DistrictAdminActions,
  DistrictAdminListFailed,
  DistrictAdminListSuccess,
  updateDistrictAdminSuccess,
  updateDistrictAdminFailed,
  requestDistrictAdminList,
  districtAdminAddSuccess,
  DistrictAdminAddFailed,
  viewDistrictAdminFailed,
  viewDistrictAdminSuccess,
  updateDistrictAdminStatusSuccess,
  updateDistrictAdminStatusFailed,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';

/** @format */

/**
                      -----------------
                      -----------------
                          DISTRICT 
                      -----------------
                      -----------------
 */
/**
 ----------------------------
    Add NEW DISTRICT SUPER ADMIN
 ----------------------------
 */
const addDistrictAdminLogic = createLogic({
  type: DistrictAdminActions.ADD_DISTRICT_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_DISTRICT_ADMIN.service,
      ApiRoutes.ADD_DISTRICT_ADMIN.url,
      ApiRoutes.ADD_DISTRICT_ADMIN.method,
      ApiRoutes.ADD_DISTRICT_ADMIN.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(districtAdminAddSuccess());
      showSuccess(messages[0]);
      dispatch(requestDistrictAdminList());
      done();
    } else {
      showError(messages[0]);
      dispatch(DistrictAdminAddFailed());
      done();
    }
  },
});
/**
 -------------------------------
    All DISTRICT SUPER ADMIN LISTING
 ------------------------------
 */
const getDistrictAdminsLogic = createLogic({
  type: DistrictAdminActions.DISTRICT_ADMIN_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_ADMINS.service,
      ApiRoutes.GET_DISTRICT_ADMINS.url,
      ApiRoutes.GET_DISTRICT_ADMINS.method,
      ApiRoutes.GET_DISTRICT_ADMINS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(DistrictAdminListFailed());
      done();
    }

    dispatch(
      DistrictAdminListSuccess({
        districtAdmins: responseData.data,
        totalRecords:
          responseData.pages && responseData.pages[0]
            ? responseData.pages[0].count
            : 0,
      })
    );
    done();
  },
});
/**
 -----------------------------------
    GET DISTRICT SUPER ADMIN VIEW RECORDS
 -----------------------------------
 */
const viewDistrictAdminLogic = createLogic({
  type: DistrictAdminActions.VIEW_DISTRICT_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_DISTRICT_ADMIN.service,
      ApiRoutes.VIEW_DISTRICT_ADMIN.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_DISTRICT_ADMIN.method,
      ApiRoutes.VIEW_DISTRICT_ADMIN.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(viewDistrictAdminFailed());
      done();
    }

    dispatch(
      viewDistrictAdminSuccess({
        districtAdminData: responseData.data,
      })
    );
    done();
  },
});

/**
 ----------------------------------
    UPDATE DISTRICT SUPER ADMIN RECORDS
 ----------------------------------
 */
const updateDistrictAdminLogic = createLogic({
  type: DistrictAdminActions.UPDATE_DISTRICT_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_DISTRICT_PROFILE.service,
      ApiRoutes.UPDATE_DISTRICT_PROFILE.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_DISTRICT_PROFILE.method,
      ApiRoutes.UPDATE_DISTRICT_PROFILE.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateDistrictAdminSuccess());
      showSuccess(messages[0]);
      dispatch(requestDistrictAdminList());
      done();
    } else {
      showError(messages[0]);
      dispatch(updateDistrictAdminFailed());
      done();
    }
  },
});
/**
 ----------------------------------
    DELETE DISTRICT SUPER ADMIN RECORDS
 ----------------------------------
 */
const deleteDistrictAdminLogic = createLogic({
  type: DistrictAdminActions.DELETE_DISTRICT_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_DISTRICT_ADMIN.service,
      ApiRoutes.DELETE_DISTRICT_ADMIN.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_DISTRICT_ADMIN.method,
      ApiRoutes.DELETE_DISTRICT_ADMIN.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      done();
    }
    dispatch(requestDistrictAdminList());
    showSuccess(messages[0]);
    done();
  },
});

/**
 ---------------------------------
    Update DISTRICT SUPER ADMIN STATUS
 --------------------------------
 */
const updateDistrictAdminStatusLogic = createLogic({
  type: DistrictAdminActions.UPDATE_DISTRICT_ADMIN_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_DISTRICT_ADMIN_STATUS.service,
      ApiRoutes.UPDATE_DISTRICT_ADMIN_STATUS.url.replace(
        ':id',
        action.payload.id
      ),
      ApiRoutes.UPDATE_DISTRICT_ADMIN_STATUS.method,
      ApiRoutes.UPDATE_DISTRICT_ADMIN_STATUS.authenticate,
      undefined,
      { status: action.payload.status }
    );
    if (!isError) {
      dispatch(updateDistrictAdminStatusSuccess());
      dispatch(
        requestDistrictAdminList({
          ...action.payload.query,
        })
      );
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateDistrictAdminStatusFailed());
      done();
    }
  },
});

export const DistrictAdminLogics = [
  /*
----------------------
      DISTRICT SUPER ADMIN
----------------------
*/
  addDistrictAdminLogic,
  getDistrictAdminsLogic,
  viewDistrictAdminLogic,
  updateDistrictAdminLogic,
  deleteDistrictAdminLogic,
  updateDistrictAdminStatusLogic,
];
