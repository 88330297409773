/** @format */

import { createAction } from "redux-actions";

export const ProgramAdminActions = {
  /*
---------------------
      Program
---------------------
*/
  // Add new Program Super Admin
  ADD_PROGRAM_ADMIN_REQUEST: "Add Program Super Admin Request!",
  ADD_PROGRAM_ADMIN_SUCCESS: "Program Super Admin Added successfully!.",
  ADD_PROGRAM_ADMIN_FAILED: "Add Program Super Admin failed!",
  // Program Super Admin listing
  PROGRAM_ADMIN_LIST_REQUEST: "Program Super Admin Listing Request!",
  PROGRAM_ADMIN_LIST_SUCCESS: "Program Super Admin Listed successfully!.",
  PROGRAM_ADMIN_LIST_FAILED: "Program Super Admin Listing failed!",
  // update Program Super Admin Record
  UPDATE_PROGRAM_ADMIN_REQUEST: "Update Program Super Admin Request!",
  UPDATE_PROGRAM_ADMIN_SUCCESS: "Update Program Super Admin successfully!.",
  UPDATE_PROGRAM_ADMIN_FAILED: "Update Program Super Admin failed!",

  // get Program Super Admin
  VIEW_PROGRAM_ADMIN_REQUEST: "View Program Super Admin Request!",
  VIEW_PROGRAM_ADMIN_SUCCESS: "View Program Super Admin successfully!.",
  VIEW_PROGRAM_ADMIN_FAILED: "View Program Super Admin failed!",

  DELETE_PROGRAM_ADMIN_REQUEST: "Request to delete Program Super Admin",
  UPDATE_PROGRAM_ADMIN_STATUS_REQUEST:
    "Request to update Program Super Admin status",
};

/*
---------------------
      Program
---------------------
*/

// Add new Program Super Admin
export const requestToAddProgramAdmin = createAction(
  ProgramAdminActions.ADD_PROGRAM_ADMIN_REQUEST
);
export const programAdminaddSuccess = createAction(
  ProgramAdminActions.ADD_PROGRAM_ADMIN_SUCCESS
);
export const programAdminaddFailed = createAction(
  ProgramAdminActions.ADD_PROGRAM_ADMIN_FAILED
);

export const requestProgramAdminList = createAction(
  ProgramAdminActions.PROGRAM_ADMIN_LIST_REQUEST
);
export const programAdminListSuccess = createAction(
  ProgramAdminActions.PROGRAM_ADMIN_LIST_SUCCESS
);
export const programAdminListFailed = createAction(
  ProgramAdminActions.PROGRAM_ADMIN_LIST_FAILED
);

export const updateProgramAdminRequest = createAction(
  ProgramAdminActions.UPDATE_PROGRAM_ADMIN_REQUEST
);
export const updateProgramAdminSuccess = createAction(
  ProgramAdminActions.UPDATE_PROGRAM_ADMIN_SUCCESS
);
export const updateProgramAdminFailed = createAction(
  ProgramAdminActions.UPDATE_PROGRAM_ADMIN_FAILED
);

export const viewProgramAdminRequest = createAction(
  ProgramAdminActions.VIEW_PROGRAM_ADMIN_REQUEST
);
export const viewProgramAdminSuccess = createAction(
  ProgramAdminActions.VIEW_PROGRAM_ADMIN_SUCCESS
);
export const viewProgramAdminFailed = createAction(
  ProgramAdminActions.VIEW_PROGRAM_ADMIN_FAILED
);

export const deleteProgramAdminRequest = createAction(
  ProgramAdminActions.DELETE_PROGRAM_ADMIN_REQUEST
);

export const updateProgramAdminStatusRequest = createAction(
  ProgramAdminActions.UPDATE_PROGRAM_ADMIN_REQUEST
);
