/** @format */

import { handleActions } from 'redux-actions';
import { TermsConditionActions } from '../actions';
import { TermsConditionInitialStates } from '../states/TermsCondition';

export const TermsConditionReducer = handleActions(
  {
    [TermsConditionActions.GET_TERMS_CONDITION_REQUEST]: (
      state = TermsConditionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [TermsConditionActions.GET_TERMS_CONDITION_SUCCESS]: (
      state = TermsConditionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      termsConditionData: action.payload.termsConditionData,
    }),
    [TermsConditionActions.GET_TERMS_CONDITION_FAILED]: (
      state = TermsConditionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [TermsConditionActions.UPDATE_TERMSCONDITION_REQUEST]: (
      state = TermsConditionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
    }),
    [TermsConditionActions.UPDATE_TERMSCONDITION_SUCCESS]: (
      state = TermsConditionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [TermsConditionActions.UPDATE_TERMSCONDITION_FAILED]: (
      state = TermsConditionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  TermsConditionInitialStates
);
