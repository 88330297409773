import { createAction } from 'redux-actions';

export const NotificationActions = {
  REQUEST_NOTIFICATIONS_LIST: 'Request to fetch Notifications',
  NOTIFICATION_LIST_SUCCESS: 'Notifications fetched successfully',
  NOTIFICATION_LIST_FAILURE: 'Failed to fetched Notifications',

  UPDATE_NOTIFICATION_STATUS_REQUEST: 'Request to update notification status',
  UPDATE_NOTIFICATION_STATUS_SUCCESS: 'Notification status updated successfully',
  UPDATE_NOTIFICATION_STATUS_FAILURE: 'Failed to update notification status',

  UPDATE_NOTIFICATION_COUNT_REQUEST: 'Request to update notification count',
  UPDATE_NOTIFICATION_COUNT_SUCCESS: 'Notification count updated successfully',
  UPDATE_NOTIFICATION_COUNT_FAILURE: 'Failed to update notification count',
};

// List notification actions
export const requestNotificationList = createAction(
  NotificationActions.REQUEST_NOTIFICATIONS_LIST
);
export const notificationListSuccess = createAction(
  NotificationActions.NOTIFICATION_LIST_SUCCESS
);
export const notificationListFailed = createAction(
  NotificationActions.NOTIFICATION_LIST_FAILURE
);

export const updateNotificationStatusRequest = createAction(
  NotificationActions.UPDATE_NOTIFICATION_STATUS_REQUEST
);
export const updateNotificationStatusSuccess = createAction(
  NotificationActions.UPDATE_NOTIFICATION_STATUS_SUCCESS
);
export const updateNotificationStatusFailed = createAction(
  NotificationActions.UPDATE_NOTIFICATION_STATUS_FAILURE
);

export const updateNotificationCountRequest = createAction(
  NotificationActions.UPDATE_NOTIFICATION_COUNT_REQUEST
);
export const updateNotificationCountSuccess = createAction(
  NotificationActions.UPDATE_NOTIFICATION_COUNT_SUCCESS
);
export const updateNotificationCountFailed = createAction(
  NotificationActions.UPDATE_NOTIFICATION_COUNT_FAILURE
);
