/** @format */
import { createLogic } from 'redux-logic';
import { push } from 'react-router-redux';

import { AuthLogics } from './Auth';
import { DistrictLogics } from './District';
import { DistrictAdminLogics } from './DistrictAdmin';
import { GeoGraphicsLogics } from './GeoGraphics';
import { HomePageLogics } from './HomePage';
import { LessonLogics } from './Lesson';
import { ProgramLogics } from './Program';
import { ProgramAdminLogics } from './ProgramAdmin';
import { ProgramSitesLogics } from './ProgramSites';
import { SchoolLogics } from './School';
import { SectionLogics } from './Section';
import { StudentLogics } from './Student';
import { PrivacyPolicyLogics } from './PrivacyPolicy';
import { TermsConditionLogics } from './TermsCondition';
import { TestLogics } from './test';
import { UnitLogics } from './Course';
import { QuizLogics } from './Quiz';
import { GlobalSettingLogics } from './GlobalSetting';
import { CouponLogics } from './Coupon';
import { BadgesAndCertificatesLogics } from './BadgeAndCertificate';
import { SupportLogics } from './Support';
import { SubscriptionPlanLogics } from './SubscriptionPlan';
import { TransactionLogics } from './Transaction';
import { ReportLogics } from './Report';
import { NotificationLogics } from './Notification';
import { EssayLogics } from './Essay';
import { DashboardLogics } from './Dashboard';
import { LinkageLogics } from './LinkageRequest';
import { ContactLogics } from './Contact';
import { FreeDemoLogics } from './FreeDemo';

export const redirectToLogic = createLogic({
  type: 'REDIRECT_TO',
  async process(data, dispatch, done) {
    const action = data.action;
    dispatch(push(action.payload.path));
    done();
  },
});

export const AllLogics = [
  ...AuthLogics,
  ...DistrictLogics,
  ...DistrictAdminLogics,
  ...GeoGraphicsLogics,
  ...LessonLogics,
  ...ProgramLogics,
  ...ProgramAdminLogics,
  ...ProgramSitesLogics,
  ...SchoolLogics,
  ...SectionLogics,
  ...StudentLogics,
  ...HomePageLogics,
  ...PrivacyPolicyLogics,
  ...TermsConditionLogics,
  ...TestLogics,
  ...UnitLogics,
  ...QuizLogics,
  ...GlobalSettingLogics,
  ...CouponLogics,
  ...BadgesAndCertificatesLogics,
  ...SupportLogics,
  ...SubscriptionPlanLogics,
  ...TransactionLogics,
  ...ReportLogics,
  ...NotificationLogics,
  ...EssayLogics,
  ...DashboardLogics,
  ...LinkageLogics,
  ...ContactLogics,
  ...FreeDemoLogics,
  redirectToLogic,
];
