/** @format */
import { handleActions } from 'redux-actions';
import { ModelActions } from '../actions';
import { ModalInitialStates } from '../states';

export const ModalInfoReducer = handleActions(
  {
    [ModelActions.MODAL_TOGGLE_REQUEST]: (
      state = ModalInitialStates,
      action,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
  ModalInitialStates,
);
