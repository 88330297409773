/** @format */

import { createLogic } from 'redux-logic';
import {
  UnitActions,
  unitListSuccess,
  unitListFailed,
  unitOptionListSuccess,
  updateUnitDataOrderFailed,
  updateUnitDataOrderSuccess,
  sectionListFailed,
  sectionListSuccess,
} from '../actions';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';

const getSectionsLogic = createLogic({
  type: UnitActions.REQUEST_UNIT_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_UNITS.service,
      ApiRoutes.GET_UNITS.url,
      ApiRoutes.GET_UNITS.method,
      ApiRoutes.GET_UNITS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(unitListFailed());
      done();
    }
    dispatch(
      unitOptionListSuccess({
        unitOptions:
          responseData.data && responseData.data.length
            ? responseData.data.map((unit) => ({
                label: unit.unitName,
                value: unit._id,
              }))
            : [],
      })
    );
    dispatch(
      unitListSuccess({
        units: responseData.data,
      })
    );
    done();
  },
});

const getSectionsOfUnitLogic = createLogic({
  type: UnitActions.REQUEST_SECTION_LIST_BY_UNIT,
  async process(data, dispatch, done) {
    const { action, getState } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SECTIONS_BY_UNIT.service,
      ApiRoutes.GET_SECTIONS_BY_UNIT.url.replace(':id', action.payload.id),
      ApiRoutes.GET_SECTIONS_BY_UNIT.method,
      ApiRoutes.GET_SECTIONS_BY_UNIT.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(unitListFailed());
      dispatch(sectionListFailed());
      done();
    }
    const { units = [] } = getState().unitReducer;
    const temp = [...units];
    let index = temp.findIndex((unit) => unit._id === action.payload.id);
    temp[index] = {
      ...temp[index],
      sections: responseData.data,
      isSectionsLoading: false,
    };
    dispatch(
      unitListSuccess({
        units: temp,
      })
    );
    dispatch(sectionListSuccess());
    done();
  },
});

const getLessonsOfUnitLogic = createLogic({
  type: UnitActions.REQUEST_LESSON_LIST_BY_UNIT,
  async process(data, dispatch, done) {
    const { action, getState } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_LESSONS_BY_UNIT.service,
      ApiRoutes.GET_LESSONS_BY_UNIT.url.replace(':id', action.payload.id),
      ApiRoutes.GET_LESSONS_BY_UNIT.method,
      ApiRoutes.GET_LESSONS_BY_UNIT.authenticate,
      { sectionID: action.payload.sectionID },
      undefined
    );
    if (isError) {
      dispatch(unitListFailed());
      done();
    }
    const { units = [] } = getState().unitReducer;
    const temp = [...units];
    let index = temp.findIndex((unit) => unit._id === action.payload.id);
    let sectionIndex = -1;
    if (action.payload.sectionID) {
      sectionIndex = temp[index].sections.findIndex(
        (section) => section._id === action.payload.sectionID
      );
    }
    temp[index].sections[sectionIndex].isLessonsLoading = false;
    temp[index].sections[sectionIndex] = {
      ...temp[index].sections[sectionIndex],
      lessons: responseData.data,
      isLessonsLoading: false,
    };

    dispatch(
      unitListSuccess({
        units: temp,
      })
    );
    done();
  },
});

const getUnitOptionsListLogic = createLogic({
  type: UnitActions.REQUEST_UNIT_OPTION_LIST,
  async process(data, dispatch, done) {
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNIT_OPTIONS_LIST.service,
      ApiRoutes.UNIT_OPTIONS_LIST.url,
      ApiRoutes.UNIT_OPTIONS_LIST.method,
      ApiRoutes.UNIT_OPTIONS_LIST.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      // dispatch(unitListFailed());
      done();
    }

    dispatch(
      unitOptionListSuccess({
        unitOptions: responseData.data,
      })
    );
    done();
  },
});

const updateUnitDataOrderLogic = createLogic({
  type: UnitActions.UPDATE_UNIT_DATA_ORDER_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_UNIT_DATA_ORDER.service,
      ApiRoutes.UPDATE_UNIT_DATA_ORDER.url,
      ApiRoutes.UPDATE_UNIT_DATA_ORDER.method,
      ApiRoutes.UPDATE_UNIT_DATA_ORDER.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(updateUnitDataOrderFailed());
      showError(messages[0]);
      done();
    }
    dispatch(
      updateUnitDataOrderSuccess({
        isSectionOrderUpdated: responseData.isSectionOrderUpdated,
        isLessonOrderUpdated: responseData.isLessonOrderUpdated,
      })
    );
    showSuccess(messages[0]);
    done();
  },
});
export const UnitLogics = [
  getSectionsLogic,
  getSectionsOfUnitLogic,
  getLessonsOfUnitLogic,
  getUnitOptionsListLogic,
  updateUnitDataOrderLogic,
];
