import { createLogic } from 'redux-logic';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import {
  SubscriptionPlanActions,
  SubscriptionPlanFailed,
  SubscriptionPlanSuccess,
  updateSubscriptionPlanFailed,
  updateSubscriptionPlanSuccess,
} from '../actions';

const getSubscriptionPlanLogic = createLogic({
  type: SubscriptionPlanActions.REQUEST_SUBSCRIPTIONPLAN_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION_PLANS.service,
      ApiRoutes.GET_SUBSCRIPTION_PLANS.url,
      ApiRoutes.GET_SUBSCRIPTION_PLANS.method,
      ApiRoutes.GET_SUBSCRIPTION_PLANS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(SubscriptionPlanFailed());
      done();
    }
    dispatch(
      SubscriptionPlanSuccess({
        plans: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

const updateSubscriptionPlanLogic = createLogic({
  type: SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SUBSCRIPTION_PLANS.service,
      ApiRoutes.UPDATE_SUBSCRIPTION_PLANS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_SUBSCRIPTION_PLANS.method,
      ApiRoutes.UPDATE_SUBSCRIPTION_PLANS.authenticate,
      undefined
    );
    if (!isError) {
      dispatch(updateSubscriptionPlanSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateSubscriptionPlanFailed(messages[0]));
      done();
    }
  },
});

export const SubscriptionPlanLogics = [
  getSubscriptionPlanLogic,
  updateSubscriptionPlanLogic,
];
