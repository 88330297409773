import { handleActions } from 'redux-actions';
import { CouponActions } from '../actions';
import { CouponInitialStates } from '../states/Coupon';

export const CouponReducer = handleActions(
  {
    [CouponActions.REQUEST_ADD_COUPON]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [CouponActions.ADD_COUPON_SUCCESS]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [CouponActions.ADD_COUPON_FAILURE]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [CouponActions.REQUEST_COUPONS_LIST]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [CouponActions.COUPON_LIST_SUCCESS]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      coupons: action.payload.coupons,
      totalRecords: action.payload.totalRecords,
    }),
    [CouponActions.COUPON_LIST_FAILURE]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [CouponActions.UPDATE_COUPON_REQUEST]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [CouponActions.UPDATE_COUPON_SUCCESS]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [CouponActions.UPDATE_COUPON_FAILURE]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),

    [CouponActions.UPDATE_COUPON_STATUS_REQUEST]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [CouponActions.UPDATE_COUPON_STATUS_SUCCESS]: (
      state = CouponInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
    [CouponActions.UPDATE_COUPON_STATUS_FAILURE]: (
        state = CouponInitialStates,
        action
      ) => ({
        ...state,
        isLoading: false,
        isUpdated: true,
      }),
  },
  CouponInitialStates
);
