import { createAction } from "redux-actions";

export const DistrictAdminActions = {
  /*
---------------------
      DISTRICT SUPER ADMIN
---------------------
*/

  // Add new District Super Admin
  ADD_DISTRICT_ADMIN_REQUEST: "Add District Super Admin Request!",
  ADD_DISTRICT_ADMIN_SUCCESS: "District Super Admin Added successfully!.",
  ADD_DISTRICT_ADMIN_FAILED: "Add District Super Admin failed!",
  // District Super Admin listing
  DISTRICT_ADMIN_LIST_REQUEST: "District Super Admin Listing Request!",
  DISTRICT_ADMIN_LIST_SUCCESS: "District Super Admin Listed successfully!.",
  DISTRICT_ADMIN_LIST_FAILED: "District Super Admin Listing failed!",

  // update District Super Admin Record
  UPDATE_DISTRICT_ADMIN_REQUEST: "Update District Super Admin Request!",
  UPDATE_DISTRICT_ADMIN_SUCCESS: "Update District Super Admin successfully!.",
  UPDATE_DISTRICT_ADMIN_FAILED: "Update District Super Admin failed!",

  // get District Super Admin
  VIEW_DISTRICT_ADMIN_REQUEST: "View District Super Admin Request!",
  VIEW_DISTRICT_ADMIN_SUCCESS: "View District Super Admin successfully!.",
  VIEW_DISTRICT_ADMIN_FAILED: "View District Super Admin failed!",
  //delete district Super Admin
  DELETE_DISTRICT_ADMIN_REQUEST: "Request to delete District Super Admin",

  // update District Status
  UPDATE_DISTRICT_ADMIN_STATUS_REQUEST:
    "Request to update District Super Admin status",
  UPDATE_DISTRICT_ADMIN_STATUS_SUCCESS:
    "Update District Super Admin Status successfully!.",
  UPDATE_DISTRICT_ADMIN_STATUS_FAILED:
    "Update District Super Admin Status failed!",
};

/*
----------------------
      DISTRICT SUPER ADMIN
----------------------
*/
// Add new District Super Admin
export const requestAddNewDistrictAdmin = createAction(
  DistrictAdminActions.ADD_DISTRICT_ADMIN_REQUEST
);
export const districtAdminAddSuccess = createAction(
  DistrictAdminActions.ADD_DISTRICT_ADMIN_SUCCESS
);
export const DistrictAdminAddFailed = createAction(
  DistrictAdminActions.ADD_DISTRICT_ADMIN_FAILED
);
// District Super Admin listing
export const requestDistrictAdminList = createAction(
  DistrictAdminActions.DISTRICT_ADMIN_LIST_REQUEST
);
export const DistrictAdminListSuccess = createAction(
  DistrictAdminActions.DISTRICT_ADMIN_LIST_SUCCESS
);
export const DistrictAdminListFailed = createAction(
  DistrictAdminActions.DISTRICT_ADMIN_LIST_FAILED
);

// update District Super Admin Record
export const updateDistrictAdminRequest = createAction(
  DistrictAdminActions.UPDATE_DISTRICT_ADMIN_REQUEST
);
export const updateDistrictAdminSuccess = createAction(
  DistrictAdminActions.UPDATE_DISTRICT_ADMIN_SUCCESS
);
export const updateDistrictAdminFailed = createAction(
  DistrictAdminActions.UPDATE_DISTRICT_ADMIN_FAILED
);
// get District Super Admin
export const viewDistrictAdminRequest = createAction(
  DistrictAdminActions.VIEW_DISTRICT_ADMIN_REQUEST
);
export const viewDistrictAdminSuccess = createAction(
  DistrictAdminActions.VIEW_DISTRICT_ADMIN_SUCCESS
);
export const viewDistrictAdminFailed = createAction(
  DistrictAdminActions.VIEW_DISTRICT_ADMIN_FAILED
);

//delete District Super Admin
export const deleteDistrictAdminRequest = createAction(
  DistrictAdminActions.DELETE_DISTRICT_ADMIN_REQUEST
);
//update District Super Admin status
export const updateDistrictAdminStatusRequest = createAction(
  DistrictAdminActions.UPDATE_DISTRICT_ADMIN_STATUS_REQUEST
);

export const updateDistrictAdminStatusSuccess = createAction(
  DistrictAdminActions.UPDATE_DISTRICT_ADMIN_STATUS_SUCCESS
);
export const updateDistrictAdminStatusFailed = createAction(
  DistrictAdminActions.UPDATE_DISTRICT_ADMIN_STATUS_FAILED
);
