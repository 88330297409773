import { createAction } from 'redux-actions';

export const EssayActions = {
  //Essay listing
  ESSAY_LIST_REQUEST: 'Essay Listing Request!',
  ESSAY_LIST_SUCCESS: 'Essay Listed successfully!.',
  ESSAY_LIST_FAILED: 'Essay Listing failed!',

  //View Essay Record
  VIEW_ESSAY_REQUEST: 'View Essay Record Request!',
  VIEW_ESSAY_SUCCESS: 'View Essay Record successfully!.',
  VIEW_ESSAY_FAILED: 'View Essay Record failed!',

  //Add Essay Record
  ADD_ESSAY_REQUEST: 'Add Essay Record Request!',
  ADD_ESSAY_SUCCESS: 'Add Essay Record successfully!.',
  ADD_ESSAY_FAILED: 'Add Essay Record failed!',

  //Update Essay Record
  UPDATE_ESSAY_REQUEST: 'Update Essay Record Request!',
  UPDATE_ESSAY_SUCCESS: 'Update Essay Record successfully!.',
  UPDATE_ESSAY_FAILED: 'Update Essay Record failed!',
};

export const essayListRequest = createAction(EssayActions.ESSAY_LIST_REQUEST);
export const essayListSuccess = createAction(EssayActions.ESSAY_LIST_SUCCESS);
export const essayListFailed = createAction(EssayActions.ESSAY_LIST_FAILED);

export const viewEssayRequest = createAction(EssayActions.VIEW_ESSAY_REQUEST);
export const viewEssaySuccess = createAction(EssayActions.VIEW_ESSAY_SUCCESS);
export const viewEssayFailed = createAction(EssayActions.VIEW_ESSAY_FAILED);

export const addEssayRequest = createAction(EssayActions.ADD_ESSAY_REQUEST);
export const addEssaySuccess = createAction(EssayActions.ADD_ESSAY_SUCCESS);
export const addEssayFailed = createAction(EssayActions.ADD_ESSAY_FAILED);

export const updateEssayRequest = createAction(
  EssayActions.UPDATE_ESSAY_REQUEST
);
export const updateEssaySuccess = createAction(
  EssayActions.UPDATE_ESSAY_SUCCESS
);
export const updateEssayFailed = createAction(EssayActions.UPDATE_ESSAY_FAILED);
