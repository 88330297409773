/** @format */

import { createAction } from 'redux-actions';

export const CouponActions = {
  REQUEST_ADD_COUPON: 'Request to add coupon',
  ADD_COUPON_SUCCESS: 'Coupon added successfully',
  ADD_COUPON_FAILURE: 'Failed to add coupon',
  REQUEST_COUPONS_LIST: 'Request to fetch coupons',
  COUPON_LIST_SUCCESS: 'Coupons fetched successfully',
  COUPON_LIST_FAILURE: 'Failed to fetched coupons',
  UPDATE_COUPON_REQUEST: 'Request to update coupon',
  UPDATE_COUPON_SUCCESS: 'Coupon updated successfully',
  UPDATE_COUPON_FAILURE: 'Failed to update coupon',
  DELETE_COUPON_REQUEST: 'Request to delete coupon',
  UPDATE_COUPON_STATUS_REQUEST: 'Request to update coupon status',
  UPDATE_COUPON_STATUS_SUCCESS: 'Coupon status updated successfully',
};

// Add coupon actions
export const requestAddCoupon = createAction(
  CouponActions.REQUEST_ADD_COUPON
);
export const addCouponSuccess = createAction(
  CouponActions.ADD_COUPON_SUCCESS
);
export const addCouponFailed = createAction(
  CouponActions.ADD_COUPON_FAILURE
);

// List coupon actions
export const requestCouponList = createAction(
  CouponActions.REQUEST_COUPONS_LIST
);
export const couponListSuccess = createAction(
  CouponActions.COUPON_LIST_SUCCESS
);
export const couponListFailed = createAction(
  CouponActions.COUPON_LIST_FAILURE
);

// Update coupon actions
export const updateCouponRequest = createAction(
  CouponActions.UPDATE_COUPON_REQUEST
);
export const updateCouponSuccess = createAction(
  CouponActions.UPDATE_COUPON_SUCCESS
);
export const updateCouponFailed = createAction(
  CouponActions.UPDATE_COUPON_FAILURE
);


export const deleteCouponRequest = createAction(
  CouponActions.DELETE_COUPON_REQUEST
);

export const updateCouponStatusRequest = createAction(
  CouponActions.UPDATE_COUPON_STATUS_REQUEST
);

export const updateCouponStatusSuccess = createAction(
  CouponActions.UPDATE_COUPON_STATUS_SUCCESS
);
