/** @format */

import { handleActions } from 'redux-actions';
import { TestActions } from '../actions';
import { TestInitialStates } from '../states/Test';

export const TestReducer = handleActions(
  {
    [TestActions.REQUEST_TEST_LIST]: (state = TestInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [TestActions.TEST_LIST_SUCCESS]: (state = TestInitialStates, action) => ({
      ...state,
      isLoading: false,
      tests: action.payload.tests,
    }),
    [TestActions.TEST_LIST_FAILURE]: (state = TestInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [TestActions.UPDATE_TEST_INSTRUCTIONS_REQUEST]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isInstructionUpdated: false,
    }),
    [TestActions.UPDATE_TEST_INSTRUCTIONS_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isInstructionUpdated: true,
    }),
    [TestActions.UPDATE_TEST_INSTRUCTIONS_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [TestActions.UPDATE_TEST_DIRECTIONS_REQUEST]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isDirectionUpdated: false,
    }),
    [TestActions.UPDATE_TEST_DIRECTIONS_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isDirectionUpdated: true,
    }),
    [TestActions.UPDATE_TEST_DIRECTIONS_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [TestActions.REQUEST_TEST_UNIT_DATA]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isTestLoading: true,
      testUnitData: [],
      isTestDataFetched: false,
    }),
    [TestActions.TEST_UNIT_DATA_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isTestLoading: false,
      testUnitData: action.payload.testUnitData,
      isTestDataFetched: true,
    }),
    [TestActions.TEST_UNIT_DATA_FAILED]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
      isTestLoading: false,
      testUnitData: [],
    }),

    [TestActions.REQUEST_TEST_SECTION_DATA]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isTestSectionLoading: true,
      testSectionData: [],
    }),
    [TestActions.TEST_SECTION_DATA_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isTestSectionLoading: false,
      testSectionData: action.payload.testSectionData,
    }),
    [TestActions.TEST_SECTION_DATA_FAILED]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
      isTestSectionLoading: false,
    }),

    [TestActions.UPDATE_TEST_UNIT_DATA_REQUEST]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isTestUnitUpdated: false,
    }),
    [TestActions.UPDATE_TEST_UNIT_DATA_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isTestUnitUpdated: true,
    }),
    [TestActions.UPDATE_TEST_UNIT_DATA_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [TestActions.DELETE_TEST_QUESTION_VIDEO_REQUEST]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isTestUnitUpdated: false,
    }),
    [TestActions.DELETE_TEST_QUESTION_VIDEO_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isTestUnitUpdated: true,
    }),
    [TestActions.DELETE_TEST_QUESTION_VIDEO_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [TestActions.UPDATE_TEST_UNIT_DATA_ORDER_REQUEST]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isTestUnitOrderUpdated: false,
    }),
    [TestActions.UPDATE_TEST_UNIT_DATA_ORDER_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isTestUnitOrderUpdated: true,
    }),
    [TestActions.UPDATE_TEST_UNIT_DATA_ORDER_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [TestActions.REQUEST_DELETE_TEST_QUESTION]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isQuestionDeleted: false,
    }),
    [TestActions.DELETE_TEST_QUESTION_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isQuestionDeleted: true,
    }),
    [TestActions.DELETE_TEST_QUESTION_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isQuestionDeleted: false,
    }),

    [TestActions.REQUEST_TEST_REDUCER_SET_INITIAL]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isTestLoading: true,
      testUnitData: [],
    }),

    [TestActions.REQUEST_ADD_NEW_TEST_PARAGRAPH_QUESTION]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isParagraphQuestionAdded: false,
    }),
    [TestActions.ADD_NEW_TEST_PARAGRAPH_QUESTION_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isParagraphQuestionAdded: true,
    }),
    [TestActions.ADD_NEW_TEST_PARAGRAPH_QUESTION_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isParagraphQuestionAdded: false,
      error: true,
    }),

    [TestActions.FLAGGED_QUESTIONS_LIST_REQUEST]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [TestActions.FLAGGED_QUESTIONS_LIST_SUCCESS]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      flaggedQuestions: action.payload.flaggedQuestions,
      totalFlaggedQuestionRecords: action.payload.totalRecords,
    }),
    [TestActions.FLAGGED_QUESTIONS_LIST_FAILURE]: (
      state = TestInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  TestInitialStates
);
