/** @format */

import { handleActions } from 'redux-actions';
import { ProgramActions } from '../actions';
import { ProgramInitialStates } from '../states';

export const ProgramReducer = handleActions(
  {
    [ProgramActions.ADD_PROGRAM_REQUEST]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [ProgramActions.ADD_PROGRAM_SUCCESS]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [ProgramActions.ADD_PROGRAM_FAILED]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [ProgramActions.PROGRAM_LIST_REQUEST]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramActions.PROGRAM_LIST_SUCCESS]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programs: action.payload.programs,
      totalRecords: action.payload.totalRecords,
    }),
    [ProgramActions.PROGRAM_LIST_FAILED]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [ProgramActions.UPDATE_PROGRAM_REQUEST]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [ProgramActions.UPDATE_PROGRAM_SUCCESS]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [ProgramActions.UPDATE_PROGRAM_FAILED]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [ProgramActions.VIEW_PROGRAM_REQUEST]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramActions.VIEW_PROGRAM_SUCCESS]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programData: action.payload.programData,
    }),
    [ProgramActions.VIEW_PROGRAM_FAILED]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [ProgramActions.PROGRAM_OPTION_LIST_REQUEST]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramActions.PROGRAM_OPTION_LIST_SUCCESS]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programOptions: action.payload.programOptions,
    }),
    [ProgramActions.PROGRAM_OPTION_LIST_FAILED]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [ProgramActions.UPDATE_PROGRAM_STATUS_REQUEST]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [ProgramActions.UPDATE_PROGRAM_STATUS_SUCCESS]: (
      state = ProgramInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
  },
  ProgramInitialStates
);
