import { createAction } from "redux-actions";

export const DistrictActions = {
  /*
---------------------
      DISTRICT
---------------------
*/
  // Add new district
  ADD_DISTRICT_REQUEST: 'Add New District Request!',
  ADD_DISTRICT_SUCCESS: 'District Added successfully!.',
  ADD_DISTRICT_FAILED: 'Add New District failed!',
  // District listing
  DISTRICT_LIST_REQUEST: 'District Listing Request!',
  DISTRICT_LIST_SUCCESS: 'District Listed successfully!.',
  DISTRICT_LIST_FAILED: 'District Listing failed!',
  // update District Record
  UPDATE_DISTRICT_RECORD_REQUEST: 'Update District Record Request!',
  UPDATE_DISTRICT_RECORD_SUCCESS: 'Update District Record successfully!.',
  UPDATE_DISTRICT_RECORD_FAILED: 'Update District Record failed!',

  //Delete District Record
  DELETE_DISTRICT_RECORD_REQUEST: 'Delete District Record Request!',
  DELETE_DISTRICT_RECORD_SUCCESS: 'Delete District Record successfully!.',
  DELETE_DISTRICT_RECORD_FAILED: 'Delete District Record failed!',

  // get District Record
  GET_DISTRICT_RECORD_REQUEST: 'Get District Record Request!',
  GET_DISTRICT_RECORD_SUCCESS: 'Get District Record successfully!.',
  GET_DISTRICT_RECORD_FAILED: 'Get District Record failed!',

  // update District Status
  UPDATE_DISTRICT_STATUS_REQUEST: 'Update District Status Request!',
  UPDATE_DISTRICT_STATUS_SUCCESS: 'Update District Status successfully!.',
  UPDATE_DISTRICT_STATUS_FAILED: 'Update District Status failed!',

  // District options list
  DISTRICT_OPTION_LIST_REQUEST: 'Request for district options list!',
  DISTRICT_OPTION_LIST_SUCCESS: 'district options list fetched successfully!.',
  DISTRICT_OPTION_LIST_FAILED: 'Failed to load district option list.',
};

/*
---------------------
      DISTRICT
---------------------
*/

// Add new district
export const requestAddNewDistrict = createAction(
  DistrictActions.ADD_DISTRICT_REQUEST
);
export const districtAddSuccess = createAction(
  DistrictActions.ADD_DISTRICT_SUCCESS
);
export const DistrictAddFailed = createAction(
  DistrictActions.ADD_DISTRICT_FAILED
);
// District listing
export const requestDistrictList = createAction(
  DistrictActions.DISTRICT_LIST_REQUEST
);
export const DistrictListSuccess = createAction(
  DistrictActions.DISTRICT_LIST_SUCCESS
);
export const DistrictListFailed = createAction(
  DistrictActions.DISTRICT_LIST_FAILED
);
// update District Record
export const updateDistrictRecordRequest = createAction(
  DistrictActions.UPDATE_DISTRICT_RECORD_REQUEST
);
export const updateDistrictRecordSuccess = createAction(
  DistrictActions.UPDATE_DISTRICT_RECORD_SUCCESS
);
export const updateDistrictRecordFailed = createAction(
  DistrictActions.UPDATE_DISTRICT_RECORD_FAILED
);
// get District Record
export const getDistrictRecordRequest = createAction(
  DistrictActions.GET_DISTRICT_RECORD_REQUEST
);
export const getDistrictRecordSuccess = createAction(
  DistrictActions.GET_DISTRICT_RECORD_SUCCESS
);
export const getDistrictRecordFailed = createAction(
  DistrictActions.GET_DISTRICT_RECORD_FAILED
);
// update District Status
export const updateDistrictStatusRequest = createAction(
  DistrictActions.UPDATE_DISTRICT_STATUS_REQUEST
);
export const updateDistrictStatusSuccess = createAction(
  DistrictActions.UPDATE_DISTRICT_STATUS_SUCCESS
);
export const updateDistrictStatusFailed = createAction(
  DistrictActions.UPDATE_DISTRICT_STATUS_FAILED
);
// District options list
export const districtOptionListRequest = createAction(
  DistrictActions.DISTRICT_OPTION_LIST_REQUEST
);
export const districtOptionListSuccess = createAction(
  DistrictActions.DISTRICT_OPTION_LIST_SUCCESS
);
export const districtOptionListFailed = createAction(
  DistrictActions.DISTRICT_OPTION_LIST_FAILED
);

export const requestDeleteDistrict = createAction(
  DistrictActions.DELETE_DISTRICT_RECORD_REQUEST
);

export const successDeleteDistrict = createAction(
  DistrictActions.DELETE_DISTRICT_RECORD_SUCCESS
);
export const failedDeleteDistrict = createAction(
  DistrictActions.DELETE_DISTRICT_RECORD_FAILED
);
