export const AuthInitialStates = {
  isLoading: false,
  loggedIn: false,
  user: null,
  isAuthenticated: false,
  district: undefined,
};

export const ResetPasswordInitialStates = {
  isLoading: false,
  verifyTokenLoading: true,
  isLinkVerified: false,
  message: '',
  data: '',
  passwordSet: false,
};
