/** @format */

import { handleActions } from 'redux-actions';
import { HomePageActions } from '../actions';
import { HomePageInitialStates } from '../states/HomePage';

export const HomePageReducer = handleActions(
  {
    [HomePageActions.REQUEST_ADD_TESTIMONIAL]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
      isUpdated: false,
    }),
    [HomePageActions.ADD_TESTIMONIAL_SUCCESS]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [HomePageActions.ADD_TESTIMONIAL_FAILURE]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [HomePageActions.UPDATE_TESTIMONIAL_REQUEST]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
      isUpdated: false,
    }),
    [HomePageActions.UPDATE_TESTIMONIAL_SUCCESS]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
      isUpdated: true,
    }),
    [HomePageActions.UPDATE_TESTIMONIAL_FAILED]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [HomePageActions.UPDATE_BANNER_REQUEST]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
      isUpdated: false,
    }),
    [HomePageActions.UPDATE_BANNER_SUCCESS]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
      isUpdated: true,
    }),
    [HomePageActions.UPDATE_BANNER_FAILED]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [HomePageActions.UPDATE_VIDEO_SECTION_REQUEST]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
      isUpdated: false,
    }),
    [HomePageActions.UPDATE_VIDEO_SECTION_SUCCESS]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
      isUpdated: true,
    }),
    [HomePageActions.UPDATE_VIDEO_SECTION_FAILED]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [HomePageActions.HOMEPAGE_LIST_REQUEST]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [HomePageActions.HOMEPAGE_LIST_SUCCESS]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      homePageData: action.payload.homePageData,
      totalRecords: action.payload.totalRecords,
    }),
    [HomePageActions.HOMEPAGE_LIST_FAILURE]: (
      state = HomePageInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  HomePageInitialStates
);
