/** @format */

import { createLogic } from 'redux-logic';
import {
  ContactActions,
  successContactList,
  failedContactList,
  successContactView,
  failedContactView,
} from '../actions';
import { showError } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

/**
 *
 */
const getContactLogic = createLogic({
  type: ContactActions.REQUEST_CONTACT_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { service, url, method, authenticate } = ApiRoutes.GET_CONTACT;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      service,
      url,
      method,
      authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(failedContactList());
      if (action.payload.search !== '') {
        showError('Invalid search');
      } else {
        showError(messages[0]);
      }
      done();
    }

    dispatch(
      successContactList({
        contactList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */
const viewContactLogic = createLogic({
  type: ContactActions.VIEW_CONTACT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { service, url, method, authenticate } = ApiRoutes.VIEW_CONTACT;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      service,
      url.replace(':id', action.payload.id),
      method,
      authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(failedContactView());
      showError(messages[0]);
      done();
    }

    dispatch(successContactView({ contactData: responseData.data }));
    done();
  },
});

export const ContactLogics = [getContactLogic, viewContactLogic];
