import { createAction } from 'redux-actions';

export const TransactionActions = {
  TRANSACTION_LIST_REQUEST: 'TRANSACTION_LIST_REQUEST!',
  TRANSACTION_LIST_SUCCESS: 'TRANSACTION_LIST_SUCCESS',
  TRANSACTION_LIST_FAILED: 'TRANSACTION_LIST_FAILED',

  USER_LISTING_REQUEST: 'USER LIST REQUEST!',
  USER_LISTING_SUCCESS: 'USER LIST SUCCESS',
  USER_LISTING_FAILED: 'USER LIST FAILED',

  REQUEST_ADD_OFFLINE_TRANSACTION: 'REQUEST_ADD_OFFLINE_TRANSACTION!',
  ADD_OFFLINE_TRANSACTION_SUCCESS: 'ADD_OFFLINE_TRANSACTION_SUCCESS',
  ADD_OFFLINE_TRANSACTION_FAILED: 'ADD_OFFLINE_TRANSACTION_FAILED',

  REQUEST_DEMO_USER_REGISTER: 'REQUEST_DEMO_USER_REGISTER!',
  DEMO_USER_REGISTER_SUCCESS: 'DEMO_USER_REGISTER_SUCCESS',
  DEMO_USER_REGISTER_FAILED: 'DEMO_USER_REGISTER_FAILED',

  TOTAL_SALES_REQUEST: 'TOTAL_SALES_REQUEST!',
  TOTAL_SALES_SUCCESS: 'TOTAL_SALES_SUCCESS',
  TOTAL_SALES_FAILED: 'TOTAL_SALES_FAILED',
};

// Transaction listing
export const requestTransactionList = createAction(
  TransactionActions.TRANSACTION_LIST_REQUEST
);
export const transactionListSuccess = createAction(
  TransactionActions.TRANSACTION_LIST_SUCCESS
);
export const transactionListFailed = createAction(
  TransactionActions.TRANSACTION_LIST_FAILED
);

// Add offline transactions
export const requestAddOfflineTransaction = createAction(
  TransactionActions.REQUEST_ADD_OFFLINE_TRANSACTION
);
export const addOfflineTransactionSuccess = createAction(
  TransactionActions.ADD_OFFLINE_TRANSACTION_SUCCESS
);
export const addOfflineTransactionFailed = createAction(
  TransactionActions.ADD_OFFLINE_TRANSACTION_FAILED
);

// free demo user transactions
export const requestFreeDemoRegister = createAction(
  TransactionActions.REQUEST_DEMO_USER_REGISTER
);
export const FreeDemoRegisterSuccess = createAction(
  TransactionActions.DEMO_USER_REGISTER_SUCCESS
);
export const FreeDemoRegisterFailed = createAction(
  TransactionActions.DEMO_USER_REGISTER_FAILED
);

// Total sales
export const requestTotalSales = createAction(
  TransactionActions.TOTAL_SALES_REQUEST
);
export const totalSalesSuccess = createAction(
  TransactionActions.TOTAL_SALES_SUCCESS
);
export const totalSalesFailed = createAction(
  TransactionActions.TOTAL_SALES_FAILED
);

// User Listing
export const userListingRequest = createAction(
  TransactionActions.USER_LISTING_REQUEST
);
export const userListingSuccess = createAction(
  TransactionActions.USER_LISTING_SUCCESS
);
export const userListingFailed = createAction(
  TransactionActions.USER_LISTING_FAILED
);
