/** @format */

import { createAction } from 'redux-actions';

export const ProgramActions = {
  /*
---------------------
      Program
---------------------
*/
  // Add new Program
  ADD_PROGRAM_REQUEST: 'Add New Program Request!',
  ADD_PROGRAM_SUCCESS: 'Program Added successfully!.',
  ADD_PROGRAM_FAILED: 'Add New Program failed!',
  // Program listing
  PROGRAM_LIST_REQUEST: 'Program Listing Request!',
  PROGRAM_LIST_SUCCESS: 'Program Listed successfully!.',
  PROGRAM_LIST_FAILED: 'Program Listing failed!',
  // update Program Record
  UPDATE_PROGRAM_REQUEST: 'Update Program Record Request!',
  UPDATE_PROGRAM_SUCCESS: 'Update Program Record successfully!.',
  UPDATE_PROGRAM_FAILED: 'Update Program Record failed!',

  DELETE_PROGRAM_RECORD_REQUEST: 'Delete Program Record Request!',
  DELETE_PROGRAM_RECORD_SUCCESS: 'Delete Program Record successfully!.',
  DELETE_PROGRAM_RECORD_FAILED: 'Delete Program Record failed!',

  // get Program Record
  VIEW_PROGRAM_REQUEST: 'View Program Record Request!',
  VIEW_PROGRAM_SUCCESS: 'View Program Record successfully!.',
  VIEW_PROGRAM_FAILED: 'View Program Record failed!',

  // Program options list
  PROGRAM_OPTION_LIST_REQUEST: 'Request for program options list!',
  PROGRAM_OPTION_LIST_SUCCESS: 'Program options list fetched successfully!.',
  PROGRAM_OPTION_LIST_FAILED: 'Failed to load program option list',

  DELETE_PROGRAM_REQUEST: 'Request to delete program',
  UPDATE_PROGRAM_STATUS_REQUEST: 'Request to update program status',

  UPDATE_PROGRAM_STATUS_SUCCESS: 'Program status updated successfully',
};

/*
---------------------
      Program
---------------------
*/

// Add new Program
export const requestAddNewProgram = createAction(
  ProgramActions.ADD_PROGRAM_REQUEST
);
export const programAddSuccess = createAction(
  ProgramActions.ADD_PROGRAM_SUCCESS
);
export const programAddFailed = createAction(ProgramActions.ADD_PROGRAM_FAILED);

export const requestProgramList = createAction(
  ProgramActions.PROGRAM_LIST_REQUEST
);
export const programListSuccess = createAction(
  ProgramActions.PROGRAM_LIST_SUCCESS
);
export const programListFailed = createAction(
  ProgramActions.PROGRAM_LIST_FAILED
);

export const updateProgramRequest = createAction(
  ProgramActions.UPDATE_PROGRAM_REQUEST
);
export const updateProgramSuccess = createAction(
  ProgramActions.UPDATE_PROGRAM_SUCCESS
);
export const updateProgramFailed = createAction(
  ProgramActions.UPDATE_PROGRAM_FAILED
);

export const viewProgramRequest = createAction(
  ProgramActions.VIEW_PROGRAM_REQUEST
);
export const viewProgramSuccess = createAction(
  ProgramActions.VIEW_PROGRAM_SUCCESS
);
export const viewProgramFailed = createAction(
  ProgramActions.VIEW_PROGRAM_FAILED
);

export const programOptionListRequest = createAction(
  ProgramActions.PROGRAM_OPTION_LIST_REQUEST
);
export const programOptionListSuccess = createAction(
  ProgramActions.PROGRAM_OPTION_LIST_SUCCESS
);
export const programOptionListFailed = createAction(
  ProgramActions.PROGRAM_OPTION_LIST_FAILED
);

export const deleteProgramRequest = createAction(
  ProgramActions.DELETE_PROGRAM_REQUEST
);

export const updateProgramStatusRequest = createAction(
  ProgramActions.UPDATE_PROGRAM_STATUS_REQUEST
);

export const updateProgramStatusSuccess = createAction(
  ProgramActions.UPDATE_PROGRAM_STATUS_SUCCESS
);

export const requestDeleteProgram = createAction(
  ProgramActions.DELETE_PROGRAM_RECORD_REQUEST
);

export const successDeleteProgram = createAction(
  ProgramActions.DELETE_PROGRAM_RECORD_SUCCESS
);
export const failedDeleteProgram = createAction(
  ProgramActions.DELETE_PROGRAM_RECORD_FAILED
);
