/** @format */

export const ApiRoutes = {
  LOGIN: {
    service: '/auth',
    url: '/login',
    method: 'POST',
    authenticate: false,
  },
  GET_PROFILE_DETAILS: {
    service: '/auth',
    url: '/me',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_PROFILE_DETAILS: {
    service: '/auth',
    url: '/',
    method: 'PUT',
    authenticate: true,
  },
  CHANGE_PASSWORD: {
    service: '/auth',
    url: '/change-password',
    method: 'POST',
    authenticate: true,
  },
  VERIFY_LINK: {
    service: '/auth',
    url: '/verify-link',
    method: 'POST',
    authenticate: true,
  },
  SET_PASSWORD: {
    service: '/auth',
    url: '/set-password',
    method: 'POST',
    authenticate: true,
  },
  ADD_STUDENT: {
    service: '/student',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_STUDENTS: {
    service: '/student',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_STUDENT: {
    service: '/student',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  DELETE_STUDENT: {
    service: '/student',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  UPDATE_STUDENT: {
    service: '/student',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_STUDENT_STATUS: {
    service: '/student',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },
  ADD_PROGRAM: {
    service: '/program',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_PROGRAMS: {
    service: '/program',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_PROGRAM: {
    service: '/program',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  DELETE_PROGRAM: {
    service: '/program',
    url: '/:id',
    method: 'PATCH',
    authenticate: true,
  },
  UPDATE_PROGRAM: {
    service: '/program',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_PROGRAM_STATUS: {
    service: '/program',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },
  PROGRAM_OPTIONS_LIST: {
    service: '/program',
    url: '/option-list',
    method: 'GET',
    authenticate: true,
  },
  ADD_PROGRAM_ADMIN: {
    service: '/program-admin',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_PROGRAM_ADMINS: {
    service: '/program-admin',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_PROGRAM_ADMIN: {
    service: '/program-admin',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  DELETE_PROGRAM_ADMIN: {
    service: '/program-admin',
    url: '/:id',
    method: 'PATCH',
    authenticate: true,
  },
  UPDATE_PROGRAM_ADMIN: {
    service: '/program-admin',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_PROGRAM_PROFILE: {
    service: '/program',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_PROGRAM_ADMIN_STATUS: {
    service: '/program-admin',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },
  ADD_PROGRAM_SITE: {
    service: '/program-site',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_PROGRAM_SITES: {
    service: '/program-site',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_PROGRAM_SITE: {
    service: '/program-site',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  DELETE_PROGRAM_SITE: {
    service: '/program-site',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  UPDATE_PROGRAM_SITE: {
    service: '/program-site',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_PROGRAM_SITE_STATUS: {
    service: '/program-site',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },
  UPDATE_SETTINGS: {
    service: '/user',
    url: '/update',
    method: 'POST',
    authenticate: true,
  },
  /*
    -----------------
       District
    -----------------
    */

  ADD_DISTRICT: {
    service: '/district',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_DISTRICT: {
    service: '/district',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  UPDATE_DISTRICT: {
    service: '/district',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },

  VIEW_DISTRICT: {
    service: '/district',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_DISTRICT_STATUS: {
    service: '/district',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },

  DISTRICT_OPTIONS_LIST: {
    service: '/district',
    url: '/option-list',
    method: 'GET',
    authenticate: true,
  },

  ADD_DISTRICT_ADMIN: {
    service: '/district-admin',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_DISTRICT_ADMINS: {
    service: '/district-admin',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_DISTRICT_ADMIN: {
    service: '/district-admin',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  DELETE_DISTRICT_ADMIN: {
    service: '/district-admin',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  UPDATE_DISTRICT_ADMIN: {
    service: '/district-admin',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_DISTRICT_PROFILE: {
    service: '/district',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_DISTRICT_ADMIN_STATUS: {
    service: '/district-admin',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },

  /*
    -----------------
       School
    -----------------
    */
  ADD_SCHOOL: {
    service: '/school',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  GET_SCHOOL: {
    service: '/school',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_SCHOOL: {
    service: '/school',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  DELETE_SCHOOL: {
    service: '/school',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  UPDATE_SCHOOL: {
    service: '/school',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_SCHOOL_STATUS: {
    service: '/school',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },
  /*
    -----------------
       Testimonial
    -----------------
    */

  ADD_TESTIMONIAL: {
    service: '/home-page-cms',
    url: '/testimonial',
    method: 'POST',
    authenticate: true,
  },
  UPDATE_TESTIMONIAL: {
    service: '/home-page-cms',
    url: '/testimonial/:id',
    method: 'PUT',
    authenticate: true,
  },
  DELETE_TESTIMONIAL: {
    service: '/home-page-cms',
    url: '/testimonial/:id',
    method: 'DELETE',
    authenticate: true,
  },
  GET_HOMEPAGE: {
    service: '/home-page-cms',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_BANNER: {
    service: '/home-page-cms',
    url: '/',
    method: 'PUT',
    authenticate: true,
  },
  UPDATE_VIDEO_SECTION: {
    service: '/home-page-cms',
    url: '/update-learning-section',
    method: 'PUT',
    authenticate: true,
  },

  /*
    -----------------
       Privacy Policy
    -----------------
    */

  GET_PRIVACYPOLICY: {
    service: '/privacy-policy-cms',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_PRIVACYPOLICY: {
    service: '/privacy-policy-cms',
    url: '/',
    method: 'PUT',
    authenticate: true,
  },

  /*
    -----------------
       Terms & Conditions
    -----------------
    */

  UPDATE_TERMSCONDITION: {
    service: '/terms-of-service-cms',
    url: '/',
    method: 'PUT',
    authenticate: true,
  },
  GET_TERMSCONDITION: {
    service: '/terms-of-service-cms',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  /*
    -----------------
       Terms & Conditions
    -----------------
    */

  UPDATE_GLOBALSETTING: {
    service: '/global-setting',
    url: '/',
    method: 'PUT',
    authenticate: true,
  },
  GET_GLOBALSETTING: {
    service: '/global-setting',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_GLOBALSETTING_STATUS: {
    service: '/global-setting',
    url: '/',
    method: 'PATCH',
    authenticate: true,
  },

  GET_COUNTRIES: {
    service: '/geo-graphics',
    url: '/countries',
    method: 'GET',
    authenticate: true,
  },
  GET_STATES: {
    service: '/geo-graphics',
    url: '/states',
    method: 'GET',
    authenticate: true,
  },
  GET_COUNTIES: {
    service: '/geo-graphics',
    url: '/counties',
    method: 'GET',
    authenticate: true,
  },
  GET_CITIES: {
    service: '/geo-graphics',
    url: '/cities',
    method: 'GET',
    authenticate: true,
  },

  GET_UNITS: {
    service: '/unit',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  UNIT_OPTIONS_LIST: {
    service: '/unit',
    url: '/option-list',
    method: 'GET',
    authenticate: true,
  },
  GET_SECTIONS_BY_UNIT: {
    service: '/unit',
    url: '/:id/sections',
    method: 'GET',
    authenticate: true,
  },
  GET_LESSONS_BY_UNIT: {
    service: '/unit',
    url: '/:id/lessons',
    method: 'GET',
    authenticate: true,
  },

  UPDATE_UNIT_DATA_ORDER: {
    service: '/unit',
    url: '/updateOrder',
    method: 'POST',
    authenticate: true,
  },

  DELETE_UNIT_DATA_ORDER: {
    service: '/unit',
    url: '/deleteOrder',
    method: 'DELETE',
    authenticate: true,
  },

  ADD_SECTION: {
    service: '/section',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  UPDATE_SECTION: {
    service: '/section',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  DELETE_SECTIONS: {
    service: '/section/item',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },

  DELETE_UNIT: {
    service: '/unit/item',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  SECTION_OPTIONS_LIST: {
    service: '/section',
    url: '/option-list',
    method: 'GET',
    authenticate: true,
  },

  ADD_LESSON: {
    service: '/lesson',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  VIEW_LESSON: {
    service: '/lesson',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_LESSON: {
    service: '/lesson',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  DELETE_LESSONS: {
    service: '/lesson/item',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  DELETE_DISTRICT: {
    service: '/district/item',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  DELETE_PROGRAM: {
    service: '/program/item',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  DELETE_LESSON_VIDEO: {
    service: '/lesson',
    url: '/delete-video/:id',
    method: 'DELETE',
    authenticate: true,
  },
  LESSON_OPTIONS_LIST: {
    service: '/lesson',
    url: '/option-list',
    method: 'GET',
    authenticate: true,
  },

  /*
                   --------------------  
                   --------------------
                     Course Section
                   ---------------------
                   ---------------------
  */

  /*
  -----------------
     LESSONS QUIZ
  -----------------
  */

  GET_QUIZ_QUESTIONS: {
    service: '/quiz',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  UPDATE_QUIZ_DATA: {
    service: '/quiz',
    url: '/updateQuizData/:id',
    method: 'PUT',
    authenticate: true,
  },
  DELETE_QUESTION_VIDEO: {
    service: '/quiz',
    url: '/delete-question-video/:id',
    method: 'DELETE',
    authenticate: true,
  },
  UPDATE_QUIZ_DATA_ORDER: {
    service: '/quiz',
    url: '/updateQuizDataOrder/:id',
    method: 'PUT',
    authenticate: true,
  },
  ADD_NEW_QUIZZES_PARAGRAPH: {
    service: '/quiz',
    url: '/addNewQuizParagraph',
    method: 'POST',
    authenticate: true,
  },

  DELETE_QUIZ_QUESTION: {
    service: '/quiz',
    url: '/question/:id',
    method: 'DELETE',
    authenticate: true,
  },

  /*
  -----------------
     Tests
  -----------------
  */

  GET_TEST: {
    service: '/test',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  UPDATE_TEST_INSTRUCTIONS: {
    service: '/test',
    url: '/instructions/:id',
    method: 'PATCH',
    authenticate: true,
  },

  UPDATE_TEST_DIRECTIONS: {
    service: '/test',
    url: '/directions/:id',
    method: 'PATCH',
    authenticate: true,
  },

  GET_TEST_SECTION_DATA: {
    service: '/test',
    url: '/testSectionDataById',
    method: 'GET',
    authenticate: true,
  },

  GET_TEST_UNIT_DATA: {
    service: '/test',
    url: '/testUnitDataById',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_TEST_UNIT_DATA: {
    service: '/test',
    url: '/updateTestUnit/:id',
    method: 'PUT',
    authenticate: true,
  },
  DELETE_TEST_QUESTION_VIDEO: {
    service: '/test',
    url: '/delete-question-video/:id',
    method: 'DELETE',
    authenticate: true,
  },

  UPDATE_TEST_UNIT_DATA_ORDER: {
    service: '/test',
    url: '/updateTestUnitOrder/:id',
    method: 'PUT',
    authenticate: true,
  },

  DELETE_TEST_QUESTION: {
    service: '/test',
    url: '/question/:id',
    method: 'DELETE',
    authenticate: true,
  },

  ADD_NEW_TEST_PARAGRAPH_QUESTION: {
    service: '/test',
    url: '/addNewTestParagraphQuestion',
    method: 'POST',
    authenticate: true,
  },
  /*
  -----------------
     Coupons
  -----------------
  */

  ADD_COUPON: {
    service: '/coupon',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  UPDATE_COUPON: {
    service: '/coupon',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },
  GET_COUPONS: {
    service: '/coupon',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  DELETE_COUPON: {
    service: '/coupon',
    url: '/:id',
    method: 'DELETE',
    authenticate: true,
  },
  UPDATE_COUPON_STATUS: {
    service: '/coupon',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },

  /*
  -----------------
    Badges & Certificates
  -----------------
  */

  GET_BADGES_CERTIFICATES: {
    service: '/badge-certificate',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  /*
  -----------------
    Support Request
  -----------------
  */

  GET_SUPPORTS: {
    service: '/support-request',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_SUPPORT: {
    service: '/support-request',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  UPDATE_SUPPORT_STATUS: {
    service: '/support-request',
    url: '/status/:id',
    method: 'PATCH',
    authenticate: true,
  },
  ADD_SUPPORT_REQUEST: {
    service: '/support-request',
    url: '/',
    method: 'POST',
    authenticate: true,
  },

  /*
  -----------------
    Subscription Plan Request
  -----------------
  */

  GET_SUBSCRIPTION_PLANS: {
    service: '/subscription-plan',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  UPDATE_SUBSCRIPTION_PLANS: {
    service: '/subscription-plan',
    url: '/:id',
    method: 'PATCH',
    authenticate: true,
  },

  /*
  -----------------
    Transaction Request
  -----------------
  */

  GET_TRANSACTIONS: {
    service: '/checkout/transactions',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  GET_TOTAL_SALES: {
    service: '/checkout',
    url: '/total-sales',
    method: 'GET',
    authenticate: true,
  },

  TRANSACTIONS_REFUND: {
    service: '/checkout/refund',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },

  TRANSACTIONS_NOTE: {
    service: '/checkout/update-note',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },

  GET_USER_LISTING: {
    service: '/transaction',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  ADD_OFFLINE_TRANSACTIONS: {
    service: '/transaction',
    url: '/',
    method: 'POST',
    authenticate: true,
  },
  FREE_DEMO_USER_REGISTRATION: {
    service: '/free-demo-registration',
    url: '/',
    method: 'POST',
    authenticate: true,
  },

  /*
  -----------------
    Login History Request
  -----------------
  */

  GET_STUDENT_LOGIN_HISTORY: {
    service: '/login-history',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  /*
  -----------------
    NOTIFICATION
  -----------------
  */

  GET_NOTIFICATION: {
    service: '/notification',
    url: '/',
    method: 'GET',
    authenticate: true,
  },

  UPDATE_NOTIFICATION_STATUS: {
    service: '/notification',
    url: '/update-status/:id',
    method: 'PUT',
    authenticate: true,
  },

  UPDATE_NOTIFICATION_COUNT: {
    service: '/notification',
    url: '/update-count',
    method: 'PUT',
    authenticate: true,
  },

  /*
  -----------------
    ESSAY Request
  -----------------
  */

  GET_ESSAY: {
    service: '/essay',
    url: '/super-admin',
    method: 'GET',
    authenticate: true,
  },

  VIEW_ESSAY: {
    service: '/essay',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },

  ADD_ESSAY: {
    service: '/essay',
    url: '/',
    method: 'POST',
    authenticate: true,
  },

  UPDATE_ESSAY: {
    service: '/essay',
    url: '/:id',
    method: 'PUT',
    authenticate: true,
  },

  /*
  -----------------
    DASHBOARD Request
  -----------------
  */

  GET_USERS: {
    service: '/dashboard',
    url: '/users',
    method: 'GET',
    authenticate: true,
  },
  GET_PAID_ACCOUNTS: {
    service: '/dashboard',
    url: '/paid-accounts',
    method: 'GET',
    authenticate: true,
  },
  GET_SALES: {
    service: '/dashboard',
    url: '/sales',
    method: 'GET',
    authenticate: true,
  },
  GET_SUPPORTS_COUNT: {
    service: '/dashboard',
    url: '/supports',
    method: 'GET',
    authenticate: true,
  },
  GET_ESSAYS: {
    service: '/dashboard',
    url: '/essays',
    method: 'GET',
    authenticate: true,
  },
  GET_DEMO_USERS: {
    service: '/dashboard',
    url: '/demo-users',
    method: 'GET',
    authenticate: true,
  },
  GET_THIS_YEAR_PAID_ACCOUNTS: {
    service: '/dashboard',
    url: '/current-paid-accounts',
    method: 'GET',
    authenticate: true,
  },

  // district dashboard

  GET_DISTRICT_DASHBOARD: {
    service: '/dashboard',
    url: '/district-program-dashboard',
    method: 'GET',
    authenticate: true,
  },

  GET_FLAGGED_QUESTIONS: {
    service: '/test',
    url: '/flag-questions',
    method: 'GET',
    authenticate: true,
  },

  /* ***Linkage Request*** */
  LIST_ORGANIZATIONS: {
    service: '/district-program',
    url: '/list-org',
    method: 'POST',
    authenticate: true,
  },
  GENERATE_LINKAGE: {
    service: '/district-program',
    url: '/linkage-request',
    method: 'POST',
    authenticate: true,
  },
  LIST_LINKAGE_REQUEST: {
    service: '/district-program',
    url: '/list-org-request',
    method: 'GET',
    authenticate: true,
  },
  LIST_PROGRAM_DISTRICT: {
    service: '/district-program',
    url: '/list',
    method: 'POST',
    authenticate: true,
  },
  LIST_ADMIN_LINKAGE: {
    service: '/district-program',
    url: '/list-admin-linkage',
    method: 'POST',
    authenticate: true,
  },
  ATTEMPT_REQUEST: {
    service: '/district-program',
    url: '/attempt-request/:id',
    method: 'PUT',
    authenticate: true,
  },
  UNLINK_LINKAGE: {
    service: '/district-program',
    url: '/unlink/:id',
    method: 'PUT',
    authenticate: true,
  },
  LIST_LINKED_ORGANIZATIONS: {
    service: '/district-program',
    url: '/list-linked-org',
    method: 'POST',
    authenticate: true,
  },

  FORGOT_PASSWORD: {
    service: '/auth',
    url: '/forgot-password',
    method: 'GET',
    authenticate: true,
  },

  // test report

  GET_STUDENT_TEST_COMPLETION: {
    service: '/report',
    url: '/test-completion',
    method: 'GET',
    authenticate: true,
  },

  GET_STUDENT_LIST: {
    service: '/report',
    url: '/list-ind-student',
    method: 'GET',
    authenticate: true,
  },
  GET_SCHOOL_LIST: {
    service: '/report',
    url: '/list-school',
    method: 'GET',
    authenticate: true,
  },
  GET_PROGRAM_SITE_LIST: {
    service: '/report',
    url: '/list-programsite',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_LIST: {
    service: '/report',
    url: '/list-district',
    method: 'GET',
    authenticate: true,
  },
  GET_PROGRAM_LIST: {
    service: '/report',
    url: '/list-program',
    method: 'GET',
    authenticate: true,
  },

  GET_DISTRICT_STUDENT_TEST_COMPLETION: {
    service: '/report',
    url: '/dist-test-completion',
    method: 'GET',
    authenticate: true,
  },
  GET_LINKED_ORGANIZATION: {
    service: '/report',
    url: '/list-linked-organization',
    method: 'GET',
    authenticate: true,
  },
  GET_STUDENT_SCORE_COMPARISON: {
    service: '/report',
    url: '/act-score-comparison',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_STUDENT_SCORE_COMPARISON: {
    service: '/report',
    url: '/dist-act-score-comparison',
    method: 'GET',
    authenticate: true,
  },
  GET_STUDENT_ACT_PERFORMANCE_REPORT: {
    service: '/report',
    url: '/act-performance',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_ACT_PERFORMANCE_REPORT: {
    service: '/report',
    url: '/dist-act-performance',
    method: 'GET',
    authenticate: true,
  },

  GET_STUDENT_QUIZ_PROGRESS_REPORT: {
    service: '/report',
    url: '/admin-quiz-progress-score',
    method: 'GET',
    authenticate: true,
  },
  GET_STUDENT_QUIZ_PROGRESS_TEST_SCORE_REPORT: {
    service: '/report',
    url: '/admin-quiz-progress-test-score',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_QUIZ_PROGRESS_REPORT: {
    service: '/report',
    url: '/dist-quiz-progress-score',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_QUIZ_PROGRESS_TEST_SCORE_REPORT: {
    service: '/report',
    url: '/district-quiz-progress-test-score',
    method: 'GET',
    authenticate: true,
  },

  GET_STUDENT_ACT_DETAILED_REPORT: {
    service: '/report',
    url: '/act-detailed',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_ACT_DETAILED_REPORT: {
    service: '/report',
    url: '/dist-act-detailed',
    method: 'GET',
    authenticate: true,
  },

  GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT: {
    service: '/report',
    url: '/admin-student-course-progress-overview',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_COURSE_PROGRESS_OVERVIEW_REPORT: {
    service: '/report',
    url: '/dist-student-course-progress-overview',
    method: 'GET',
    authenticate: true,
  },

  GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT: {
    service: '/report',
    url: '/admin-student-act-score-progress-overview',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT: {
    service: '/report',
    url: '/dist-student-act-score-progress-overview',
    method: 'GET',
    authenticate: true,
  },

  GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT: {
    service: '/report',
    url: '/admin-student-quiz-progress-overview',
    method: 'GET',
    authenticate: true,
  },
  GET_DISTRICT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT: {
    service: '/report',
    url: '/dist-student-quiz-progress-overview',
    method: 'GET',
    authenticate: true,
  },

  GET_CONTACT: {
    service: '/contact',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_CONTACT: {
    service: '/contact',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
  GET_FREE_DEMO: {
    service: '/free-demo',
    url: '/',
    method: 'GET',
    authenticate: true,
  },
  VIEW_FREE_DEMO: {
    service: '/free-demo',
    url: '/:id',
    method: 'GET',
    authenticate: true,
  },
};

export default ApiRoutes;
