/** @format */
export const EnviornmentType = {
  DEV: 'development',
  PROD: 'production',
};

export const env = process.env.NODE_ENV || EnviornmentType.DEV;

export const AppConfig = {
  DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME,
  FILES_ENDPOINT: process.env.REACT_APP_FILES_ENDPOINT,
  API_ENDPOINT:
    process.env.REACT_APP_API ||
    process.env.REACT_APP_API_ENDPOINT ||
    'https://api-dev.upscoretestprep.com/api',
  APP_ENDPOINT: process.env.REACT_APP_ENDPOINT,
  SOCKET_ENDPOINT: process.env.REACT_APP_SOCKET_ENDPOINT,
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY,
  API_VERSION: process.env.REACT_APP_API_VERSION,
  DEFAULT_DATE_FORMAT: process.env.REACT_APP_DEFAULT_DATE_FORMAT,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  REACT_APP_AWS_S3_BUCKET_NAME: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  REACT_APP_AWS_S3_BUCKET_REGION: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
};
