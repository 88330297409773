/** @format */

import { createLogic } from 'redux-logic';
import {
  TestActions,
  requestTestList,
  testListSuccess,
  testListFailed,
  updateTestInstructionFailed,
  updateTestInstructionSuccess,
  testUnitDataFailed,
  testUnitDataSuccess,
  updateTestUnitDataOrderFailed,
  updateTestUnitDataOrderSuccess,
  updateTestUnitDataFailed,
  updateTestUnitDataSuccess,
  testSectionDataFailed,
  testSectionDataSuccess,
  updateTestDirectionFailed,
  updateTestDirectionSuccess,
  deleteTestQuestionFailed,
  deleteTestQuestionSuccess,
  addTestParagraphOrQuestionSuccess,
  addTestParagraphOrQuestionFailed,
  flaggedQuestionsListSuccess,
  flaggedQuestionsListFailed,
  deleteTestQuestionVideoSuccess,
  deleteTestQuestionVideoFailed,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

const getTestsLogic = createLogic({
  type: TestActions.REQUEST_TEST_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TEST.service,
      ApiRoutes.GET_TEST.url,
      ApiRoutes.GET_TEST.method,
      ApiRoutes.GET_TEST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(testListFailed());
      done();
    }
    dispatch(
      testListSuccess({
        tests: responseData.data,
      })
    );
    done();
  },
});

const updateTestInstructionsLogic = createLogic({
  type: TestActions.UPDATE_TEST_INSTRUCTIONS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      // data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_TEST_INSTRUCTIONS.service,
      ApiRoutes.UPDATE_TEST_INSTRUCTIONS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_TEST_INSTRUCTIONS.method,
      ApiRoutes.UPDATE_TEST_INSTRUCTIONS.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(updateTestInstructionFailed(messages[0]));
      done();
    }
    dispatch(requestTestList());
    dispatch(updateTestInstructionSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const updateTestDirectionsLogic = createLogic({
  type: TestActions.UPDATE_TEST_DIRECTIONS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      // data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_TEST_DIRECTIONS.service,
      ApiRoutes.UPDATE_TEST_DIRECTIONS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_TEST_DIRECTIONS.method,
      ApiRoutes.UPDATE_TEST_DIRECTIONS.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(updateTestDirectionFailed(messages[0]));
      done();
    }
    dispatch(requestTestList());
    dispatch(updateTestDirectionSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const getTestUnitDataLogic = createLogic({
  type: TestActions.REQUEST_TEST_UNIT_DATA,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TEST_UNIT_DATA.service,
      ApiRoutes.GET_TEST_UNIT_DATA.url,
      ApiRoutes.GET_TEST_UNIT_DATA.method,
      ApiRoutes.GET_TEST_UNIT_DATA.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(testUnitDataFailed());
      done();
    }
    dispatch(
      testUnitDataSuccess({
        testUnitData: responseData.data,
      })
    );
    done();
  },
});

const deleteQuestionVideoLogic = createLogic({
  type: TestActions.DELETE_TEST_QUESTION_VIDEO_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_TEST_QUESTION_VIDEO.service,
      ApiRoutes.DELETE_TEST_QUESTION_VIDEO.url.replace(
        ':id',
        action.payload.id
      ),
      ApiRoutes.DELETE_TEST_QUESTION_VIDEO.method,
      ApiRoutes.DELETE_TEST_QUESTION_VIDEO.authenticate,
      undefined,
      action.payload.values
    );

    if (isError) {
      showError(messages[0]);
      dispatch(deleteTestQuestionVideoFailed());
      done();
    }

    dispatch(deleteTestQuestionVideoSuccess());
    done();
  },
});

const getTestSectionDataLogic = createLogic({
  type: TestActions.REQUEST_TEST_SECTION_DATA,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TEST_SECTION_DATA.service,
      ApiRoutes.GET_TEST_SECTION_DATA.url,
      ApiRoutes.GET_TEST_SECTION_DATA.method,
      ApiRoutes.GET_TEST_SECTION_DATA.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(testSectionDataFailed());
      done();
    }
    dispatch(
      testSectionDataSuccess({
        testSectionData: responseData.data
          ? responseData.data[0]
          : responseData.data,
      })
    );
    done();
  },
});

const updateTestUnitDataLogic = createLogic({
  type: TestActions.UPDATE_TEST_UNIT_DATA_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_TEST_UNIT_DATA.service,
      ApiRoutes.UPDATE_TEST_UNIT_DATA.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_TEST_UNIT_DATA.method,
      ApiRoutes.UPDATE_TEST_UNIT_DATA.authenticate,
      undefined,
      action.payload.values
    );

    if (isError) {
      showError(messages[0]);
      dispatch(updateTestUnitDataFailed());
      done();
    }

    dispatch(updateTestUnitDataSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const updateTestUnitDataOrderLogic = createLogic({
  type: TestActions.UPDATE_TEST_UNIT_DATA_ORDER_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_TEST_UNIT_DATA_ORDER.service,
      ApiRoutes.UPDATE_TEST_UNIT_DATA_ORDER.url.replace(
        ':id',
        action.payload.id
      ),
      ApiRoutes.UPDATE_TEST_UNIT_DATA_ORDER.method,
      ApiRoutes.UPDATE_TEST_UNIT_DATA_ORDER.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(updateTestUnitDataOrderFailed(messages[0]));
      done();
    }
    dispatch(updateTestUnitDataOrderSuccess());
    showSuccess(messages[0]);
    done();
  },
});

/**
 -------------------------
    DELETE Test Question
 -------------------------
 */
const deleteTestQuestionLogic = createLogic({
  type: TestActions.REQUEST_DELETE_TEST_QUESTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_TEST_QUESTION.service,
      ApiRoutes.DELETE_TEST_QUESTION.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_TEST_QUESTION.method,
      ApiRoutes.DELETE_TEST_QUESTION.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(deleteTestQuestionFailed());
      done();
    }
    dispatch(deleteTestQuestionSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const addNewTestParagraphOrQuestionLogic = createLogic({
  type: TestActions.REQUEST_ADD_NEW_TEST_PARAGRAPH_QUESTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_NEW_TEST_PARAGRAPH_QUESTION.service,
      ApiRoutes.ADD_NEW_TEST_PARAGRAPH_QUESTION.url,
      ApiRoutes.ADD_NEW_TEST_PARAGRAPH_QUESTION.method,
      ApiRoutes.ADD_NEW_TEST_PARAGRAPH_QUESTION.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addTestParagraphOrQuestionSuccess({ data: responseData.data }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(
        addTestParagraphOrQuestionFailed({
          validationErrors,
        })
      );
      done();
    }
  },
});

const getFlaggedQuestionsLogic = createLogic({
  type: TestActions.FLAGGED_QUESTIONS_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FLAGGED_QUESTIONS.service,
      ApiRoutes.GET_FLAGGED_QUESTIONS.url,
      ApiRoutes.GET_FLAGGED_QUESTIONS.method,
      ApiRoutes.GET_FLAGGED_QUESTIONS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      dispatch(flaggedQuestionsListFailed());
      done();
    }
    dispatch(
      flaggedQuestionsListSuccess({
        flaggedQuestions: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

export const TestLogics = [
  getTestsLogic,
  updateTestInstructionsLogic,
  updateTestDirectionsLogic,
  getTestUnitDataLogic,
  updateTestUnitDataLogic,
  updateTestUnitDataOrderLogic,
  getTestSectionDataLogic,
  deleteTestQuestionLogic,
  addNewTestParagraphOrQuestionLogic,
  getFlaggedQuestionsLogic,
  deleteQuestionVideoLogic,
];
