import { createAction } from 'redux-actions';

export const BadgesAndCertificatesActions = {
  REQUEST_BADGES_AND_CERTIFICATES_LIST:
    'Request to fetch Badges And Certificates',
  BADGES_AND_CERTIFICATES_LIST_SUCCESS:
    'Badges And Certificates fetched successfully',
  BADGES_AND_CERTIFICATES_LIST_FAILURE:
    'Failed to fetch Badges And Certificates',
};

// List badgesAndCertificates actions
export const requestBadgesAndCertificatesList = createAction(
  BadgesAndCertificatesActions.REQUEST_BADGES_AND_CERTIFICATES_LIST
);
export const badgesAndCertificatesListSuccess = createAction(
  BadgesAndCertificatesActions.BADGES_AND_CERTIFICATES_LIST_SUCCESS
);
export const badgesAndCertificatesListFailed = createAction(
  BadgesAndCertificatesActions.BADGES_AND_CERTIFICATES_LIST_FAILURE
);
