/** @format */

import { handleActions } from 'redux-actions';
import { ReportActions } from '../actions';
import { ReportInitialStates } from '../states/Report';

export const ReportReducer = handleActions(
  {
    [ReportActions.REQUEST_STUDENTS_LOGIN_HISTORY_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoginHistoryLoading: true,
    }),
    [ReportActions.STUDENT_LOGIN_HISTORY_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoginHistoryLoading: false,
      UserList: action.payload.UserList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.STUDENT_LOGIN_HISTORY_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoginHistoryLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_STUDENTS_TEST_COMPLETION_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isTestCompletionLoading: true,
    }),
    [ReportActions.STUDENT_TEST_COMPLETION_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isTestCompletionLoading: false,
      studentTestReportList: action.payload.studentTestReportList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.STUDENT_TEST_COMPLETION_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isTestCompletionLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_TEST_COMPLETION_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isTestCompletionLoading: true,
    }),
    [ReportActions.DISTRICT_STUDENTS_TEST_COMPLETION_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isTestCompletionLoading: false,
      districtstudentTestReportList:
        action.payload.districtstudentTestReportList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.DISTRICT_STUDENS_TEST_COMPLETION_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isTestCompletionLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_STUDENTS_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ReportActions.STUDENTS_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      studentList: action.payload.studentList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.STUDENTS_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_SCHOOLS_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ReportActions.SCHOOLS_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      schoolList: action.payload.schoolList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.SCHOOLS_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_PROGRAM_SITE_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ReportActions.PROGRAM_SITE_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programsiteList: action.payload.programsiteList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.PROGRAM_SITE_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isDistrictLoading: true,
    }),
    [ReportActions.DISTRICT_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isDistrictLoading: false,
      districtList: action.payload.districtList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.DISTRICT_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isDistrictLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_PROGRAM_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ReportActions.PROGRAM_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programList: action.payload.programList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.PROGRAM_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_LINKED_ORGANIZATION_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      islinkedOrganizationLoading: true,
    }),
    [ReportActions.LINKED_ORGANIZATION_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      islinkedOrganizationLoading: false,
      linkedOrganizationList: action.payload.linkedOrganizationList,
      totalRecords: action.payload.totalRecords,
    }),
    [ReportActions.LINKED_ORGANIZATION_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      islinkedOrganizationLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_STUDENTS_SCORE_COMPARISON_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isScoreComparisonLoading: true,
    }),
    [ReportActions.STUDENTS_SCORE_COMPARISON_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isScoreComparisonLoading: false,
      studentScoreComparisonReportList:
        action.payload.studentScoreComparisonReportList,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.STUDENTS_SCORE_COMPARISON_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isScoreComparisonLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_SCORE_COMPARISON_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isScoreComparisonLoading: true,
    }),
    [ReportActions.DISTRICT_STUDENTS_SCORE_COMPARISON_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isScoreComparisonLoading: false,
      studentScoreComparisonReportList:
        action.payload.studentScoreComparisonReportList,
      // totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.DISTRICT_STUDENTS_SCORE_COMPARISON_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isScoreComparisonLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_STUDENTS_ACT_PERFORMANCE_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActPerformanceLoading: true,
    }),
    [ReportActions.STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActPerformanceLoading: false,
      studentReportData: action.payload.studentReportData,
      totalStudent: action.payload.totalStudent,
      testStudent: action.payload.testStudent,
    }),
    [ReportActions.STUDENTS_ACT_PERFORMANCE_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActPerformanceLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActPerformanceLoading: true,
    }),
    [ReportActions.DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActPerformanceLoading: false,
      studentReportData: action.payload.studentReportData,
      totalStudent: action.payload.totalStudent,
      testStudent: action.payload.testStudent,
    }),
    [ReportActions.DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActPerformanceLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_STUDENTS_QUIZ_PROGRESS_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizProgressLoading: true,
    }),
    [ReportActions.STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizProgressLoading: false,
      studentReportData: action.payload.studentReportData,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.STUDENTS_QUIZ_PROGRESS_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizProgressLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizProgressLoading: true,
    }),
    [ReportActions.DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizProgressLoading: false,
      studentReportData: action.payload.studentReportData,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizProgressLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_STUDENTS_ACT_DETAILED_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActDetailLoading: true,
    }),
    [ReportActions.STUDENTS_ACT_DETAILED_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActDetailLoading: false,
      actDetailReportData: action.payload.studentReportData,
      totalStudent: action.payload.totalStudent,
      testStudent: action.payload.testStudent,
    }),
    [ReportActions.STUDENTS_ACT_DETAILED_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActDetailLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_DETAILED_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActDetailLoading: true,
    }),
    [ReportActions.DISTRICT_STUDENTS_ACT_DETAILED_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActDetailLoading: false,
      actDetailReportData: action.payload.studentReportData,
      totalStudent: action.payload.totalStudent,
      testStudent: action.payload.testStudent,
    }),
    [ReportActions.DISTRICT_STUDENTS_ACT_DETAILED_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActDetailLoading: false,
      error: true,
    }),

    // course
    [ReportActions.REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isCourseOverviewLoading: true,
    }),
    [ReportActions.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isCourseOverviewLoading: false,
      studentCourseReportData: action.payload.studentCourseReportData,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isCourseOverviewLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isCourseOverviewLoading: true,
    }),
    [ReportActions.DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isCourseOverviewLoading: false,
      studentCourseReportData: action.payload.studentCourseReportData,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isCourseOverviewLoading: false,
      error: true,
    }),

    // act score
    [ReportActions.REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActScoreOverviewLoading: true,
    }),
    [ReportActions.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActScoreOverviewLoading: false,
      studentActScoreReportData: action.payload.studentActScoreReportData,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isActScoreOverviewLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST]:
      (state = ReportInitialStates, action) => ({
        ...state,
        isActScoreOverviewLoading: true,
      }),
    [ReportActions.DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS]:
      (state = ReportInitialStates, action) => ({
        ...state,
        isActScoreOverviewLoading: false,
        studentActScoreReportData: action.payload.studentActScoreReportData,
        totalStudent: action.payload.totalStudent,
      }),
    [ReportActions.DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE]:
      (state = ReportInitialStates, action) => ({
        ...state,
        isActScoreOverviewLoading: false,
        error: true,
      }),

    // quiz score
    [ReportActions.REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizScoreOverviewLoading: true,
    }),
    [ReportActions.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizScoreOverviewLoading: false,
      studentQuizScoreReportData: action.payload.studentQuizScoreReportData,
      totalStudent: action.payload.totalStudent,
    }),
    [ReportActions.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE]: (
      state = ReportInitialStates,
      action
    ) => ({
      ...state,
      isQuizScoreOverviewLoading: false,
      error: true,
    }),

    [ReportActions.REQUEST_DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST]:
      (state = ReportInitialStates, action) => ({
        ...state,
        isQuizScoreOverviewLoading: true,
      }),
    [ReportActions.DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS]:
      (state = ReportInitialStates, action) => ({
        ...state,
        isQuizScoreOverviewLoading: false,
        studentQuizScoreReportData: action.payload.studentQuizScoreReportData,
        totalStudent: action.payload.totalStudent,
      }),
    [ReportActions.DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE]:
      (state = ReportInitialStates, action) => ({
        ...state,
        isQuizScoreOverviewLoading: false,
        error: true,
      }),
  },

  ReportInitialStates
);
