/** @format */

import { createLogic } from 'redux-logic';
import {
  TermsConditionActions,
  updateTermsConditionSuccess,
  updateTermsConditionFailed,
  getTermsConditionRequest,
  getTermsConditionSuccess,
  getTermsConditionFailed,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import { ApiHelper } from '../../helpers';

/**
 ------------------------
    GET TERMS & CONDITION RECORDS
 ------------------------
 */
const getTermsConditionLogic = createLogic({
  type: TermsConditionActions.GET_TERMS_CONDITION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TERMSCONDITION.service,
      ApiRoutes.GET_TERMSCONDITION.url,
      ApiRoutes.GET_TERMSCONDITION.method,
      ApiRoutes.GET_TERMSCONDITION.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(getTermsConditionFailed());
      done();
    }

    dispatch(
      getTermsConditionSuccess({
        termsConditionData: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const updateTermsConditionLogic = createLogic({
  type: TermsConditionActions.UPDATE_TERMSCONDITION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_TERMSCONDITION.service,
      ApiRoutes.UPDATE_TERMSCONDITION.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_TERMSCONDITION.method,
      ApiRoutes.UPDATE_TERMSCONDITION.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateTermsConditionSuccess());
      showSuccess(messages[0]);
      dispatch(getTermsConditionRequest());
      done();
    } else {
      showError(messages[0]);
      dispatch(updateTermsConditionFailed());
      done();
    }
  },
});

export const TermsConditionLogics = [
  getTermsConditionLogic,
  updateTermsConditionLogic,
];
