/** @format */

import { createLogic } from 'redux-logic';
import {
  getDistrictRecordSuccess,
  getDistrictRecordFailed,
  DistrictActions,
  DistrictAddFailed,
  districtAddSuccess,
  DistrictListFailed,
  DistrictListSuccess,
  updateDistrictRecordFailed,
  updateDistrictRecordSuccess,
  updateDistrictStatusSuccess,
  updateDistrictStatusFailed,
  requestDistrictList,
  districtOptionListSuccess,
  districtOptionListFailed,
  failedDeleteDistrict,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import { ApiHelper } from '../../helpers/ApiHelper';

/**
                      -----------------
                      -----------------
                          DISTRICT 
                      -----------------
                      -----------------
 */
/**
 ----------------------
    Add NEW DISTRICT
 ----------------------
 */
const AddDistrictLogic = createLogic({
  type: DistrictActions.ADD_DISTRICT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_DISTRICT.service,
      ApiRoutes.ADD_DISTRICT.url,
      ApiRoutes.ADD_DISTRICT.method,
      ApiRoutes.ADD_DISTRICT.authenticate,
      undefined,
      action.payload,
    );
    if (!isError) {
      dispatch(districtAddSuccess());
      showSuccess(messages[0]);
      dispatch(requestDistrictList());
      done();
    } else {
      showError(messages[0]);
      dispatch(DistrictAddFailed());
      done();
    }
  },
});

/**
 ------------------------
    All DISTRICT LISTING
 ------------------------
 */
const DistrictListingLogic = createLogic({
  type: DistrictActions.DISTRICT_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT.service,
      ApiRoutes.GET_DISTRICT.url,
      ApiRoutes.GET_DISTRICT.method,
      ApiRoutes.GET_DISTRICT.authenticate,
      action.payload,
      undefined,
    );
    if (isError) {
     if (action.payload.search !== '') {
       showError('Invalid search');
     } else {
       showError(messages[0]);
     }
      dispatch(DistrictListFailed());
      done();
    }
    dispatch(
      DistrictListSuccess({
        districts: responseData.data,
        totalRecords: responseData.pages ? responseData.pages : 0,
      }),
    );
    done();
  },
});

/**
 ---------------------------
    Update DISTRICT RECORDS
 ---------------------------
 */
const UpdateDistrictRecordLogic = createLogic({
  type: DistrictActions.UPDATE_DISTRICT_RECORD_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_DISTRICT.service,
      ApiRoutes.UPDATE_DISTRICT.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_DISTRICT.method,
      ApiRoutes.UPDATE_DISTRICT.authenticate,
      undefined,
      action.payload.values,
    );
    if (!isError) {
      dispatch(updateDistrictRecordSuccess());
      dispatch(requestDistrictList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateDistrictRecordFailed());
      done();
    }
  },
});

/**
 ------------------------
    GET DISTRICT RECORDS
 ------------------------
 */
const getDistrictRecordLogic = createLogic({
  type: DistrictActions.GET_DISTRICT_RECORD_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_DISTRICT.service,
      ApiRoutes.VIEW_DISTRICT.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_DISTRICT.method,
      ApiRoutes.VIEW_DISTRICT.authenticate,
      undefined,
      undefined,
    );
    if (isError) {
      showError(messages[0]);
      dispatch(getDistrictRecordFailed());
      done();
    }

    dispatch(
      getDistrictRecordSuccess({
        districtData: responseData.data,
      }),
    );
    done();
  },
});

/**
 ---------------------------
    Delete DISTRICT RECORDS
 ---------------------------
 */

const deleteDistrictLogic = createLogic({
  type: DistrictActions.DELETE_DISTRICT_RECORD_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    let serviceAndUrl = {
      service: ApiRoutes.DELETE_DISTRICT.service,
      url: ApiRoutes.DELETE_DISTRICT.url.replace(':id', action.payload.id),
    };
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      serviceAndUrl.service,
      serviceAndUrl.url,
      ApiRoutes.DELETE_DISTRICT.method,
      ApiRoutes.DELETE_DISTRICT.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(requestDistrictList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(failedDeleteDistrict({ validationErrors }));
      done();
    }
  },
});

/**
 ---------------------------
    Update DISTRICT STATUS
 ---------------------------
 */
const UpdateDistrictStatusLogic = createLogic({
  type: DistrictActions.UPDATE_DISTRICT_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_DISTRICT_STATUS.service,
      ApiRoutes.UPDATE_DISTRICT_STATUS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_DISTRICT_STATUS.method,
      ApiRoutes.UPDATE_DISTRICT_STATUS.authenticate,
      undefined,
      { status: action.payload.status },
    );
    if (!isError) {
      dispatch(updateDistrictStatusSuccess());
      dispatch(
        requestDistrictList({
          ...action.payload.query,
        }),
      );
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateDistrictStatusFailed());
      done();
    }
  },
});

/**
 ----------------------------------------------
    GET ALL ACTIVE DISTRICT FOR DISTRICT SUPER ADMIN
 ----------------------------------------------
 */
const getDistrictOptionsListLogic = createLogic({
  type: DistrictActions.DISTRICT_OPTION_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DISTRICT_OPTIONS_LIST.service,
      ApiRoutes.DISTRICT_OPTIONS_LIST.url,
      ApiRoutes.DISTRICT_OPTIONS_LIST.method,
      ApiRoutes.DISTRICT_OPTIONS_LIST.authenticate,
      action.payload,
      undefined,
    );

    if (isError) {
      showError(messages[0]);
      dispatch(districtOptionListFailed());
      done();
    }
    dispatch(
      districtOptionListSuccess({
        districtOptions: responseData.data,
      }),
    );
    done();
  },
});

export const DistrictLogics = [
  /*
-----------------
      DISTRICT
----------------
*/
  AddDistrictLogic,
  DistrictListingLogic,
  UpdateDistrictRecordLogic,
  getDistrictRecordLogic,
  UpdateDistrictStatusLogic,
  deleteDistrictLogic,
  getDistrictOptionsListLogic,
];
