/** @format */

import { createAction } from 'redux-actions';

export const ReportActions = {
  // student
  REQUEST_STUDENTS_LIST: 'Request to fetch Students List',
  STUDENTS_LIST_SUCCESS: 'Students List fetched successfully',
  STUDENTS_LIST_FAILURE: 'Failed to fetched students List',

  // school
  REQUEST_SCHOOLS_LIST: 'Request to fetch Schools List',
  SCHOOLS_LIST_SUCCESS: 'Schools List fetched successfully',
  SCHOOLS_LIST_FAILURE: 'Failed to fetched Schools List',

  // Program Site
  REQUEST_PROGRAM_SITE_LIST: 'Request to fetch Program Site List',
  PROGRAM_SITE_LIST_SUCCESS: 'Program Site List fetched successfully',
  PROGRAM_SITE_LIST_FAILURE: 'Failed to fetched Program Site List',

  // District
  REQUEST_DISTRICT_LIST: 'Request to fetch District List',
  DISTRICT_LIST_SUCCESS: 'District List fetched successfully',
  DISTRICT_LIST_FAILURE: 'Failed to fetched District List',

  // Program
  REQUEST_PROGRAM_LIST: 'Request to fetch Program List',
  PROGRAM_LIST_SUCCESS: 'Program List fetched successfully',
  PROGRAM_LIST_FAILURE: 'Failed to fetched Program List',

  REQUEST_STUDENTS_LOGIN_HISTORY_LIST:
    'Request to fetch Students Login History List',
  STUDENT_LOGIN_HISTORY_LIST_SUCCESS:
    'Students Login History List fetched successfully',
  STUDENT_LOGIN_HISTORY_LIST_FAILURE:
    'Failed to fetched students Login History List',

  REQUEST_STUDENTS_TEST_COMPLETION_LIST:
    'Request to fetch Students Test Completion  List',
  STUDENT_TEST_COMPLETION_LIST_SUCCESS:
    'Students Test Completion  List fetched successfully',
  STUDENT_TEST_COMPLETION_LIST_FAILURE:
    'Failed to fetched students Test Completion  List',

  REQUEST_DISTRICT_STUDENTS_TEST_COMPLETION_LIST:
    'Request to fetch  District Students Test Completion  List',
  DISTRICT_STUDENTS_TEST_COMPLETION_LIST_SUCCESS:
    'District Students Test Completion  List fetched successfully',
  DISTRICT_STUDENS_TEST_COMPLETION_LIST_FAILURE:
    'District Failed to fetched students Test Completion List',

  REQUEST_LINKED_ORGANIZATION_LIST:
    'Request to fetch Linked Organization  List',
  LINKED_ORGANIZATION_LIST_SUCCESS:
    'Linked Organization  List fetched successfully',
  LINKED_ORGANIZATION_LIST_FAILURE: 'Failed to fetched Linked Organization',

  REQUEST_STUDENTS_SCORE_COMPARISON_LIST:
    'Request to fetch Students Act Score Comparison List',
  STUDENTS_SCORE_COMPARISON_LIST_SUCCESS:
    'Students Act Score Comparison List fetched successfully',
  STUDENTS_SCORE_COMPARISON_LIST_FAILURE:
    'Failed to fetched students Act Score Comparison List',

  REQUEST_DISTRICT_STUDENTS_SCORE_COMPARISON_LIST:
    'Request to fetch District Students Act Score Comparison List',
  DISTRICT_STUDENTS_SCORE_COMPARISON_LIST_SUCCESS:
    'District Students Act Score Comparison List fetched successfully',
  DISTRICT_STUDENTS_SCORE_COMPARISON_LIST_FAILURE:
    'Failed to fetched District Students Act Score Comparison List',

  REQUEST_STUDENTS_ACT_PERFORMANCE_LIST:
    'Request to fetch Students Act Performance List',
  STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS:
    'Students Act Performance List fetched successfully',
  STUDENTS_ACT_PERFORMANCE_LIST_FAILURE:
    'Failed to fetched students Act Performance List',

  REQUEST_DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST:
    'Request to fetch District Students Act Performance List',
  DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS:
    'District Students Act Performance List fetched successfully',
  DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST_FAILURE:
    'Failed to fetched District students Act Performance List',

  REQUEST_STUDENTS_QUIZ_PROGRESS_LIST:
    'Request to fetch Students Quiz Progress List',
  STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS:
    'Students Quiz Progress List fetched successfully',
  STUDENTS_QUIZ_PROGRESS_LIST_FAILURE:
    'Failed to fetched students Quiz Progress List',

  REQUEST_DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST:
    'Request to fetch District Students Quiz Progress List',
  DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS:
    'District Students Quiz Progress List fetched successfully',
  DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST_FAILURE:
    'Failed to fetched District students Quiz Progress List',

  REQUEST_STUDENTS_ACT_DETAILED_LIST:
    'Request to fetch Students Act Detailed List',
  STUDENTS_ACT_DETAILED_LIST_SUCCESS:
    'Students Act Detailed List fetched successfully',
  STUDENTS_ACT_DETAILED_LIST_FAILURE:
    'Failed to fetched students Act Detailed List',

  REQUEST_DISTRICT_STUDENTS_ACT_DETAILED_LIST:
    'Request to fetch District Students Act Detailed List',
  DISTRICT_STUDENTS_ACT_DETAILED_LIST_SUCCESS:
    'District Students Act Detailed List fetched successfully',
  DISTRICT_STUDENTS_ACT_DETAILED_LIST_FAILURE:
    'Failed to fetched District students Act Detailed List',

  REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST:
    'Request to fetch Students Course Progress Overview List',
  STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    'Students Course Progress Overview List fetched successfully',
  STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE:
    'Failed to fetched students Course Progress Overview List',

  REQUEST_DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST:
    'Request to fetch District Students Course Progress Overview List',
  DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    'District Students Course Progress Overview List fetched successfully',
  DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE:
    'Failed to fetched District students Course Progress Overview List',

  REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST:
    'Request to fetch Students ACT Score Progress Overview List',
  STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    'Students ACT Score Progress Overview List fetched successfully',
  STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
    'Failed to fetched students ACT Score Progress Overview List',

  REQUEST_DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST:
    'Request to fetch District Students ACT Score Progress Overview List',
  DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    'District Students ACT Score Progress Overview List fetched successfully',
  DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
    'Failed to fetched District students ACT Score Progress Overview List',

  REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST:
    'Request to fetch Students Quiz Score Progress Overview List',
  STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    'Students Quiz Score Progress Overview List fetched successfully',
  STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
    'Failed to fetched students Quiz Score Progress Overview List',

  REQUEST_DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST:
    'Request to fetch District Students Quiz Score Progress Overview List',
  DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    'District Students Quiz Score Progress Overview List fetched successfully',
  DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
    'Failed to fetched District students Quiz Score Progress Overview List',
};

// List student actions
export const requestReportStudentList = createAction(
  ReportActions.REQUEST_STUDENTS_LIST
);
export const reportStudentListSuccess = createAction(
  ReportActions.STUDENTS_LIST_SUCCESS
);
export const reportStudentListFailed = createAction(
  ReportActions.STUDENTS_LIST_FAILURE
);

// List school actions
export const requestReportSchoolList = createAction(
  ReportActions.REQUEST_SCHOOLS_LIST
);
export const reportSchoolListSuccess = createAction(
  ReportActions.SCHOOLS_LIST_SUCCESS
);
export const reportSchoolListFailed = createAction(
  ReportActions.SCHOOLS_LIST_FAILURE
);

// List program site actions
export const requestReportProgramSiteList = createAction(
  ReportActions.REQUEST_PROGRAM_SITE_LIST
);
export const reportProgramSiteListSuccess = createAction(
  ReportActions.PROGRAM_SITE_LIST_SUCCESS
);
export const reportProgramSiteListFailed = createAction(
  ReportActions.PROGRAM_SITE_LIST_FAILURE
);

// List district actions
export const requestReportDistrictList = createAction(
  ReportActions.REQUEST_DISTRICT_LIST
);
export const reportDistrictListSuccess = createAction(
  ReportActions.DISTRICT_LIST_SUCCESS
);
export const reportDistrictListFailed = createAction(
  ReportActions.DISTRICT_LIST_FAILURE
);

// List program actions
export const requestReportProgramList = createAction(
  ReportActions.REQUEST_PROGRAM_LIST
);
export const reportProgramListSuccess = createAction(
  ReportActions.PROGRAM_LIST_SUCCESS
);
export const reportProgramListFailed = createAction(
  ReportActions.PROGRAM_LIST_FAILURE
);

// List student  login report actions
export const requestStudentLoginHistoryList = createAction(
  ReportActions.REQUEST_STUDENTS_LOGIN_HISTORY_LIST
);
export const studentLoginHistoryListSuccess = createAction(
  ReportActions.STUDENT_LOGIN_HISTORY_LIST_SUCCESS
);
export const studentLoginHistoryListFailed = createAction(
  ReportActions.STUDENT_LOGIN_HISTORY_LIST_FAILURE
);

// List student test completion  actions
export const requestStudentTestCompletionList = createAction(
  ReportActions.REQUEST_STUDENTS_TEST_COMPLETION_LIST
);
export const studentTestCompletionListSuccess = createAction(
  ReportActions.STUDENT_TEST_COMPLETION_LIST_SUCCESS
);
export const studentTestCompletionListFailed = createAction(
  ReportActions.STUDENT_TEST_COMPLETION_LIST_FAILURE
);

// District student test completion  actions
export const requestDistrictStudentTestCompletionList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_TEST_COMPLETION_LIST
);
export const districtStudentTestCompletionListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_TEST_COMPLETION_LIST_SUCCESS
);
export const districtStudentTestCompletionListFailed = createAction(
  ReportActions.DISTRICT_STUDENS_TEST_COMPLETION_LIST_FAILURE
);

// linked org actions
export const requestLinkedOrganizationList = createAction(
  ReportActions.REQUEST_LINKED_ORGANIZATION_LIST
);
export const linkedOrganizationListSuccess = createAction(
  ReportActions.LINKED_ORGANIZATION_LIST_SUCCESS
);
export const linkedOrganizationListFailed = createAction(
  ReportActions.LINKED_ORGANIZATION_LIST_FAILURE
);

// List student act score comparison  actions
export const requestStudentScoreComparisonList = createAction(
  ReportActions.REQUEST_STUDENTS_SCORE_COMPARISON_LIST
);
export const studentScoreComparisonListSuccess = createAction(
  ReportActions.STUDENTS_SCORE_COMPARISON_LIST_SUCCESS
);
export const studentScoreComparisonListFailed = createAction(
  ReportActions.STUDENTS_SCORE_COMPARISON_LIST_FAILURE
);

// District student List act score comparison  actions
export const requestDistrictStudentScoreComparisonList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_SCORE_COMPARISON_LIST
);
export const districtStudentScoreComparisonListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_SCORE_COMPARISON_LIST_SUCCESS
);
export const districtStudentScoreComparisonListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_SCORE_COMPARISON_LIST_FAILURE
);

// List student act performance  actions
export const requestStudentActPerformanceList = createAction(
  ReportActions.REQUEST_STUDENTS_ACT_PERFORMANCE_LIST
);
export const studentActPerformanceListSuccess = createAction(
  ReportActions.STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS
);
export const studentActPerformanceListFailed = createAction(
  ReportActions.STUDENTS_ACT_PERFORMANCE_LIST_FAILURE
);

// List district student act performance  actions
export const requestDistrictStudentActPerformanceList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST
);
export const districtStudentActPerformanceListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS
);
export const districtStudentActPerformanceListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST_FAILURE
);

// List student quiz progress  actions
export const requestStudentQuizProgressList = createAction(
  ReportActions.REQUEST_STUDENTS_QUIZ_PROGRESS_LIST
);
export const studentQuizProgressListSuccess = createAction(
  ReportActions.STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS
);
export const studentQuizProgressListFailed = createAction(
  ReportActions.STUDENTS_QUIZ_PROGRESS_LIST_FAILURE
);

// List district student quiz progress  actions
export const requestDistrictStudentQuizProgressList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST
);
export const districtStudentQuizProgressListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS
);
export const districtStudentQuizProgressListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST_FAILURE
);

// List student act detailed actions
export const requestStudentActDetailedList = createAction(
  ReportActions.REQUEST_STUDENTS_ACT_DETAILED_LIST
);
export const studentActDetailedListSuccess = createAction(
  ReportActions.STUDENTS_ACT_DETAILED_LIST_SUCCESS
);
export const studentActDetailedListFailed = createAction(
  ReportActions.STUDENTS_ACT_DETAILED_LIST_FAILURE
);

// List district student act detailed actions
export const requestDistrictStudentActDetailedList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_DETAILED_LIST
);
export const districtStudentActDetailedListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_ACT_DETAILED_LIST_SUCCESS
);
export const districtStudentActDetailedListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_ACT_DETAILED_LIST_FAILURE
);

// List student Course Progress Overview actions
export const requestStudentCourseProgressOverviewList = createAction(
  ReportActions.REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST
);
export const studentCourseProgressOverviewListSuccess = createAction(
  ReportActions.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS
);
export const studentCourseProgressOverviewListFailed = createAction(
  ReportActions.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE
);

// List district Course student Progress Overview actions
export const requestDistrictStudentCourseProgressOverviewList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST
);
export const districtStudentCourseProgressOverviewListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS
);
export const districtStudentCourseProgressOverviewListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE
);

// List student ActScore Progress Overview actions
export const requestStudentActScoreProgressOverviewList = createAction(
  ReportActions.REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST
);
export const studentActScoreProgressOverviewListSuccess = createAction(
  ReportActions.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS
);
export const studentActScoreProgressOverviewListFailed = createAction(
  ReportActions.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE
);

// List district ActScore student Progress Overview actions
export const requestDistrictStudentActScoreProgressOverviewList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST
);
export const districtStudentActScoreProgressOverviewListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS
);
export const districtStudentActScoreProgressOverviewListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE
);

// List student QuizScore Progress Overview actions
export const requestStudentQuizScoreProgressOverviewList = createAction(
  ReportActions.REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST
);
export const studentQuizScoreProgressOverviewListSuccess = createAction(
  ReportActions.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS
);
export const studentQuizScoreProgressOverviewListFailed = createAction(
  ReportActions.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE
);

// List district QuizScore student Progress Overview actions
export const requestDistrictStudentQuizScoreProgressOverviewList = createAction(
  ReportActions.REQUEST_DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST
);
export const districtStudentQuizScoreProgressOverviewListSuccess = createAction(
  ReportActions.DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS
);
export const districtStudentQuizScoreProgressOverviewListFailed = createAction(
  ReportActions.DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE
);
