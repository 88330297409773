/** @format */

import { createLogic } from 'redux-logic';

import {
  CouponActions,
  addCouponFailed,
  addCouponSuccess,
  couponListSuccess,
  couponListFailed,
  updateCouponSuccess,
  updateCouponFailed,
  requestCouponList,
  updateCouponStatusSuccess,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

/**
 *
 */
const addCouponLogic = createLogic({
  type: CouponActions.REQUEST_ADD_COUPON,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_COUPON.service,
      ApiRoutes.ADD_COUPON.url,
      ApiRoutes.ADD_COUPON.method,
      ApiRoutes.ADD_COUPON.authenticate,
      undefined,
      action.payload,
    );
    if (!isError) {
      dispatch(addCouponSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addCouponFailed());
      done();
    }
  },
});

/**
 *
 */
const getCouponsLogic = createLogic({
  type: CouponActions.REQUEST_COUPONS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_COUPONS.service,
      ApiRoutes.GET_COUPONS.url,
      ApiRoutes.GET_COUPONS.method,
      ApiRoutes.GET_COUPONS.authenticate,
      action.payload,
      undefined,
    );
    if (isError) {
      showError(messages[0]);
      dispatch(couponListFailed());
      done();
    }
    dispatch(
      couponListSuccess({
        coupons: responseData.data,
        totalRecords: responseData.totalRecords,
      }),
    );
    done();
  },
});

/**
 *
 */
const updateCouponLogic = createLogic({
  type: CouponActions.UPDATE_COUPON_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_COUPON.service,
      ApiRoutes.UPDATE_COUPON.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_COUPON.method,
      ApiRoutes.UPDATE_COUPON.authenticate,
      undefined,
      action.payload.values,
    );
    if (!isError) {
      dispatch(updateCouponSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateCouponFailed());
      done();
    }
  },
});


/**
 *
 */
const deleteCouponLogic = createLogic({
  type: CouponActions.DELETE_COUPON_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_COUPON.service,
      ApiRoutes.DELETE_COUPON.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_COUPON.method,
      ApiRoutes.DELETE_COUPON.authenticate,
      undefined,
      undefined,
    );
    if (isError) {
      dispatch(couponListFailed(messages[0]));
      done();
    }
    dispatch(requestCouponList());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

/**
 *
 */
const updateCouponStatusLogic = createLogic({
  type: CouponActions.UPDATE_COUPON_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_COUPON_STATUS.service,
      ApiRoutes.UPDATE_COUPON_STATUS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_COUPON_STATUS.method,
      ApiRoutes.UPDATE_COUPON_STATUS.authenticate,
      undefined,
      { status: action.payload.status },
    );
    if (isError) {
      dispatch(couponListFailed(messages[0]));
      done();
    }
    dispatch(
      requestCouponList({
        ...action.payload.query,
      }),
    );
    dispatch(updateCouponStatusSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const CouponLogics = [
  addCouponLogic,
  getCouponsLogic,
  updateCouponLogic,
  deleteCouponLogic,
  updateCouponStatusLogic,
];
