/** @format */

import { handleActions } from 'redux-actions';
import { GlobalSettingActions } from '../actions';
import { GlobalSettingInitialStates } from '../states/GlobalSetting';

export const GlobalSettingReducer = handleActions(
  {
    [GlobalSettingActions.GET_GLOBAL_SETTING_CONTENT]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GlobalSettingActions.GET_GLOBAL_SETTING_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      globalSettingData: action.payload.globalSettingData,
    }),
    [GlobalSettingActions.GET_GLOBAL_SETTING_FAILED]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [GlobalSettingActions.UPDATE_GLOBALSETTING_REQUEST]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
    }),
    [GlobalSettingActions.UPDATE_GLOBALSETTING_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [GlobalSettingActions.UPDATE_GLOBALSETTING_FAILED]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_REQUEST]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
    [GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_FAILED]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  GlobalSettingInitialStates
);
