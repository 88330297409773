export const DashboardInitialStates = {
  isLoadingUser: false,
  isLoadingPaidAccount: false,
  isLoadingSale: false,
  isLoadingSupport: false,
  isLoadingEssay: false,
  isLoadingFreeDemo: false,

  isLoadingDistrict: true,
  DistrictDashboardData: {},
  
  CountData: {},
  usersData: {},
  paidAccountsData: {},
  salesData: {},
  supportsData: {},
  essaysData: {},
  demoUsersData: {},
  currentYearPaidAccountsData: {},
  error: false,
};
