/** @format */

import { createAction } from 'redux-actions';

export const QuizActions = {
  REQUEST_GET_QUIZ_QUESTIONS_BY_ID: 'Request to get quiz questions by id',
  GET_QUIZ_QUESTIONS_BY_ID_SUCCESS: 'Quiz questions fetched successfully.',
  GET_QUIZ_QUESTIONS_BY_ID_FAILURE: 'Failed to fetch quiz questions',

  UPDATE_QUIZ_DATA_REQUEST: 'Request to update quiz  data',
  UPDATE_QUIZ_DATA_SUCCESS: 'Quiz data updated successfully',
  UPDATE_QUIZ_DATA_FAILURE: 'Failed to update quiz data',

  UPDATE_QUIZ_DATA_ORDER_REQUEST: 'Request to update quiz data order',
  UPDATE_QUIZ_DATA_ORDER_SUCCESS: 'Quiz data order updated successfully',
  UPDATE_QUIZ_DATA_ORDER_FAILURE: 'Failed to update quiz data order',

  DELETE_QUIZ_DATA_ORDER_REQUEST: 'Request to delete quiz data order',
  DELETE_QUIZ_DATA_ORDER_SUCCESS: 'Quiz data order deleted successfully',
  DELETE_QUIZ_DATA_ORDER_FAILURE: 'Failed to delete quiz data order',

  REQUEST_ADD_NEW_QUIZZES_PARAGRAPH: 'Request to add new quizzes paragraph',
  ADD_NEW_QUIZZES_PARAGRAPH_SUCCESS: 'Quizzes paragraph added successfully',
  ADD_NEW_QUIZZES_PARAGRAPH_FAILURE: 'Failed to add quizzes paragraph',

  REQUEST_DELETE_QUIZ_QUESTION: 'Request to delete quiz question',
  DELETE_QUIZ_QUESTION_SUCCESS: 'Quiz question deleted successfully',
  DELETE_QUIZ_QUESTION_FAILURE: 'Failed to delete quiz question',

  DELETE_QUESTION_VIDEO_REQUEST: 'DELETE_QUESTION_VIDEO_REQUEST',
  DELETE_QUESTION_VIDEO_SUCCESS: 'DELETE_QUESTION_VIDEO_SUCCESS',
  DELETE_QUESTION_VIDEO_FAILURE: 'DELETE_QUESTION_VIDEO_FAILURE',
};

/*
-------------------------------------
   Get quizzes questions by quiz Id
-------------------------------------
*/
export const requestGetQuizQuestionsById = createAction(
  QuizActions.REQUEST_GET_QUIZ_QUESTIONS_BY_ID
);
export const getQuizQuestionsByIdSuccess = createAction(
  QuizActions.GET_QUIZ_QUESTIONS_BY_ID_SUCCESS
);
export const getQuizQuestionsDataByIdFailed = createAction(
  QuizActions.GET_QUIZ_QUESTIONS_BY_ID_FAILURE
);

/*
-------------------------------------
  Update quizzes data and questions
-------------------------------------
*/
export const updateQuizDataRequest = createAction(
  QuizActions.UPDATE_QUIZ_DATA_REQUEST
);
export const updateQuizDataSuccess = createAction(
  QuizActions.UPDATE_QUIZ_DATA_SUCCESS
);
export const updateQuizDataFailed = createAction(
  QuizActions.UPDATE_QUIZ_DATA_FAILURE
);

export const deleteQuestionVideoRequest = createAction(
  QuizActions.DELETE_QUESTION_VIDEO_REQUEST
);
export const deleteQuestionVideoSuccess = createAction(
  QuizActions.DELETE_QUESTION_VIDEO_SUCCESS
);
export const deleteQuestionVideoFailed = createAction(
  QuizActions.DELETE_QUESTION_VIDEO_FAILURE
);

/*
----------------------------------
  Update quizzes questions order
----------------------------------
*/
export const updateQuizDataOrderRequest = createAction(
  QuizActions.UPDATE_QUIZ_DATA_ORDER_REQUEST
);
export const updateQuizDataOrderSuccess = createAction(
  QuizActions.UPDATE_QUIZ_DATA_ORDER_SUCCESS
);
export const updateQuizDataOrderFailed = createAction(
  QuizActions.UPDATE_QUIZ_DATA_ORDER_FAILURE
);



export const deleteQuizDataOrderRequest = createAction(
  QuizActions.DELETE_QUIZ_DATA_ORDER_REQUEST
);
export const deleteQuizDataOrderSuccess = createAction(
  QuizActions.DELETE_QUIZ_DATA_ORDER_SUCCESS
);
export const deleteQuizDataOrderFailed = createAction(
  QuizActions.DELETE_QUIZ_DATA_ORDER_FAILURE
);

/*
----------------------------
  Add new  quiz paragraph
---------------------------
*/
export const requestAddNewQuizParagraph = createAction(
  QuizActions.REQUEST_ADD_NEW_QUIZZES_PARAGRAPH
);
export const addNewQuizParagraphSuccess = createAction(
  QuizActions.ADD_NEW_QUIZZES_PARAGRAPH_SUCCESS
);
export const addNewQuizParagraphFailed = createAction(
  QuizActions.ADD_NEW_QUIZZES_PARAGRAPH_FAILURE
);

/*
----------------------------
  Delete  quiz questions
---------------------------
*/
export const deleteQuizQuestionRequest = createAction(
  QuizActions.REQUEST_DELETE_QUIZ_QUESTION
);
export const deleteQuizQuestionSuccess = createAction(
  QuizActions.DELETE_QUIZ_QUESTION_SUCCESS
);
export const deleteQuizQuestionFailed = createAction(
  QuizActions.DELETE_QUIZ_QUESTION_FAILURE
);
