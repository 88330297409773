export const TransactionInitialStates = {
  isLoading: true,
  isUserLoading: true,
  isTransactionLoading: true,
  isAdded: false,
  isSubmitting: false,
  isUpdated: false,
  error: false,
  transactions: [],
  transactionData: {},
  totalSales: [],
  totalRecords: 0,
  users: [],
  freeDemoUser: [],
};
