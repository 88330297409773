export const SchoolInitialStates = {
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  schools: [],
  schoolData: {},
  totalRecords: 0,
  subscriptiondata: [],
  taxDetails: [],
};
