import { createLogic } from 'redux-logic';

import {
  listOrgSuccess,
  listOrgFailed,
  listLinkageRequest,
  listLinkageSuccess,
  listLinkageFailed,
  generateLinkageSuccess,
  generateLinkageFailed,
  attemptLinkageSuccess,
  attemptLinkageFailed,
  linkedOrgSuccess,
  linkedOrgFailed,
  LinkageActions,
  unlinkLinkageSuccess,
  unlinkLinkageFailed,
} from '../actions';
import { ApiHelper } from '../../helpers';
import { showSuccess, showError } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';

const getOrgList = createLogic({
  type: LinkageActions.ORGANIZATIONS_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LIST_ORGANIZATIONS.service,
      ApiRoutes.LIST_ORGANIZATIONS.url,
      ApiRoutes.LIST_ORGANIZATIONS.method,
      ApiRoutes.LIST_ORGANIZATIONS.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        listOrgSuccess({
          organizations: responseData.data,
        })
      );
      //showSuccess(messages[0]);
      done();
    } else {
      dispatch(listOrgFailed());
      showError(messages[0]);
      done();
    }
  },
});

const getLinkageList = createLogic({
  type: LinkageActions.LINKAGE_LIST_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LIST_ADMIN_LINKAGE.service,
      ApiRoutes.LIST_ADMIN_LINKAGE.url,
      ApiRoutes.LIST_ADMIN_LINKAGE.method,
      ApiRoutes.LIST_ADMIN_LINKAGE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        listLinkageSuccess({
          requests: responseData.data,
        })
      );
      //showSuccess(messages[0]);
      done();
    } else {
      dispatch(listLinkageFailed());
      showError(messages[0]);
      done();
    }
  },
});

const generateLinkageList = createLogic({
  type: LinkageActions.LINKAGE_GENERATE_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GENERATE_LINKAGE.service,
      ApiRoutes.GENERATE_LINKAGE.url,
      ApiRoutes.GENERATE_LINKAGE.method,
      ApiRoutes.GENERATE_LINKAGE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        listLinkageRequest({
          districtID: action.payload.districtID,
        })
      );
      dispatch(generateLinkageSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      dispatch(generateLinkageFailed());
      showError(messages[0]);
      done();
    }
  },
});

const attemptLinkageList = createLogic({
  type: LinkageActions.LINKAGE_ATTEMPT_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ATTEMPT_REQUEST.service,
      ApiRoutes.ATTEMPT_REQUEST.url.replace(':id', action.payload.id),
      ApiRoutes.ATTEMPT_REQUEST.method,
      ApiRoutes.ATTEMPT_REQUEST.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        listLinkageRequest({
          districtID: action.payload.districtID,
        })
      );
      dispatch(attemptLinkageSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      dispatch(attemptLinkageFailed());
      showError(messages[0]);
      done();
    }
  },
});

const unlinkLinkage = createLogic({
  type: LinkageActions.LINKAGE_UNLINK_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNLINK_LINKAGE.service,
      ApiRoutes.UNLINK_LINKAGE.url.replace(':id', action.payload.id),
      ApiRoutes.UNLINK_LINKAGE.method,
      ApiRoutes.UNLINK_LINKAGE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        listLinkageRequest({
          districtID: action.payload.districtID,
        })
      );
      dispatch(unlinkLinkageSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      dispatch(unlinkLinkageFailed());
      showError(messages[0]);
      done();
    }
  },
});

const getLinkedOrg = createLogic({
  type: LinkageActions.LINKED_ORGANIZATIONS_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LIST_LINKED_ORGANIZATIONS.service,
      ApiRoutes.LIST_LINKED_ORGANIZATIONS.url,
      ApiRoutes.LIST_LINKED_ORGANIZATIONS.method,
      ApiRoutes.LIST_LINKED_ORGANIZATIONS.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        linkedOrgSuccess({
          linkedOrganizations: responseData.data,
        })
      );
      //showSuccess(messages[0]);
      done();
    } else {
      dispatch(linkedOrgFailed());
      showError(messages[0]);
      done();
    }
  },
});

export const LinkageLogics = [
  getOrgList,
  getLinkageList,
  generateLinkageList,
  attemptLinkageList,
  getLinkedOrg,
  unlinkLinkage,
];
