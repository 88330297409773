/** @format */

import StatesData from './../common/state.json';
import CitiesData from './../common/cities.json';

export const getCitiesByCountryId = countryId => {
  // to filters states of particular country
  let states = StatesData.filter(
    state => parseInt(state.countryid) === countryId,
  );
  states = states.map(state => state.id);
  // To filter all the cities belongs to particular country
  const cities = CitiesData.filter(city => states.includes(city.state_id));
  return cities.map(({ name, id }) => ({
    label: name,
    value: parseInt(id),
  }));
};
