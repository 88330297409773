/** @format */

import { handleActions } from 'redux-actions';
import { ProgramSitesActions } from '../actions';
import { ProgramSitesInitialStates } from '../states';

export const ProgramSitesReducer = handleActions(
  {
    [ProgramSitesActions.ADD_PROGRAM_SITES_REQUEST]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [ProgramSitesActions.ADD_PROGRAM_SITES_SUCCESS]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [ProgramSitesActions.ADD_PROGRAM_SITES_FAILED]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: action.payload.isAdded,
      error: true,
    }),
    [ProgramSitesActions.PROGRAM_SITES_LIST_REQUEST]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isListLoading: true,
      programSiteData: {},
    }),
    [ProgramSitesActions.PROGRAM_SITES_LIST_SUCCESS]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isListLoading: false,
      programSites: action.payload.programSites,
      totalRecords: action.payload.totalRecords,
    }),
    [ProgramSitesActions.PROGRAM_SITES_LIST_FAILED]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isListLoading: false,
      error: true,
    }),
    [ProgramSitesActions.UPDATE_PROGRAM_SITES_REQUEST]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [ProgramSitesActions.UPDATE_PROGRAM_SITES_SUCCESS]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [ProgramSitesActions.UPDATE_PROGRAM_SITES_FAILED]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [ProgramSitesActions.VIEW_PROGRAM_SITES_REQUEST]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramSitesActions.VIEW_PROGRAM_SITES_SUCCESS]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programSiteData: action.payload.programSiteData,
      subscriptiondata: action.payload.subscriptiondata,
      taxDetails: action.payload.taxDetails,
    }),
    [ProgramSitesActions.VIEW_PROGRAM_SITES_FAILED]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [ProgramSitesActions.PROGRAM_SITES_OPTION_LIST_REQUEST]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramSitesActions.PROGRAM_SITES_OPTION_LIST_SUCCESS]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      programSiteOptions: action.payload.programSiteOptions,
    }),
    [ProgramSitesActions.PROGRAM_SITES_OPTION_LIST_FAILED]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [ProgramSitesActions.UPDATE_PROGRAM_SITES_STATUS_REQUEST]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [ProgramSitesActions.UPDATE_PROGRAM_SITES_STATUS_SUCCESS]: (
      state = ProgramSitesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
  },
  ProgramSitesInitialStates
);
