/** @format */

import { createAction } from 'redux-actions';

export const HomePageActions = {
  REQUEST_ADD_TESTIMONIAL: 'Request to add Testimonial',
  ADD_TESTIMONIAL_SUCCESS: 'Testimonial added successfully',
  ADD_TESTIMONIAL_FAILURE: 'Failed to add Testimonial',

  // update Testimonial Record
  UPDATE_TESTIMONIAL_REQUEST: 'Update Testimonial Record Request!',
  UPDATE_TESTIMONIAL_SUCCESS: 'Update Testimonial Record successfully!.',
  UPDATE_TESTIMONIAL_FAILED: 'Update Testimonial Record failed!',

  // update Banner Record
  UPDATE_BANNER_REQUEST: 'Update Banner Record Request!',
  UPDATE_BANNER_SUCCESS: 'Update Banner Record successfully!.',
  UPDATE_BANNER_FAILED: 'Update Banner Record failed!',

  // Testimonial listing
  HOMEPAGE_LIST_REQUEST: 'Home Page Listing Request!',
  HOMEPAGE_LIST_SUCCESS: 'Home Page Listed successfully!.',
  HOMEPAGE_LIST_FAILURE: 'Home Page Listing failed!',

  //Delete Testimonial Record
  DELETE_TESTIMONIAL_REQUEST: 'Delete Testimonial Record Request!',
  DELETE_TESTIMONIAL_SUCCESS: 'Delete Testimonial Record successfully!.',
  DELETE_TESTIMONIAL_FAILED: 'Delete Testimonial Record failed!',

  UPDATE_VIDEO_SECTION_REQUEST: 'UPDATE_VIDEO_SECTION_REQUEST',
  UPDATE_VIDEO_SECTION_SUCCESS: 'UPDATE_VIDEO_SECTION_SUCCESS',
  UPDATE_VIDEO_SECTION_FAILED: 'UPDATE_VIDEO_SECTION_FAILED',
};

// Add Testimonial actions
export const requestAddTestimonial = createAction(
  HomePageActions.REQUEST_ADD_TESTIMONIAL
);
export const addTestimonialSuccess = createAction(
  HomePageActions.ADD_TESTIMONIAL_SUCCESS
);
export const addTestimonialFailed = createAction(
  HomePageActions.ADD_TESTIMONIAL_FAILURE
);

export const updateTestimonialRequest = createAction(
  HomePageActions.UPDATE_TESTIMONIAL_REQUEST
);
export const updateTestimonialSuccess = createAction(
  HomePageActions.UPDATE_TESTIMONIAL_SUCCESS
);
export const updateTestimonialFailed = createAction(
  HomePageActions.UPDATE_TESTIMONIAL_FAILED
);

export const deleteTestimonialRequest = createAction(
  HomePageActions.DELETE_TESTIMONIAL_REQUEST
);
export const deleteTestimonialSuccess = createAction(
  HomePageActions.DELETE_TESTIMONIAL_SUCCESS
);
export const deleteTestimonialFailed = createAction(
  HomePageActions.DELETE_TESTIMONIAL_FAILED
);
export const updateBannerRequest = createAction(
  HomePageActions.UPDATE_BANNER_REQUEST
);

export const updateBannerSuccess = createAction(
  HomePageActions.UPDATE_BANNER_SUCCESS
);
export const updateBannerFailed = createAction(
  HomePageActions.UPDATE_BANNER_FAILED
);

export const updateVideoSectionRequest = createAction(
  HomePageActions.UPDATE_VIDEO_SECTION_REQUEST
);

export const updateVideoSectionSuccess = createAction(
  HomePageActions.UPDATE_VIDEO_SECTION_SUCCESS
);

export const updateVideoSectionFailed = createAction(
  HomePageActions.UPDATE_VIDEO_SECTION_FAILED
);

export const requestHomePageList = createAction(
  HomePageActions.HOMEPAGE_LIST_REQUEST
);
export const homePageListSuccess = createAction(
  HomePageActions.HOMEPAGE_LIST_SUCCESS
);
export const homePageListFailed = createAction(
  HomePageActions.HOMEPAGE_LIST_FAILURE
);
