/** @format */

export const AppRoutes = {
  MAIN: '/',
  LOGIN: '/login',
  NOT_AVAILABLE: '/404',
  RESET_PASSWORD: '/reset-password',

  HOME: '/dashboard',
  MY_PROFILE: '/profile',
  USERS: '/users',
  STUDENT: '/student',
  VIEW_STUDENT: '/student/view/:id',
  VIEW_DISTRICT: '/district/view/:id',
  DISTRICT: '/district',
  DISTRICT_ADMIN: '/district-admin',
  VIEW_DISTRICT_ADMIN: '/district-admin/view/:id',
  PROGRAM_SA: '/programs-admin',
  VIEW_PROGRAM_SA: '/programs-admin/view/:id',
  PROGRAM_SITE: '/program-site',
  VIEW_PROGRAM_SITE: '/program-site/view/:id',
  PROGRAM: '/programs',
  VIEW_PROGRAM: '/programs/view/:id',
  EMPLOYEE: '/employee',
  VIEW_EMPLOYEE: '/employee/view',
  SUBSCRIPTION: '/subscription',
  UPDATE_SUBSCRIPTION: '/subscription/update/:id',
  COUPONS: '/coupons',
  TRANSACTION: '/transaction',
  ORDER_HISTORY: '/order-history',
  HOME_PAGES: '/homepage',
  PARTNERSHIP_PAGE: '/partnership',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_CONDITION: '/terms-condition',
  SETTINGS: '/settings',
  SECTIONS: '/sections',
  COURSES: '/courses',
  ADD_LESSONS: '/courses/lessons/add',
  EDIT_LESSONS: '/courses/lessons/edit/:id',
  QUESTION_BANK: '/question-bank',
  MULTIPLE_CHOICE: '/multiple-choice',
  ADD_MULTIPLE_CHOICE: '/multiple-choice/add',
  DESCRIPTIVE_ANSWER: '/descriptive-answer',
  ADD_DESCRIPTIVE_ANSWER: '/descriptive-answer/add',
  TEST: '/test',
  ADD_TEST: '/test/view/:testSlug/:testId/:testSectionId/:type',
  CERTIFICATE: '/certificate-badges',
  ESSAY: '/essay',
  VIEW_ESSAY: '/essay/view/:id',
  LOGINS: '/inner-login',
  SCHOOL: '/school',
  SCHOOL_SA: '/school-admin',
  VIEW_SCHOOL_SA: '/school-admin/view',
  VIEW_SCHOOL: '/school/view/:id',
  SUPPORT_REQUEST: '/support-request',
  REPORT: '/reports',
  VIEW_REPORT: '/reports/view',
  VIEW_ACT_DETAILED_REPORT: '/reports/view/act-detailed-report',
  VIEW_ACT_PERFORMANCE_REPORT: '/reports/view/act-performance-report',
  VIEW_ACT_SCORE_COMPARISON_REPORT: '/reports/view/act-score-comparison-report',
  VIEW_STUDENT_PROGRESS_REPORT: '/reports/view/student-progress-report',
  VIEW_QUIZ_PROGRESS_REPORT: '/reports/view/quiz-progress-report',
  STUDENT_LOGIN_REPORT: '/reports/view/login-history',
  STUDENT_TEST_COMPLETION_REPORT:
    '/reports/view/student-test-completion-report',
  FLAGGED_QUESTION: '/flagged-question',
  LINKAGE: '/linkage',
  LINKED_SCHOOL: '/linked-school',
  VIEW_LINKED_SCHOOL: '/linked-school/view/:id',
  LINKED_PROGRAM_SITE: '/linked-program-site',
  VIEW_LINKED_PROGRAM_SITE: '/linked-program-site/view/:id',
  FORGOT_PASSWORD: '/forgot-password',
  CONTACT_REQUEST: '/contact-request',
  FREE_DEMO_REQUEST: '/free-demo-request',
};
