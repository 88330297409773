/** @format */

export const UnitInitialStates = {
  isLoading: false,
  error: false,
  units: [],
  unitOptions: [],
  isLessonOrderUpdated: false,
  isSectionOrderUpdated: false,
  isSectionLoadByUnit: false,
};
