/** @format */

import { createAction } from 'redux-actions';

export * from './Auth';
export * from './District';
export * from './DistrictAdmin';
export * from './GeoGraphics';
export * from './HomePage';
export * from './Lesson';
export * from './Modal';
export * from './Program';
export * from './ProgramAdmin';
export * from './ProgramSites';
export * from './School';
export * from './Section';
export * from './Student';
export * from './PrivacyPolicy';
export * from './TermsCondition';
export * from './Test';
export * from './Course';
export * from './Quiz';
export * from './GlobalSetting';
export * from './Coupon';
export * from './BadgeAndCertificate';
export * from './Support';
export * from './SubscriptionPlan'
export * from './Transaction';
export * from './Report';
export * from './Notification';
export * from './Essay';
export * from './Dashboard';
export * from './LinkageRequest';
export * from './Contact';
export * from './FreeDemo';

export const redirectTo = createAction('REDIRECT_TO');
