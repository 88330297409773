/** @format */

import { createAction } from 'redux-actions';

export const GlobalSettingActions = {
  // get Global Settings Record
  GET_GLOBAL_SETTING_CONTENT: 'Get Global Settings Record Request!',
  GET_GLOBAL_SETTING_SUCCESS: 'Get Global Settings Record successfully!.',
  GET_GLOBAL_SETTING_FAILED: 'Get Global Settings Record failed!',

  // update Global Settings Record
  UPDATE_GLOBALSETTING_REQUEST: 'Update Global Settings Record Request!',
  UPDATE_GLOBALSETTING_SUCCESS: 'Update Global Settings Record successfully!.',
  UPDATE_GLOBALSETTING_FAILED: 'Update Global Settings Record failed!',

  // update Global Settings social Status
  UPDATE_GLOBALSETTING_STATUS_REQUEST: 'Update Social Status Request!',
  UPDATE_GLOBALSETTING_STATUS_SUCCESS: 'Update Social Status successfully!.',
  UPDATE_GLOBALSETTING_STATUS_FAILED: 'Update Social Status failed!',
};

// Get Global Settings actions
export const getGlobalSettingRequest = createAction(
  GlobalSettingActions.GET_GLOBAL_SETTING_CONTENT
);
export const getGlobalSettingSuccess = createAction(
  GlobalSettingActions.GET_GLOBAL_SETTING_SUCCESS
);
export const getGlobalSettingFailed = createAction(
  GlobalSettingActions.GET_GLOBAL_SETTING_FAILED
);

// Update Global Settings actions
export const updateGlobalSettingRequest = createAction(
  GlobalSettingActions.UPDATE_GLOBALSETTING_REQUEST
);
export const updateGlobalSettingSuccess = createAction(
  GlobalSettingActions.UPDATE_GLOBALSETTING_SUCCESS
);
export const updateGlobalSettingFailed = createAction(
  GlobalSettingActions.UPDATE_GLOBALSETTING_FAILED
);

// update Global Settings social Status
export const updateGlobalSettingStatusRequest = createAction(
  GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_REQUEST
);
export const updateGlobalSettingStatusSuccess = createAction(
  GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_SUCCESS
);
export const updateGlobalSettingStatusFailed = createAction(
  GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_FAILED
);
