/** @format */

import { createAction } from 'redux-actions';

export const SupportActions = {
  REQUEST_SUPPORTS_LIST: 'Request to fetch supports',
  SUPPORT_LIST_SUCCESS: 'Supports fetched successfully',
  SUPPORT_LIST_FAILURE: 'Failed to fetched supports',
  VIEW_SUPPORT_REQUEST: 'Request to view support',
  VIEW_SUPPORT_SUCCESS: 'Support details fetched successfully',
  VIEW_SUPPORT_FAILURE: 'Failed to fetch support details',
  SUPPORT_STATUS_UPDATE_REQUEST: 'Support Status Update Request!',
  SUPPORT_STATUS_UPDATE_SUCCESS: 'Support Status Update Successfully!',
  SUPPORT_STATUS_UPDATE_FAILED: 'Support Status Update Failed!',
  SUPPORT_ADD_REQUEST: 'Support Add Request!',
};

// List support actions
export const requestSupportList = createAction(
  SupportActions.REQUEST_SUPPORTS_LIST
);
export const successSupportList = createAction(
  SupportActions.SUPPORT_LIST_SUCCESS
);
export const failedSupportList = createAction(
  SupportActions.SUPPORT_LIST_FAILURE
);

// View support actions
export const requestSupportView = createAction(
  SupportActions.VIEW_SUPPORT_REQUEST
);
export const successSupportView = createAction(
  SupportActions.VIEW_SUPPORT_SUCCESS
);
export const failedSupportView = createAction(
  SupportActions.VIEW_SUPPORT_FAILURE
);

// Update status support actions
export const requestSupportStatusUpdate = createAction(
  SupportActions.SUPPORT_STATUS_UPDATE_REQUEST
);
export const successSupportStatusUpdate = createAction(
  SupportActions.SUPPORT_STATUS_UPDATE_SUCCESS
);
export const failedSupportStatusUpdate = createAction(
  SupportActions.SUPPORT_STATUS_UPDATE_FAILED
);

export const requestSupportAdd = createAction(
  SupportActions.SUPPORT_ADD_REQUEST
);