/** @format */

export const ModalInitialStates = {
  studentModalOpen: false,
  programModalOpen: false,
  programAdminModalOpen: false,
  programSitesModalOpen: false,
  programSitesChangePasswordModalOpen: false,
  testimonialModalOpen: false,
  sectionModalOpen: false,
  couponModalOpen: false,
  supportViewModalOpen: false,
  contactViewModalOpen: false,
  freeDemoViewModalOpen: false,
  transactionModalOpen: false,
  subscriptionModalOpen: false
};
