import { handleActions } from 'redux-actions';
import { NotificationActions } from '../actions';
import { NotificationStates } from '../states/Notification';

export const NotificationReducer = handleActions(
  {
    [NotificationActions.REQUEST_NOTIFICATIONS_LIST]: (
      state = NotificationStates,
      action
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [NotificationActions.NOTIFICATION_LIST_SUCCESS]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      notifications: action.payload.notifications,
      totalRecords: action.payload.totalRecords,
      unReadRecords: action.payload.unReadRecords,
    }),
    [NotificationActions.NOTIFICATION_LIST_FAILURE]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [NotificationActions.UPDATE_NOTIFICATION_STATUS_REQUEST]: (
      state = NotificationStates,
      action
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [NotificationActions.UPDATE_NOTIFICATION_STATUS_SUCCESS]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      unReadRecords: action.payload.unReadRecords,
    }),
    [NotificationActions.UPDATE_NOTIFICATION_STATUS_FAILURE]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [NotificationActions.UPDATE_NOTIFICATION_COUNT_REQUEST]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [NotificationActions.UPDATE_NOTIFICATION_COUNT_SUCCESS]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
    [NotificationActions.UPDATE_NOTIFICATION_COUNT_FAILURE]: (
      state = NotificationStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },

  NotificationStates
);
