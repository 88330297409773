/** @format */

import { createAction } from 'redux-actions';

export const DashboardActions = {
  GET_USERS_REQUEST: 'Get Users Record Request!',
  GET_USERS_SUCCESS: 'Get Users Record successfully!.',
  GET_USERS_FAILED: 'Get Users Record failed!',

  GET_PAID_ACCOUNTS_REQUEST: 'Get Paid accounts Record Request!',
  GET_PAID_ACCOUNTS_SUCCESS: 'Get Paid accounts Record successfully!.',
  GET_PAID_ACCOUNTS_FAILED: 'Get Paid accounts Record failed!',

  GET_SALES_REQUEST: 'Get Sales Record Request!',
  GET_SALES_SUCCESS: 'Get Sales Record successfully!.',
  GET_SALES_FAILED: 'Get USale Record failed!',

  GET_SUPPORTS_REQUEST: 'Get Supports Record Request!',
  GET_SUPPORTS_SUCCESS: 'Get Supports Record successfully!.',
  GET_SUPPORTS_FAILED: 'Get Supports Record failed!',

  GET_ESSAYS_REQUEST: 'Get Essays Record Request!',
  GET_ESSAYS_SUCCESS: 'Get Essays Record successfully!.',
  GET_ESSAYS_FAILED: 'Get Essays Record failed!',

  GET_DEMO_USERS_REQUEST: 'Get Free Demo Record Request!',
  GET_DEMO_USERS_SUCCESS: 'Get Free Demo Record successfully!.',
  GET_DEMO_USERS_FAILED: 'Get Free Demo Record failed!',

  GET_THIS_YEAR_PAID_ACCOUNTS_REQUEST:
    'Get this year Paid accounts Record Request!',
  GET_THIS_YEAR_PAID_ACCOUNTS_SUCCESS:
    'Get this year Paid accounts Record successfully!.',
  GET_THIS_YEAR_PAID_ACCOUNTS_FAILED:
    'Get this year Paid accounts Record failed!',

  
  GET_DISTRICT_DASHBOARD_REQUEST:
    'Get District Dashboard Record Request!',
  GET_DISTRICT_DASHBOARD_SUCCESS:
    'Get District Dashboard Record successfully!.',
  GET_DISTRICT_DASHBOARD_FAILED: 'Get District Dashboard Record failed!',
};

// Get Terms Condition actions
export const getUsersRequest = createAction(DashboardActions.GET_USERS_REQUEST);
export const getUsersSuccess = createAction(DashboardActions.GET_USERS_SUCCESS);
export const getUsersFailed = createAction(DashboardActions.GET_USERS_FAILED);

export const getPaidAccountsRequest = createAction(
  DashboardActions.GET_PAID_ACCOUNTS_REQUEST
);
export const getPaidAccountsSuccess = createAction(
  DashboardActions.GET_PAID_ACCOUNTS_SUCCESS
);
export const getPaidAccountsFailed = createAction(
  DashboardActions.GET_PAID_ACCOUNTS_FAILED
);

export const getSalesRequest = createAction(DashboardActions.GET_SALES_REQUEST);
export const getSalesSuccess = createAction(DashboardActions.GET_SALES_SUCCESS);
export const getSalesFailed = createAction(DashboardActions.GET_SALES_FAILED);

export const getSupportsRequest = createAction(
  DashboardActions.GET_SUPPORTS_REQUEST
);
export const getSupportsSuccess = createAction(
  DashboardActions.GET_SUPPORTS_SUCCESS
);
export const getSupportsFailed = createAction(
  DashboardActions.GET_SUPPORTS_FAILED
);

export const getEssaysRequest = createAction(
  DashboardActions.GET_ESSAYS_REQUEST
);
export const getEssaysSuccess = createAction(
  DashboardActions.GET_ESSAYS_SUCCESS
);
export const getEssaysFailed = createAction(DashboardActions.GET_ESSAYS_FAILED);

export const getDemoUsersRequest = createAction(
  DashboardActions.GET_DEMO_USERS_REQUEST
);
export const getDemoUsersSuccess = createAction(
  DashboardActions.GET_DEMO_USERS_SUCCESS
);
export const getDemoUsersFailed = createAction(
  DashboardActions.GET_DEMO_USERS_FAILED
);

export const getThisYearPaidAccountsRequest = createAction(
  DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_REQUEST
);
export const getThisYearPaidAccountsSuccess = createAction(
  DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_SUCCESS
);
export const getThisYearPaidAccountsFailed = createAction(
  DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_FAILED
);

// district dashboard


export const getDistrictDashboardRequest = createAction(
  DashboardActions.GET_DISTRICT_DASHBOARD_REQUEST
);
export const  getDistrictDashboardSuccess = createAction(
  DashboardActions.GET_DISTRICT_DASHBOARD_SUCCESS
);
export const  getDistrictDashboardFailed = createAction(
  DashboardActions.GET_DISTRICT_DASHBOARD_FAILED
);
