/** @format */

import { handleActions } from 'redux-actions';
import { ProgramAdminActions } from '../actions';
import { ProgramAdminInitialStates } from '../states';

export const ProgramAdminReducer = handleActions(
  {
    [ProgramAdminActions.ADD_PROGRAM_ADMIN_REQUEST]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [ProgramAdminActions.ADD_PROGRAM_ADMIN_SUCCESS]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [ProgramAdminActions.ADD_PROGRAM_ADMIN_FAILED]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [ProgramAdminActions.PROGRAM_ADMIN_LIST_REQUEST]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramAdminActions.PROGRAM_ADMIN_LIST_SUCCESS]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      programAdmins: action.payload.programAdmins,
      totalRecords: action.payload.totalRecords,
    }),
    [ProgramAdminActions.PROGRAM_ADMIN_LIST_FAILED]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [ProgramAdminActions.UPDATE_PROGRAM_ADMIN_REQUEST]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [ProgramAdminActions.UPDATE_PROGRAM_ADMIN_SUCCESS]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [ProgramAdminActions.UPDATE_PROGRAM_ADMIN_FAILED]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [ProgramAdminActions.VIEW_PROGRAM_ADMIN_REQUEST]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ProgramAdminActions.VIEW_PROGRAM_ADMIN_SUCCESS]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      programAdminData: action.payload.programAdminData,
    }),
    [ProgramAdminActions.VIEW_PROGRAM_ADMIN_FAILED]: (
      state = ProgramAdminInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  ProgramAdminInitialStates,
);
