/** @format */

import { handleActions } from 'redux-actions';
import { UnitActions } from '../actions';
import { UnitInitialStates } from '../states';

export const UnitReducer = handleActions(
  {
    [UnitActions.REQUEST_UNIT_LIST]: (state = UnitInitialStates, action) => ({
      ...state,
      isLoading: true,
      isUnitLoading: false,
    }),
    [UnitActions.UNIT_LIST_SUCCESS]: (state = UnitInitialStates, action) => ({
      ...state,
      isLoading: false,
      units: action.payload.units,
      isUnitLoading: true,
    }),
    [UnitActions.UNIT_LIST_FAILURE]: (state = UnitInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
      isUnitLoading: false,
    }),
    [UnitActions.UNIT_OPTION_LIST_SUCCESS]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      unitOptions: action.payload.unitOptions,
    }),

    [UnitActions.UPDATE_UNIT_DATA_ORDER_REQUEST]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      isLessonOrderUpdated: false,
      isSectionOrderUpdated: false,
    }),
    [UnitActions.UPDATE_UNIT_DATA_ORDER_SUCCESS]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      isLessonOrderUpdated: action.payload.isLessonOrderUpdated,
      isSectionOrderUpdated: action.payload.isSectionOrderUpdated,
    }),
    [UnitActions.UPDATE_UNIT_DATA_ORDER_FAILURE]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      error: true,
      isLessonOrderUpdated: false,
      isSectionOrderUpdated: false,
    }),

    [UnitActions.REQUEST_SECTION_LIST_BY_UNIT]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      isSectionLoadByUnit: false,
    }),
    [UnitActions.SECTION_LIST_SUCCESS]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      isSectionLoadByUnit: true,
    }),
    [UnitActions.SECTION_LIST_FAILURE]: (
      state = UnitInitialStates,
      action
    ) => ({
      ...state,
      isSectionLoadByUnit: false,
    }),
  },
  UnitInitialStates
);
