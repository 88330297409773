/** @format */

import { createLogic } from 'redux-logic';
import {
  BadgesAndCertificatesActions,
  badgesAndCertificatesListSuccess,
  badgesAndCertificatesListFailed,
} from '../actions';
import { showError } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

const getBadgesAndCertificatesLogic = createLogic({
  type: BadgesAndCertificatesActions.REQUEST_BADGES_AND_CERTIFICATES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_BADGES_CERTIFICATES.service,
      ApiRoutes.GET_BADGES_CERTIFICATES.url,
      ApiRoutes.GET_BADGES_CERTIFICATES.method,
      ApiRoutes.GET_BADGES_CERTIFICATES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(badgesAndCertificatesListFailed());
      done();
    }
    dispatch(
      badgesAndCertificatesListSuccess({
        badges: responseData.badgeData,
        certificates: responseData.certificateData,
      })
    );
    done();
  },
});

export const BadgesAndCertificatesLogics = [getBadgesAndCertificatesLogic];
