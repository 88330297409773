/** @format */

import { createAction } from 'redux-actions';

export const SchoolActions = {
  /*
---------------------
      School   
---------------------
*/
  // Add new School
  ADD_SCHOOL_REQUEST: 'Add School Request!',
  ADD_SCHOOL_SUCCESS: 'School Added successfully!.',
  ADD_SCHOOL_FAILED: 'Add School failed!',
  // School listing
  SCHOOL_LIST_REQUEST: 'School Listing Request!',
  SCHOOL_LIST_SUCCESS: 'School Listed successfully!.',
  SCHOOL_LIST_FAILED: 'School Listing failed!',
  // update School Record
  UPDATE_SCHOOL_REQUEST: 'Update School Record Request!',
  UPDATE_SCHOOL_SUCCESS: 'Update School Record successfully!.',
  UPDATE_SCHOOL_FAILED: 'Update School Record failed!',

  // get School Record
  VIEW_SCHOOL_REQUEST: 'View School Record Request!',
  VIEW_SCHOOL_SUCCESS: 'View School Record successfully!.',
  VIEW_SCHOOL_FAILED: 'View School Record failed!',

  DELETE_SCHOOL_REQUEST: 'Request to delete school',
  UPDATE_SCHOOL_STATUS_REQUEST: 'Request to update school sites status',

  UPDATE_SCHOOL_STATUS_SUCCESS: 'School sites status updated successfully',
};

/*
---------------------
      School   
---------------------
*/

// Add new School
export const requestAddSchool = createAction(SchoolActions.ADD_SCHOOL_REQUEST);
export const schoolAddSuccess = createAction(SchoolActions.ADD_SCHOOL_SUCCESS);
export const schoolAddFailed = createAction(SchoolActions.ADD_SCHOOL_FAILED);

export const requestSchoolList = createAction(
  SchoolActions.SCHOOL_LIST_REQUEST
);
export const schoolListSuccess = createAction(
  SchoolActions.SCHOOL_LIST_SUCCESS
);
export const schoolListFailed = createAction(SchoolActions.SCHOOL_LIST_FAILED);

export const updateSchoolRequest = createAction(
  SchoolActions.UPDATE_SCHOOL_REQUEST
);
export const updateSchoolSuccess = createAction(
  SchoolActions.UPDATE_SCHOOL_SUCCESS
);
export const updateSchoolFailed = createAction(
  SchoolActions.UPDATE_SCHOOL_FAILED
);

export const viewSchoolRequest = createAction(
  SchoolActions.VIEW_SCHOOL_REQUEST
);
export const viewSchoolSuccess = createAction(
  SchoolActions.VIEW_SCHOOL_SUCCESS
);
export const viewSchoolFailed = createAction(SchoolActions.VIEW_SCHOOL_FAILED);

export const deleteSchoolRequest = createAction(
  SchoolActions.DELETE_SCHOOL_REQUEST
);

export const updateSchoolStatusRequest = createAction(
  SchoolActions.UPDATE_SCHOOL_STATUS_REQUEST
);

export const updateSchoolStatusSuccess = createAction(
  SchoolActions.UPDATE_SCHOOL_STATUS_SUCCESS
);
