/** @format */

import { handleActions } from 'redux-actions';
import { StudentActions } from '../actions';
import { StudentInitialStates } from '../states/Student';

export const StudentReducer = handleActions(
  {
    [StudentActions.REQUEST_ADD_STUDENT]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [StudentActions.ADD_STUDENT_SUCCESS]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [StudentActions.ADD_STUDENT_FAILURE]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [StudentActions.REQUEST_STUDENTS_LIST]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [StudentActions.STUDENT_LIST_SUCCESS]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      users: action.payload.users,
      totalRecords: action.payload.totalRecords,
    }),
    [StudentActions.STUDENT_LIST_FAILURE]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [StudentActions.UPDATE_STUDENT_REQUEST]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [StudentActions.UPDATE_STUDENT_SUCCESS]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [StudentActions.UPDATE_STUDENT_FAILURE]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),
    [StudentActions.VIEW_STUDENT_REQUEST]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [StudentActions.VIEW_STUDENT_SUCCESS]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      studentData: action.payload.studentData,
      subscriptiondata: action.payload.subscriptiondata,
    }),
    [StudentActions.VIEW_STUDENT_FAILURE]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [StudentActions.UPDATE_STUDENT_STATUS_REQUEST]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [StudentActions.UPDATE_STUDENT_STATUS_SUCCESS]: (
      state = StudentInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
  },
  StudentInitialStates
);
