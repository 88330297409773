/** @format */

import { createLogic } from 'redux-logic';
import {
  StudentActions,
  addStudentFailed,
  addStudentSuccess,
  studentListSuccess,
  studentListFailed,
  updateStudentSuccess,
  updateStudentFailed,
  viewStudentSuccess,
  viewStudentFailed,
  requestStudentList,
  updateStudentStatusSuccess,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

/**
 *
 */
const addStudentLogic = createLogic({
  type: StudentActions.REQUEST_ADD_STUDENT,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_STUDENT.service,
      ApiRoutes.ADD_STUDENT.url,
      ApiRoutes.ADD_STUDENT.method,
      ApiRoutes.ADD_STUDENT.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addStudentSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addStudentFailed());
      done();
    }
  },
});

/**
 *
 */
const getStudentsLogic = createLogic({
  type: StudentActions.REQUEST_STUDENTS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENTS.service,
      ApiRoutes.GET_STUDENTS.url,
      ApiRoutes.GET_STUDENTS.method,
      ApiRoutes.GET_STUDENTS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
       if (action.payload.search !== '') {showError("Invalid search");}
       else{  showError(messages[0]);}
      dispatch(studentListFailed());
      done();
    }

    dispatch(
      studentListSuccess({
        users: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */
const updateStudentLogic = createLogic({
  type: StudentActions.UPDATE_STUDENT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STUDENT.service,
      ApiRoutes.UPDATE_STUDENT.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_STUDENT.method,
      ApiRoutes.UPDATE_STUDENT.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateStudentSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateStudentFailed());
      done();
    }
  },
});

/**
 *
 */
const viewStudentLogic = createLogic({
  type: StudentActions.VIEW_STUDENT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_STUDENT.service,
      ApiRoutes.VIEW_STUDENT.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_STUDENT.method,
      ApiRoutes.VIEW_STUDENT.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(viewStudentFailed(messages[0]));
      done();
    }
    dispatch(
      viewStudentSuccess({
        studentData: responseData.data,
        subscriptiondata: responseData.subscriptionDetails,
      })
    );
    done();
  },
});

/**
 *
 */
const deleteStudentLogic = createLogic({
  type: StudentActions.DELETE_STUDENT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_STUDENT.service,
      ApiRoutes.DELETE_STUDENT.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_STUDENT.method,
      ApiRoutes.DELETE_STUDENT.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(viewStudentFailed(messages[0]));
      done();
    }
    dispatch(requestStudentList());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

/**
 *
 */
const updateStudentStatusLogic = createLogic({
  type: StudentActions.UPDATE_STUDENT_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STUDENT_STATUS.service,
      ApiRoutes.UPDATE_STUDENT_STATUS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_STUDENT_STATUS.method,
      ApiRoutes.UPDATE_STUDENT_STATUS.authenticate,
      undefined,
      { status: action.payload.status }
    );
    if (isError) {
      dispatch(viewStudentFailed(messages[0]));
      done();
    }
    dispatch(
      requestStudentList({
        ...action.payload.query,
      })
    );
    dispatch(updateStudentStatusSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const StudentLogics = [
  addStudentLogic,
  getStudentsLogic,
  updateStudentLogic,
  viewStudentLogic,
  deleteStudentLogic,
  updateStudentStatusLogic,
];
