/** @format */
export const ReportInitialStates = {
  isLoading: true,
  isTestCompletionLoading: true,
  isLoginHistoryLoading: true,
  isCourseOverviewLoading: true,
  isActScoreOverviewLoading: true,
  isQuizScoreOverviewLoading: true,
  isScoreComparisonLoading: true,
  isQuizProgressLoading: true,
  isActDetailLoading: true,
  isActPerformanceLoading: true,
  isDistrictLoading: true,
  error: false,
  UserList: [],
  studentTestReportList: [],
  districtstudentTestReportList: [],
  studentScoreComparisonReportList: [],
  studentReportData: [],
  actDetailReportData: [],
  studentCourseReportData: [],
  studentQuizScoreReportData: [],
  studentActScoreReportData: [],
  totalStudent: 0,
  testStudent: 0,
  totalRecords: 0,
  studentList: [],
  schoolList: [],
  programsiteList: [],
  districtList: [],
  programList: [],
  linkedOrganizationList: [],
  studentTestData: [],
};
