/** @format */

import { createAction } from 'redux-actions';

export const GeoGraphicsActions = {
  REQUEST_COUNTRIES_LIST: 'Request to fetch countries',
  COUNTRIES_LIST_SUCCESS: 'countries fetched successfully',
  COUNTRIES_LIST_FAILURE: 'Failed to fetched countries',
  REQUEST_STATES_LIST: 'Request to fetch states',
  STATES_LIST_SUCCESS: 'states fetched successfully',
  STATES_LIST_FAILURE: 'Failed to fetched states',
  REQUEST_COUNTIES_LIST: 'Request to fetch counties',
  COUNTIES_LIST_SUCCESS: 'counties fetched successfully',
  COUNTIES_LIST_FAILURE: 'Failed to fetched counties',
  REQUEST_CITIES_LIST: 'Request to fetch cities',
  CITIES_LIST_SUCCESS: 'cities fetched successfully',
  CITIES_LIST_FAILURE: 'Failed to fetched cities',
};

export const requestCountriesList = createAction(
  GeoGraphicsActions.REQUEST_COUNTRIES_LIST,
);
export const countriesListSuccess = createAction(
  GeoGraphicsActions.COUNTRIES_LIST_SUCCESS,
);
export const countriesListFailed = createAction(
  GeoGraphicsActions.COUNTRIES_LIST_FAILURE,
);

export const requestStatesList = createAction(
  GeoGraphicsActions.REQUEST_STATES_LIST,
);
export const statesListSuccess = createAction(
  GeoGraphicsActions.STATES_LIST_SUCCESS,
);
export const statesListFailed = createAction(
  GeoGraphicsActions.STATES_LIST_FAILURE,
);

export const requestCounitesList = createAction(
  GeoGraphicsActions.REQUEST_COUNTIES_LIST,
);
export const counitesListSuccess = createAction(
  GeoGraphicsActions.COUNTIES_LIST_SUCCESS,
);
export const counitesListFailed = createAction(
  GeoGraphicsActions.COUNTIES_LIST_FAILURE,
);

export const requestcitiesList = createAction(
  GeoGraphicsActions.REQUEST_CITIES_LIST,
);
export const citiesListSuccess = createAction(
  GeoGraphicsActions.CITIES_LIST_SUCCESS,
);
export const citiesListFailed = createAction(
  GeoGraphicsActions.CITIES_LIST_FAILURE,
);