/** @format */

import { createLogic } from 'redux-logic';
import {
  FreeDemoActions,
  successFreeDemoList,
  failedFreeDemoList,
  successFreeDemoView,
  failedFreeDemoView,
} from '../actions';
import { showError } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

/**
 *
 */
const getFreeDemoLogic = createLogic({
  type: FreeDemoActions.REQUEST_FREE_DEMO_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { service, url, method, authenticate } = ApiRoutes.GET_FREE_DEMO;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      service,
      url,
      method,
      authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(failedFreeDemoList());
       if (action.payload.search !== '') {
         showError('Invalid search');
       } else {
         showError(messages[0]);
       }
      done();
    }

    dispatch(
      successFreeDemoList({
        freeDemoList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */
const viewFreeDemoLogic = createLogic({
  type: FreeDemoActions.VIEW_FREE_DEMO_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { service, url, method, authenticate } = ApiRoutes.VIEW_FREE_DEMO;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      service,
      url.replace(':id', action.payload.id),
      method,
      authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(failedFreeDemoView());
      showError(messages[0]);
      done();
    }

    dispatch(successFreeDemoView({ freeDemoData: responseData.data }));
    done();
  },
});

export const FreeDemoLogics = [getFreeDemoLogic, viewFreeDemoLogic];
