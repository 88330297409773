/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';
import {
  SchoolActions,
  schoolAddSuccess,
  schoolAddFailed,
  schoolListFailed,
  schoolListSuccess,
  updateSchoolSuccess,
  updateSchoolFailed,
  viewSchoolSuccess,
  viewSchoolFailed,
  requestSchoolList,
  updateSchoolStatusSuccess,
} from '../actions/School';
import { logger } from '../../helpers';

const addSchoolLogic = createLogic({
  type: SchoolActions.ADD_SCHOOL_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SCHOOL.service,
      ApiRoutes.ADD_SCHOOL.url,
      ApiRoutes.ADD_SCHOOL.method,
      ApiRoutes.ADD_SCHOOL.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(schoolAddSuccess());
      dispatch(requestSchoolList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(
        schoolAddFailed({
          isAdded:
            messages[0] ===
            'School added successfully, but having error while sending email.',
        })
      );
      done();
    }
  },
});
/**
 *
 */
const getSchoolLogic = createLogic({
  type: SchoolActions.SCHOOL_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SCHOOL.service,
      ApiRoutes.GET_SCHOOL.url,
      ApiRoutes.GET_SCHOOL.method,
      ApiRoutes.GET_SCHOOL.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
       if (action.payload.search !== '') {
         showError('Invalid search');
       } else {
         showError(messages[0]);
       }
      dispatch(schoolListFailed());
      done();
    }

    dispatch(
      schoolListSuccess({
        school: responseData.data,
        totalRecords:
          responseData.pages && responseData.pages[0]
            ? responseData.pages[0].count
            : 0,
      })
    );
    done();
  },
});
/**
 *
 */
const viewSchoolLogic = createLogic({
  type: SchoolActions.VIEW_SCHOOL_REQUEST,
  async process(data, dispatch, done) {
    logger('viewSchoolLogic', viewSchoolLogic);
    const { action } = data;
    logger('action', action);
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_SCHOOL.service,
      ApiRoutes.VIEW_SCHOOL.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_SCHOOL.method,
      ApiRoutes.VIEW_SCHOOL.authenticate,
      undefined,
      undefined
    );
    logger('data', data);
    if (isError) {
      showError(messages[0]);
      dispatch(viewSchoolFailed());
      done();
    }

    dispatch(
      viewSchoolSuccess({
        schoolData: responseData.data,
        subscriptiondata: responseData.subscriptionDetails,
        taxDetails: responseData.taxDetails,
      })
    );
    done();
  },
});
/**
 *
 */
const updateSchoolLogic = createLogic({
  type: SchoolActions.UPDATE_SCHOOL_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SCHOOL.service,
      ApiRoutes.UPDATE_SCHOOL.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_SCHOOL.method,
      ApiRoutes.UPDATE_SCHOOL.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateSchoolSuccess());
      dispatch(requestSchoolList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateSchoolFailed());
      done();
    }
  },
});

/**
 *
 */
const deleteSchoolLogic = createLogic({
  type: SchoolActions.DELETE_SCHOOL_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SCHOOL.service,
      ApiRoutes.DELETE_SCHOOL.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_SCHOOL.method,
      ApiRoutes.DELETE_SCHOOL.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(viewSchoolFailed(messages[0]));
      done();
    }
    dispatch(requestSchoolList());
    showSuccess(messages[0]);
    done();
  },
});

/**
 *
 */
const updateSchoolStatusLogic = createLogic({
  type: SchoolActions.UPDATE_SCHOOL_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SCHOOL_STATUS.service,
      ApiRoutes.UPDATE_SCHOOL_STATUS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_SCHOOL_STATUS.method,
      ApiRoutes.UPDATE_SCHOOL_STATUS.authenticate,
      undefined,
      { status: action.payload.status }
    );
    if (isError) {
      dispatch(viewSchoolFailed(messages[0]));
      done();
    }
    dispatch(
      requestSchoolList({
        ...action.payload.query,
      })
    );
    dispatch(updateSchoolStatusSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const SchoolLogics = [
  addSchoolLogic,
  getSchoolLogic,
  viewSchoolLogic,
  updateSchoolLogic,
  deleteSchoolLogic,
  updateSchoolStatusLogic,
];
