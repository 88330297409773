import { handleActions } from 'redux-actions';
import { EssayActions } from '../actions';
import { EssayInitialStates } from '../states';

export const EssayReducer = handleActions(
  {
    [EssayActions.ESSAY_LIST_REQUEST]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isEssayLoading: true,
    }),
    [EssayActions.ESSAY_LIST_SUCCESS]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isEssayLoading: false,
      essays: action.payload.essay,
      totalRecords: action.payload.totalRecords,
    }),
    [EssayActions.ESSAY_LIST_FAILED]: (state = EssayInitialStates, action) => ({
      ...state,
      isEssayLoading: false,
      error: true,
    }),

    [EssayActions.VIEW_ESSAY_REQUEST]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [EssayActions.VIEW_ESSAY_SUCCESS]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      essay: action.payload.essay,
    }),
    [EssayActions.VIEW_ESSAY_FAILED]: (state = EssayInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [EssayActions.ADD_ESSAY_REQUEST]: (state = EssayInitialStates, action) => ({
      ...state,
      isAdded: false,
      isLoading: true,
    }),
    [EssayActions.ADD_ESSAY_SUCCESS]: (state = EssayInitialStates, action) => ({
      ...state,
      isLoading: true,
      isAdded: true,
    }),
    [EssayActions.ADD_ESSAY_FAILED]: (state = EssayInitialStates, action) => ({
      ...state,
      isLoading: false,
      isAdded: false,
      error: true,
    }),

    [EssayActions.UPDATE_ESSAY_REQUEST]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isUpdated: false,
      isLoading: true,
    }),
    [EssayActions.UPDATE_ESSAY_SUCCESS]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isUpdated: true,
      isLoading: false,
    }),
    [EssayActions.UPDATE_ESSAY_FAILED]: (
      state = EssayInitialStates,
      action
    ) => ({
      ...state,
      isUpdated: false,
      isLoading: false,
      error: true,
    }),
  },
  EssayInitialStates
);
