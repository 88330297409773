export const TestInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isInstructionUpdated: false,
  isDirectionUpdated: false,
  isTestUnitUpdated: false,
  isTestUnitOrderUpdated: false,
  isQuestionDeleted: false,
  error: false,
  tests: [],
  testUnitData: [],
  testSectionData: [],
  isTestLoading: false,
  isTestDataFetched: false,
  isParagraphQuestionAdded: false,
  flaggedQuestions: [],
  totalFlaggedQuestionRecords: 0,
};
