/** @format */

import { createAction } from 'redux-actions';

export const ProgramSitesActions = {
  /*
---------------------
      Program
---------------------
*/
  // Add new Program
  ADD_PROGRAM_SITES_REQUEST: 'Add Program sites Request!',
  ADD_PROGRAM_SITES_SUCCESS: 'Program sites Added successfully!.',
  ADD_PROGRAM_SITES_FAILED: 'Add Program sites failed!',
  // Program sites listing
  PROGRAM_SITES_LIST_REQUEST: 'Program sites Listing Request!',
  PROGRAM_SITES_LIST_SUCCESS: 'Program sites Listed successfully!.',
  PROGRAM_SITES_LIST_FAILED: 'Program sites Listing failed!',
  // update Program sites Record
  UPDATE_PROGRAM_SITES_REQUEST: 'Update Program sites Record Request!',
  UPDATE_PROGRAM_SITES_SUCCESS: 'Update Program sites Record successfully!.',
  UPDATE_PROGRAM_SITES_FAILED: 'Update Program sites Record failed!',

  // get Program sites Record
  VIEW_PROGRAM_SITES_REQUEST: 'View Program sites Record Request!',
  VIEW_PROGRAM_SITES_SUCCESS: 'View Program sites Record successfully!.',
  VIEW_PROGRAM_SITES_FAILED: 'View Program sites Record failed!',

  // Program options list
  PROGRAM_SITES_OPTION_LIST_REQUEST: 'Request for program sites options list!',
  PROGRAM_SITES_OPTION_LIST_SUCCESS:
    'Program sites options list fetched successfully!.',
  PROGRAM_SITES_OPTION_LIST_FAILED: 'Failed to load program sitesoption list.',

  DELETE_PROGRAM_SITES_REQUEST: 'Request to delete program sites',
  UPDATE_PROGRAM_SITES_STATUS_REQUEST: 'Request to update program sites status',

  UPDATE_PROGRAM_SITES_STATUS_SUCCESS:
    'Program sites status  updated successfully',
};

/*
---------------------
      Program
---------------------
*/

// Add new Program
export const requestAddProgramSite = createAction(
  ProgramSitesActions.ADD_PROGRAM_SITES_REQUEST
);
export const programSiteAddSuccess = createAction(
  ProgramSitesActions.ADD_PROGRAM_SITES_SUCCESS
);
export const programSiteAddFailed = createAction(
  ProgramSitesActions.ADD_PROGRAM_SITES_FAILED
);

export const requestProgramSiteList = createAction(
  ProgramSitesActions.PROGRAM_SITES_LIST_REQUEST
);
export const programSiteListSuccess = createAction(
  ProgramSitesActions.PROGRAM_SITES_LIST_SUCCESS
);
export const programSiteListFailed = createAction(
  ProgramSitesActions.PROGRAM_SITES_LIST_FAILED
);

export const updateProgramSiteRequest = createAction(
  ProgramSitesActions.UPDATE_PROGRAM_SITES_REQUEST
);
export const updateProgramSiteSuccess = createAction(
  ProgramSitesActions.UPDATE_PROGRAM_SITES_SUCCESS
);
export const updateProgramSiteFailed = createAction(
  ProgramSitesActions.UPDATE_PROGRAM_SITES_FAILED
);

export const viewProgramSiteRequest = createAction(
  ProgramSitesActions.VIEW_PROGRAM_SITES_REQUEST
);
export const viewProgramSiteSuccess = createAction(
  ProgramSitesActions.VIEW_PROGRAM_SITES_SUCCESS
);
export const viewProgramSiteFailed = createAction(
  ProgramSitesActions.VIEW_PROGRAM_SITES_FAILED
);

export const programSiteOptionListRequest = createAction(
  ProgramSitesActions.PROGRAM_SITES_OPTION_LIST_REQUEST
);
export const programSiteOptionListSuccess = createAction(
  ProgramSitesActions.PROGRAM_SITES_OPTION_LIST_SUCCESS
);
export const programSiteOptionListFailed = createAction(
  ProgramSitesActions.PROGRAM_SITES_OPTION_LIST_FAILED
);

export const deleteProgramSiteRequest = createAction(
  ProgramSitesActions.DELETE_PROGRAM_SITES_REQUEST
);

export const updateProgramSiteStatusRequest = createAction(
  ProgramSitesActions.UPDATE_PROGRAM_SITES_STATUS_REQUEST
);

export const updateProgramSiteStatusSuccess = createAction(
  ProgramSitesActions.UPDATE_PROGRAM_SITES_STATUS_SUCCESS
);
