/** @format */

import { createLogic } from 'redux-logic';
import {
  SupportActions,
  successSupportList,
  failedSupportList,
  successSupportView,
  failedSupportView,
  successSupportStatusUpdate,
  failedSupportStatusUpdate,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

/**
 *
 */
const getSupportsLogic = createLogic({
  type: SupportActions.REQUEST_SUPPORTS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { service, url, method, authenticate } = ApiRoutes.GET_SUPPORTS;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      service,
      url,
      method,
      authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(failedSupportList());
      if (action.payload.search !== '') {
        showError('Invalid search');
      } else {
        showError(messages[0]);
      }
      done();
    }

    dispatch(
      successSupportList({
        supportList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */
const viewSupportLogic = createLogic({
  type: SupportActions.VIEW_SUPPORT_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { service, url, method, authenticate } = ApiRoutes.VIEW_SUPPORT;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      service,
      url.replace(':id', action.payload.id),
      method,
      authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(failedSupportView());
      showError(messages[0]);
      done();
    }

    dispatch(successSupportView({ supportData: responseData.data }));
    done();
  },
});

/**
 *
 */
const updateSupportStatusLogic = createLogic({
  type: SupportActions.SUPPORT_STATUS_UPDATE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      service,
      url,
      method,
      authenticate,
    } = ApiRoutes.UPDATE_SUPPORT_STATUS;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      service,
      url.replace(':id', action.payload.id),
      method,
      authenticate,
      undefined,
      { status: action.payload.status }
    );
    if (isError) {
      dispatch(failedSupportStatusUpdate());
      showError(messages[0]);
      done();
    }
    dispatch(
      successSupportList({
        ...action.payload.query,
      })
    );
    dispatch(successSupportStatusUpdate());
    showSuccess(messages[0]);
    done();
  },
});

const addSupportRequestLogic = createLogic({
  type: SupportActions.SUPPORT_ADD_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      service,
      url,
      method,
      authenticate,
    } = ApiRoutes.ADD_SUPPORT_REQUEST;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      service,
      url,
      method,
      authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      dispatch(failedSupportStatusUpdate());
      showError(messages[0]);
      done();
    }
    dispatch(
      successSupportList({
        ...action.payload.query,
      })
    );
    dispatch(successSupportStatusUpdate());
    showSuccess(messages[0]);
    done();
  },
});

export const SupportLogics = [
  getSupportsLogic,
  viewSupportLogic,
  updateSupportStatusLogic,
  addSupportRequestLogic,
];
