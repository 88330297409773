/** @format */

import { handleActions } from 'redux-actions';
import { SectionActions } from '../actions';
import { SectionInitialStates } from '../states';

export const SectionReducer = handleActions(
  {
    [SectionActions.REQUEST_ADD_SECTION]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: true,
      isAdded: false,
    }),
    [SectionActions.ADD_SECTION_SUCCESS]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [SectionActions.ADD_SECTION_FAILURE]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: false,
      validationErrors: action.payload.validationErrors,
    }),
    [SectionActions.REQUEST_UPDATE_SECTION]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: true,
      isAdded: false,
    }),

    [SectionActions.SECTIONS_DELETE_REQUEST]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: true,
      isDelete: false,
    }),
    [SectionActions.SECTIONS_DELETE_SUCCESS]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: false,
      isDeleted: true,
    }),
    [SectionActions.SECTIONS_DELETE_FAILED]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: false,
      isDeleted: false,
    }),

    [SectionActions.REQUEST_SECTION_OPTION_LIST]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: true,
      sectionOptions: action.payload.sectionOptions,
    }),
    [SectionActions.SECTION_OPTION_LIST_SUCCESS]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: false,
      sectionOptions: action.payload.sectionOptions,
    }),
    [SectionActions.SECTION_OPTION_LIST_FAILURE]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: false,
      sectionOptions: [],
    }),

    [SectionActions.REQUEST_SECTION_OPTION_SET_INITIAL]: (
      state = SectionInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: false,
      sectionOptions: [],
    }),
  },
  SectionInitialStates
);
