import { handleActions } from 'redux-actions';
import { DashboardActions } from '../actions';
import { DashboardInitialStates } from '../states/Dashboard';

export const DashboardReducer = handleActions(
  {
    [DashboardActions.GET_USERS_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingUser: true,
    }),
    [DashboardActions.GET_USERS_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingUser: false,
      usersData: action.payload.usersData,
    }),
    [DashboardActions.GET_USERS_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingUser: false,
      error: true,
    }),

    [DashboardActions.GET_PAID_ACCOUNTS_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPaidAccount: true,
    }),
    [DashboardActions.GET_PAID_ACCOUNTS_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPaidAccount: false,
      paidAccountsData: action.payload.paidAccountsData,
    }),
    [DashboardActions.GET_PAID_ACCOUNTS_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPaidAccount: false,
      error: true,
    }),

    [DashboardActions.GET_SALES_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSale: true,
    }),
    [DashboardActions.GET_SALES_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSale: false,
      salesData: action.payload.salesData,
    }),
    [DashboardActions.GET_SALES_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSale: false,
      error: true,
    }),

    [DashboardActions.GET_SUPPORTS_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSupport: true,
    }),
    [DashboardActions.GET_SUPPORTS_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSupport: false,
      supportsData: action.payload.supportsData,
    }),
    [DashboardActions.GET_SUPPORTS_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSupport: false,
      error: true,
    }),

    [DashboardActions.GET_ESSAYS_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingEssay: true,
    }),
    [DashboardActions.GET_ESSAYS_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingEssay: false,
      essaysData: action.payload.essaysData,
    }),
    [DashboardActions.GET_ESSAYS_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingEssay: false,
      error: true,
    }),

    [DashboardActions.GET_DEMO_USERS_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingFreeDemo: true,
    }),
    [DashboardActions.GET_DEMO_USERS_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingFreeDemo: false,
      demoUsersData: action.payload.demoUsersData,
    }),
    [DashboardActions.GET_DEMO_USERS_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingFreeDemo: false,
      error: true,
    }),

    [DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPaidAccount: true,
    }),
    [DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPaidAccount: false,
      thisYearPaidAccountsData: action.payload.thisYearPaidAccountsData,
    }),
    [DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPaidAccount: false,
      error: true,
    }),

    // district dashboard
    

    [DashboardActions.GET_DISTRICT_DASHBOARD_REQUEST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingDistrict: true,
    }),
    [DashboardActions.GET_DISTRICT_DASHBOARD_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingDistrict: false,
      DistrictDashboardData: action.payload.DistrictDashboardData,
      
    }),
    [DashboardActions.GET_DISTRICT_DASHBOARD_FAILED]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoadingDistrict: false,
      error: true,
    }),
  },
  DashboardInitialStates
);
