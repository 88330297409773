import { createAction } from 'redux-actions';

export const TestActions = {
  REQUEST_TEST_LIST: 'Request to fetch tests',
  TEST_LIST_SUCCESS: 'Tests fetched successfully',
  TEST_LIST_FAILURE: 'Failed to fetched tests',

  UPDATE_TEST_INSTRUCTIONS_REQUEST: 'Request to update test instructions',
  UPDATE_TEST_INSTRUCTIONS_SUCCESS: 'Test instructions updated successfully',
  UPDATE_TEST_INSTRUCTIONS_FAILURE: 'Failed to update test instructions',

  UPDATE_TEST_DIRECTIONS_REQUEST: 'Request to update test directions',
  UPDATE_TEST_DIRECTIONS_SUCCESS: 'Test directions updated successfully',
  UPDATE_TEST_DIRECTIONS_FAILURE: 'Failed to update test directions',

  REQUEST_TEST_SECTION_DATA: 'Request to fetch test section data',
  TEST_SECTION_DATA_SUCCESS: 'Test section data fetched successfully',
  TEST_SECTION_DATA_FAILED: 'Failed to fetch test section data',

  REQUEST_TEST_UNIT_DATA: 'Request to fetch test unit data',
  TEST_UNIT_DATA_SUCCESS: 'Test unit data fetched successfully',
  TEST_UNIT_DATA_FAILED: 'Failed to fetch test unit data',

  UPDATE_TEST_UNIT_DATA_REQUEST: 'Request to update test unit data',
  UPDATE_TEST_UNIT_DATA_SUCCESS: 'Test unit data updated successfully',
  UPDATE_TEST_UNIT_DATA_FAILURE: 'Failed to update test unit data',

  UPDATE_TEST_UNIT_DATA_ORDER_REQUEST: 'Request to update test unit data order',
  UPDATE_TEST_UNIT_DATA_ORDER_SUCCESS:
    'Test unit data order updated successfully',
  UPDATE_TEST_UNIT_DATA_ORDER_FAILURE: 'Failed to update test unit data order',

  DELETE_TEST_UNIT_DATA_ORDER_REQUEST: 'Request to delete test unit data order',
  DELETE_TEST_UNIT_DATA_ORDER_SUCCESS:
    'Test unit data order deleted successfully',
  DELETE_TEST_UNIT_DATA_ORDER_FAILURE: 'Failed to delete test unit data order',

  REQUEST_DELETE_TEST_QUESTION: 'Request to delete test question',
  DELETE_TEST_QUESTION_SUCCESS: 'Test question deleted successfully',
  DELETE_TEST_QUESTION_FAILURE: 'Failed to delete test question',

  REQUEST_TEST_REDUCER_SET_INITIAL: 'Test Reducer reset successfully',

  REQUEST_ADD_NEW_TEST_PARAGRAPH_QUESTION:
    'Request to add new test paragraph/question',
  ADD_NEW_TEST_PARAGRAPH_QUESTION_SUCCESS:
    'Test paragraph/question added successfully',
  ADD_NEW_TEST_PARAGRAPH_QUESTION_FAILURE:
    'Failed to add test paragraph/question',

  FLAGGED_QUESTIONS_LIST_REQUEST: 'Request to fetch flagged questions',
  FLAGGED_QUESTIONS_LIST_SUCCESS: 'Flagged questions fetched successfully',
  FLAGGED_QUESTIONS_LIST_FAILURE: 'Failed to fetched flagged questions',

  DELETE_TEST_QUESTION_VIDEO_REQUEST: 'DELETE_TEST_QUESTION_VIDEO_REQUEST',
  DELETE_TEST_QUESTION_VIDEO_SUCCESS: 'DELETE_TEST_QUESTION_VIDEO_SUCCESS',
  DELETE_TEST_QUESTION_VIDEO_FAILURE: 'DELETE_TEST_QUESTION_VIDEO_FAILURE',
};

// List test actions
export const requestTestList = createAction(TestActions.REQUEST_TEST_LIST);
export const testListSuccess = createAction(TestActions.TEST_LIST_SUCCESS);
export const testListFailed = createAction(TestActions.TEST_LIST_FAILURE);

// Test section data  actions
export const requestTestSectionData = createAction(
  TestActions.REQUEST_TEST_SECTION_DATA
);
export const testSectionDataSuccess = createAction(
  TestActions.TEST_SECTION_DATA_SUCCESS
);
export const testSectionDataFailed = createAction(
  TestActions.TEST_SECTION_DATA_FAILED
);

// Test Unit data  actions
export const requestTestUnitData = createAction(
  TestActions.REQUEST_TEST_UNIT_DATA
);
export const testUnitDataSuccess = createAction(
  TestActions.TEST_UNIT_DATA_SUCCESS
);
export const testUnitDataFailed = createAction(
  TestActions.TEST_UNIT_DATA_FAILED
);

export const deleteTestQuestionVideoRequest = createAction(
  TestActions.DELETE_TEST_QUESTION_VIDEO_REQUEST
);
export const deleteTestQuestionVideoSuccess = createAction(
  TestActions.DELETE_TEST_QUESTION_VIDEO_SUCCESS
);
export const deleteTestQuestionVideoFailed = createAction(
  TestActions.DELETE_TEST_QUESTION_VIDEO_FAILURE
);

// Update test instruction actions
export const updateTestInstructionRequest = createAction(
  TestActions.UPDATE_TEST_INSTRUCTIONS_REQUEST
);
export const updateTestInstructionSuccess = createAction(
  TestActions.UPDATE_TEST_INSTRUCTIONS_SUCCESS
);
export const updateTestInstructionFailed = createAction(
  TestActions.UPDATE_TEST_INSTRUCTIONS_FAILURE
);

// Update test direction actions
export const updateTestDirectionRequest = createAction(
  TestActions.UPDATE_TEST_DIRECTIONS_REQUEST
);
export const updateTestDirectionSuccess = createAction(
  TestActions.UPDATE_TEST_DIRECTIONS_SUCCESS
);
export const updateTestDirectionFailed = createAction(
  TestActions.UPDATE_TEST_DIRECTIONS_FAILURE
);

// Update test description actions
export const updateTestUnitDataRequest = createAction(
  TestActions.UPDATE_TEST_UNIT_DATA_REQUEST
);
export const updateTestUnitDataSuccess = createAction(
  TestActions.UPDATE_TEST_UNIT_DATA_SUCCESS
);
export const updateTestUnitDataFailed = createAction(
  TestActions.UPDATE_TEST_UNIT_DATA_FAILURE
);

// Update test description actions
export const updateTestUnitDataOrderRequest = createAction(
  TestActions.UPDATE_TEST_UNIT_DATA_ORDER_REQUEST
);
export const updateTestUnitDataOrderSuccess = createAction(
  TestActions.UPDATE_TEST_UNIT_DATA_ORDER_SUCCESS
);
export const updateTestUnitDataOrderFailed = createAction(
  TestActions.UPDATE_TEST_UNIT_DATA_ORDER_FAILURE
);



export const deleteTestUnitDataOrderRequest = createAction(
  TestActions.DELETE_TEST_UNIT_DATA_ORDER_REQUEST
);
export const deleteTestUnitDataOrderSuccess = createAction(
  TestActions.DELETE_TEST_UNIT_DATA_ORDER_SUCCESS
);
export const deleteTestUnitDataOrderFailed = createAction(
  TestActions.DELETE_TEST_UNIT_DATA_ORDER_FAILURE
);

/*
----------------------------
  Delete  test questions
---------------------------
*/
export const deleteTestQuestionRequest = createAction(
  TestActions.REQUEST_DELETE_TEST_QUESTION
);
export const deleteTestQuestionSuccess = createAction(
  TestActions.DELETE_TEST_QUESTION_SUCCESS
);
export const deleteTestQuestionFailed = createAction(
  TestActions.DELETE_TEST_QUESTION_FAILURE
);

export const requestTestReducerSetInitial = createAction(
  TestActions.REQUEST_TEST_REDUCER_SET_INITIAL
);

/*
---------------------------------------
  Add new  test paragraph or question
---------------------------------------
*/
export const requestAddTestParagraphOrQuestion = createAction(
  TestActions.REQUEST_ADD_NEW_TEST_PARAGRAPH_QUESTION
);
export const addTestParagraphOrQuestionSuccess = createAction(
  TestActions.ADD_NEW_TEST_PARAGRAPH_QUESTION_SUCCESS
);
export const addTestParagraphOrQuestionFailed = createAction(
  TestActions.ADD_NEW_TEST_PARAGRAPH_QUESTION_FAILURE
);

// Flagged questions listing actions
export const flaggedQuestionsListRequest = createAction(
  TestActions.FLAGGED_QUESTIONS_LIST_REQUEST
);
export const flaggedQuestionsListSuccess = createAction(
  TestActions.FLAGGED_QUESTIONS_LIST_SUCCESS
);
export const flaggedQuestionsListFailed = createAction(
  TestActions.FLAGGED_QUESTIONS_LIST_FAILURE
);
