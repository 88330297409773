/** @format */

import { createAction } from 'redux-actions';

export const SectionActions = {
  REQUEST_ADD_SECTION: 'Request to add section',
  ADD_SECTION_SUCCESS: 'Section added successfully',
  ADD_SECTION_FAILURE: 'Failed to add section',
  REQUEST_UPDATE_SECTION: 'Request to update section',
  REQUEST_DELETE_SECTION: 'Request to delete section',
  REQUEST_SECTION_OPTION_LIST: 'Request to fetch section option list',
  SECTION_OPTION_LIST_SUCCESS: 'Section option list fetched successfully.',
  SECTION_OPTION_LIST_FAILURE: 'Failed to fetch section option list',

  REQUEST_SECTION_OPTION_SET_INITIAL: 'Section Options reset successfully',

  SECTIONS_DELETE_REQUEST: 'SECTIONS_DELETE_REQUEST',
  SECTIONS_DELETE_SUCCESS: 'SECTIONS_DELETE_SUCCESS',
  SECTIONS_DELETE_FAILED: 'SECTIONS_DELETE_FAILED',
};

// Add section actions
export const requestAddSection = createAction(
  SectionActions.REQUEST_ADD_SECTION
);
export const addSectionSuccess = createAction(
  SectionActions.ADD_SECTION_SUCCESS
);
export const addSectionFailed = createAction(
  SectionActions.ADD_SECTION_FAILURE
);

export const updateSectionRequest = createAction(
  SectionActions.REQUEST_UPDATE_SECTION
);

export const deleteSectionRequest = createAction(
  SectionActions.REQUEST_DELETE_SECTION
);

export const requestSectionOptionsList = createAction(
  SectionActions.REQUEST_SECTION_OPTION_LIST
);
export const sectionOptionsListSuccess = createAction(
  SectionActions.SECTION_OPTION_LIST_SUCCESS
);
export const sectionOptionsListFailed = createAction(
  SectionActions.SECTION_OPTION_LIST_FAILURE
);
export const requestSectionOptionsSetInitial = createAction(
  SectionActions.REQUEST_SECTION_OPTION_SET_INITIAL
);


export const deleteSectionsRequest = createAction(
  SectionActions.SECTIONS_DELETE_REQUEST
);
export const deleteSectionSuccess = createAction(
  SectionActions.SECTIONS_DELETE_SUCCESS
);
export const deleteSectionFailed = createAction(
  SectionActions.SECTIONS_DELETE_FAILED
);
// export const updateStudentSuccess = createAction(
//   StudentActions.UPDATE_STUDENT_SUCCESS,
// );
// export const updateStudentFailed = createAction(
//   StudentActions.UPDATE_STUDENT_FAILURE,
// );
