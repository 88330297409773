/** @format */

import { createLogic } from 'redux-logic';
import {
  PrivacyPolicyActions,
  updatePrivacyPolicySuccess,
  updatePrivacyPolicyFailed,
  getPrivacyPolicyRequest,
  getPrivacyPolicyFailed,
  getPrivacyPolicySuccess,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import { ApiHelper } from '../../helpers';

/**
 ------------------------
    GET PRIVACY POLICY RECORDS
 ------------------------
 */
const getPrivacyPolicyLogic = createLogic({
  type: PrivacyPolicyActions.GET_PRIVACY_POLICY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PRIVACYPOLICY.service,
      ApiRoutes.GET_PRIVACYPOLICY.url,
      ApiRoutes.GET_PRIVACYPOLICY.method,
      ApiRoutes.GET_PRIVACYPOLICY.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getPrivacyPolicyFailed());
      done();
    }

    dispatch(
      getPrivacyPolicySuccess({
        privacyPolicyData: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const updatePrivacyPolicyLogic = createLogic({
  type: PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PRIVACYPOLICY.service,
      ApiRoutes.UPDATE_PRIVACYPOLICY.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_PRIVACYPOLICY.method,
      ApiRoutes.UPDATE_PRIVACYPOLICY.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updatePrivacyPolicySuccess());
      showSuccess(messages[0]);
      dispatch(getPrivacyPolicyRequest());
      done();
    } else {
      showError(messages[0]);
      dispatch(updatePrivacyPolicyFailed());
      done();
    }
  },
});

export const PrivacyPolicyLogics = [
  getPrivacyPolicyLogic,
  updatePrivacyPolicyLogic,
];
