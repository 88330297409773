import { handleActions } from 'redux-actions';

import { LinkageReqInitialStates } from './../states';
import { LinkageActions } from '../actions';

export const LinkageReducer = handleActions(
  {
    [LinkageActions.ORGANIZATIONS_REQUEST]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LinkageActions.ORGANIZATIONS_SUCCESS]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      organizations: action.payload.organizations,
    }),
    [LinkageActions.ORGANIZATIONS_FAILED]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),

    [LinkageActions.LINKAGE_LIST_REQUEST]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LinkageActions.LINKAGE_LIST_SUCCESS]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      requests: action.payload.requests,
    }),
    [LinkageActions.LINKAGE_LIST_FAILED]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),

    [LinkageActions.LINKAGE_GENERATE_REQUEST]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LinkageActions.LINKAGE_GENERATE_SUCCESS]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
    [LinkageActions.LINKAGE_GENERATE_FAILED]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),

    [LinkageActions.LINKAGE_ATTEMPT_REQUEST]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LinkageActions.LINKAGE_ATTEMPT_SUCCESS]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
    [LinkageActions.LINKAGE_ATTEMPT_FAILED]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),

    [LinkageActions.LINKAGE_UNLINK_REQUEST]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LinkageActions.LINKAGE_UNLINK_SUCCESS]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
    [LinkageActions.LINKAGE_UNLINK_FAILED]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),

    [LinkageActions.LINKED_ORGANIZATIONS_REQUEST]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LinkageActions.LINKED_ORGANIZATIONS_SUCCESS]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      linkedOrganizations: action.payload.linkedOrganizations,
    }),
    [LinkageActions.LINKED_ORGANIZATIONS_FAILED]: (
      state = LinkageReqInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
  },
  LinkageReqInitialStates
);
