/** @format */

import { handleActions } from 'redux-actions';
import { QuizActions } from '../actions';
import { QuizInitialStates } from '../states';

export const QuizReducer = handleActions(
  {
    [QuizActions.REQUEST_GET_QUIZ_QUESTIONS_BY_ID]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuizLoading: true,
      quizDataFetched: false,
    }),
    [QuizActions.GET_QUIZ_QUESTIONS_BY_ID_SUCCESS]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuizLoading: false,
      quizQuestions: action.payload.data,
      quizDataFetched: true,
    }),
    [QuizActions.GET_QUIZ_QUESTIONS_BY_ID_FAILURE]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuizLoading: false,
    }),

    [QuizActions.UPDATE_QUIZ_DATA_REQUEST]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isQuizUpdated: false,
    }),
    [QuizActions.UPDATE_QUIZ_DATA_SUCCESS]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isQuizUpdated: true,
    }),
    [QuizActions.UPDATE_QUIZ_DATA_FAILURE]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [QuizActions.DELETE_QUESTION_VIDEO_REQUEST]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isQuizUpdated: false,
    }),
    [QuizActions.DELETE_QUESTION_VIDEO_SUCCESS]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isQuizUpdated: true,
    }),
    [QuizActions.DELETE_QUESTION_VIDEO_FAILURE]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [QuizActions.UPDATE_QUIZ_DATA_ORDER_REQUEST]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuizDataOrderUpdated: false,
    }),
    [QuizActions.UPDATE_QUIZ_DATA_ORDER_SUCCESS]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuizDataOrderUpdated: true,
    }),
    [QuizActions.UPDATE_QUIZ_DATA_ORDER_FAILURE]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      error: true,
    }),

    [QuizActions.REQUEST_ADD_NEW_QUIZZES_PARAGRAPH]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isParagraphAdded: false,
    }),
    [QuizActions.ADD_NEW_QUIZZES_PARAGRAPH_SUCCESS]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isParagraphAdded: true,
    }),
    [QuizActions.ADD_NEW_QUIZZES_PARAGRAPH_FAILURE]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isParagraphAdded: false,
      error: true,
    }),

    [QuizActions.REQUEST_DELETE_QUIZ_QUESTION]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuestionDeleted: false,
    }),
    [QuizActions.DELETE_QUIZ_QUESTION_SUCCESS]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuestionDeleted: true,
    }),
    [QuizActions.DELETE_QUIZ_QUESTION_FAILURE]: (
      state = QuizInitialStates,
      action
    ) => ({
      ...state,
      isQuestionDeleted: false,
    }),
  },
  QuizInitialStates
);
