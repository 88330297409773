/** @format */

import { createLogic } from 'redux-logic';
import {
  HomePageActions,
  addTestimonialSuccess,
  addTestimonialFailed,
  homePageListSuccess,
  homePageListFailed,
  requestHomePageList,
  updateTestimonialSuccess,
  updateTestimonialFailed,
  updateBannerSuccess,
  updateBannerFailed,
  deleteTestimonialSuccess,
  deleteTestimonialFailed,
  updateVideoSectionSuccess,
  updateVideoSectionFailed,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import { ApiHelper } from '../../helpers/ApiHelper';

/**
 *
 */

const addTestimonialLogic = createLogic({
  type: HomePageActions.REQUEST_ADD_TESTIMONIAL,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_TESTIMONIAL.service,
      ApiRoutes.ADD_TESTIMONIAL.url,
      ApiRoutes.ADD_TESTIMONIAL.method,
      ApiRoutes.ADD_TESTIMONIAL.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addTestimonialSuccess());
      showSuccess(messages[0]);
      dispatch(requestHomePageList());
      done();
    } else {
      showError(messages[0]);
      dispatch(addTestimonialFailed());
      done();
    }
  },
});

/**
 *
 */
const getHomePagesLogic = createLogic({
  type: HomePageActions.HOMEPAGE_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_HOMEPAGE.service,
      ApiRoutes.GET_HOMEPAGE.url,
      ApiRoutes.GET_HOMEPAGE.method,
      ApiRoutes.GET_HOMEPAGE.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(homePageListFailed());
      done();
    }

    dispatch(
      homePageListSuccess({
        homePageData: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */
const updateBannerLogic = createLogic({
  type: HomePageActions.UPDATE_BANNER_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_BANNER.service,
      ApiRoutes.UPDATE_BANNER.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_BANNER.method,
      ApiRoutes.UPDATE_BANNER.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateBannerSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateBannerFailed());
      done();
    }
  },
});

/**
 *
 */
const updateVideoSectionLogic = createLogic({
  type: HomePageActions.UPDATE_VIDEO_SECTION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_VIDEO_SECTION.service,
      ApiRoutes.UPDATE_VIDEO_SECTION.url,
      ApiRoutes.UPDATE_VIDEO_SECTION.method,
      ApiRoutes.UPDATE_VIDEO_SECTION.authenticate,
      undefined,
      action.payload
    );

    if (!isError) {
      dispatch(updateVideoSectionSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateVideoSectionFailed());
      done();
    }
  },
});

/**
 *
 */
const updateTestimonialLogic = createLogic({
  type: HomePageActions.UPDATE_TESTIMONIAL_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_TESTIMONIAL.service,
      ApiRoutes.UPDATE_TESTIMONIAL.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_TESTIMONIAL.method,
      ApiRoutes.UPDATE_TESTIMONIAL.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateTestimonialSuccess());
      showSuccess(messages[0]);
      dispatch(requestHomePageList());
      done();
    } else {
      showError(messages[0]);
      dispatch(updateTestimonialFailed());
      done();
    }
  },
});

const deleteTestimonialLogic = createLogic({
  type: HomePageActions.DELETE_TESTIMONIAL_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_TESTIMONIAL.service,
      ApiRoutes.DELETE_TESTIMONIAL.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_TESTIMONIAL.method,
      ApiRoutes.DELETE_TESTIMONIAL.authenticate,
      undefined,
      undefined
    );
    if (!isError) {
      dispatch(deleteTestimonialSuccess());
      showSuccess(messages[0]);
      dispatch(requestHomePageList());
      done();
    } else {
      showError(messages[0]);
      dispatch(deleteTestimonialFailed());
      done();
    }
  },
});

export const HomePageLogics = [
  addTestimonialLogic,
  updateTestimonialLogic,
  getHomePagesLogic,
  updateBannerLogic,
  deleteTestimonialLogic,
  updateVideoSectionLogic,
];
