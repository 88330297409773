/** @format */

import { handleActions } from 'redux-actions';
import { ContactActions } from '../actions';
import { ContactInitialStates } from '../states/Contact';

export const ContactReducer = handleActions(
  {
    [ContactActions.REQUEST_CONTACT_LIST]: (
      state = ContactInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ContactActions.CONTACT_LIST_SUCCESS]: (
      state = ContactInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      contactList: action.payload.contactList,
      totalRecords: action.payload.totalRecords,
    }),
    [ContactActions.CONTACT_LIST_FAILURE]: (
      state = ContactInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [ContactActions.VIEW_CONTACT_REQUEST]: (
      state = ContactInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [ContactActions.VIEW_CONTACT_SUCCESS]: (
      state = ContactInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      contactData: action.payload.contactData,
    }),
    [ContactActions.VIEW_CONTACT_FAILURE]: (
      state = ContactInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  ContactInitialStates
);
