/** @format */

import { createLogic } from 'redux-logic';
import {
  GlobalSettingActions,
  updateGlobalSettingSuccess,
  updateGlobalSettingFailed,
  getGlobalSettingRequest,
  getGlobalSettingSuccess,
  getGlobalSettingFailed,
  updateGlobalSettingStatusSuccess,
  updateGlobalSettingStatusFailed,
} from '../actions';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import { ApiHelper } from '../../helpers';

/**
 ------------------------
    GET GLOBAL SETTING RECORDS
 ------------------------
 */
const getGlobalSettingLogic = createLogic({
  type: GlobalSettingActions.GET_GLOBAL_SETTING_CONTENT,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_GLOBALSETTING.service,
      ApiRoutes.GET_GLOBALSETTING.url,
      ApiRoutes.GET_GLOBALSETTING.method,
      ApiRoutes.GET_GLOBALSETTING.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(getGlobalSettingFailed());
      done();
    }

    dispatch(
      getGlobalSettingSuccess({
        globalSettingData: responseData.data,
      })
    );
    done();
  },
});

/**
 ------------------------
    UPDATE GLOBAL SETTING RECORDS
 ------------------------
 */
const updateGlobalSettingLogic = createLogic({
  type: GlobalSettingActions.UPDATE_GLOBALSETTING_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_GLOBALSETTING.service,
      ApiRoutes.UPDATE_GLOBALSETTING.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_GLOBALSETTING.method,
      ApiRoutes.UPDATE_GLOBALSETTING.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateGlobalSettingSuccess());
      showSuccess(messages[0]);
      dispatch(getGlobalSettingRequest());
      done();
    } else {
      showError(messages[0]);
      dispatch(updateGlobalSettingFailed());
      done();
    }
  },
});

/**
 ---------------------------
    Update Global setting STATUS
 ---------------------------
 */
const UpdateGlobalSettingStatusLogic = createLogic({
  type: GlobalSettingActions.UPDATE_GLOBALSETTING_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_GLOBALSETTING_STATUS.service,
      ApiRoutes.UPDATE_GLOBALSETTING_STATUS.url.replace(
        ':id',
        action.payload.id
      ),
      ApiRoutes.UPDATE_GLOBALSETTING_STATUS.method,
      ApiRoutes.UPDATE_GLOBALSETTING_STATUS.authenticate,
      undefined,
      action.payload
    );

    if (!isError) {
      dispatch(updateGlobalSettingStatusSuccess());
      dispatch(
        getGlobalSettingRequest({
          ...action.payload.query,
        })
      );
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateGlobalSettingStatusFailed());
      done();
    }
  },
});

export const GlobalSettingLogics = [
  getGlobalSettingLogic,
  updateGlobalSettingLogic,
  UpdateGlobalSettingStatusLogic,
];
