import { handleActions } from 'redux-actions';

import { AuthInitialStates, ResetPasswordInitialStates } from './../states';
import { AuthActions } from '../actions';

export const AuthReducer = handleActions(
  {
    [AuthActions.REQUEST_LOGIN]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [AuthActions.LOGIN_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: false,
      isLoading: false,
    }),
    [AuthActions.REQUEST_FORGOT_PASSWORD]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [AuthActions.GET_USER_DETAILS]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      profileUpdating: false,
      isAuthenticated: false,
    }),

    [AuthActions.USER_DETAILS_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      user: action.payload.user,
      district: action.payload.district,
      profileUpdating: false,
      isAuthenticated: true,
    }),

    [AuthActions.USER_DETAILS_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      user: action.payload.user,
      profileUpdating: false,
      isAuthenticated: false,
    }),

    [AuthActions.CHANGE_PASSWORD_REQUEST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: true,
      passwordUpdated: false,
    }),
    [AuthActions.CHANGE_PASSWORD_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: false,
      passwordUpdated: true,
    }),
    [AuthActions.CHANGE_PASSWORD_FAILURE]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: false,
    }),
    [AuthActions.REQUEST_UPDATE_PROFILE]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      profileUpdating: true,
    }),
    [AuthActions.UPDATE_PROFILE_FAILURE]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      profileUpdating: false,
    }),
  },
  AuthInitialStates
);

export const ResetPasswordReducer = handleActions(
  {
    [AuthActions.LINK_VERIFICATION_REQUEST]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      verifyTokenLoading: true,
      isLinkVerified: true,
    }),
    [AuthActions.LINK_VERIFICATION_SUCCESS]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      verifyTokenLoading: false,
      isLinkVerified: true,
      userData: action.payload,
    }),
    [AuthActions.LINK_VERIFICATION_FAILURE]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      verifyTokenLoading: false,
      isLinkVerified: false,
      message: action.payload.message,
    }),
    [AuthActions.SET_PASSWORD_REQUEST]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      isPasswordLoading: true,
      passwordSet: false,
    }),
    [AuthActions.SET_PASSWORD_SUCCESS]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      isPasswordLoading: false,
      passwordSet: true,
    }),
    [AuthActions.SET_PASSWORD_FAILURE]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      isPasswordLoading: false,
      passwordSet: false,
    }),
  },
  ResetPasswordInitialStates
);
