/** @format */

import { createAction } from 'redux-actions';

export const TermsConditionActions = {
  // get Terms & Condition Record
  GET_TERMS_CONDITION_REQUEST: 'Get Terms & Condition Record Request!',
  GET_TERMS_CONDITION_SUCCESS: 'Get Terms & Condition Record successfully!.',
  GET_TERMS_CONDITION_FAILED: 'Get Terms & Condition Record failed!',

  // update Terms & Condition Record
  UPDATE_TERMSCONDITION_REQUEST: 'Update Terms & Condition Record Request!',
  UPDATE_TERMSCONDITION_SUCCESS:
    'Update Terms & Condition Record successfully!.',
  UPDATE_TERMSCONDITION_FAILED: 'Update Terms & Condition Record failed!',
};

// Get Terms Condition actions
export const getTermsConditionRequest = createAction(
  TermsConditionActions.GET_TERMS_CONDITION_REQUEST
);
export const getTermsConditionSuccess = createAction(
  TermsConditionActions.GET_TERMS_CONDITION_SUCCESS
);
export const getTermsConditionFailed = createAction(
  TermsConditionActions.GET_TERMS_CONDITION_FAILED
);

// Update Terms Condition actions
export const updateTermsConditionRequest = createAction(
  TermsConditionActions.UPDATE_TERMSCONDITION_REQUEST
);
export const updateTermsConditionSuccess = createAction(
  TermsConditionActions.UPDATE_TERMSCONDITION_SUCCESS
);
export const updateTermsConditionFailed = createAction(
  TermsConditionActions.UPDATE_TERMSCONDITION_FAILED
);
