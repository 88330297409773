/** @format */

import { createAction } from 'redux-actions';

export const FreeDemoActions = {
  REQUEST_FREE_DEMO_LIST: 'Request to fetch Free Demo list',
  FREE_DEMO_LIST_SUCCESS: 'Free Demo list fetched successfully',
  FREE_DEMO_LIST_FAILURE: 'Failed to fetched Free Demo list',
  VIEW_FREE_DEMO_REQUEST: 'Request to view Free Demo',
  VIEW_FREE_DEMO_SUCCESS: 'Free Demo details fetched successfully',
  VIEW_FREE_DEMO_FAILURE: 'Failed to fetch Free Demo details',
};

// List Free Demo Actions
export const requestFreeDemoList = createAction(
  FreeDemoActions.REQUEST_FREE_DEMO_LIST
);
export const successFreeDemoList = createAction(
  FreeDemoActions.FREE_DEMO_LIST_SUCCESS
);
export const failedFreeDemoList = createAction(
  FreeDemoActions.FREE_DEMO_LIST_FAILURE
);

// View Free Demo Actions
export const requestFreeDemoView = createAction(
  FreeDemoActions.VIEW_FREE_DEMO_REQUEST
);
export const successFreeDemoView = createAction(
  FreeDemoActions.VIEW_FREE_DEMO_SUCCESS
);
export const failedFreeDemoView = createAction(
  FreeDemoActions.VIEW_FREE_DEMO_FAILURE
);
