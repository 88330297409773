/** @format */

export const ProgramSitesInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  programSites: [],
  programSiteData: {},
  programSiteOptions: [],
  totalRecords: 0,
  subscriptiondata: [],
  taxDetails: [],
};
