/** @format */

export const ProgramAdminInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  error: false,
  programAdmins: [],
  programAdminData: {},
  totalRecords: 0,
};
