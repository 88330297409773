import Swal from 'sweetalert2';
export const ConfirmBox = async (obj) => {
  if (!obj) {
    obj = {};
  }
  let {
    title,
    text,
    type,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText,
    cancelButtonText,
    showCloseButton,
  } = obj;
  return await Swal.fire({
    title: title || 'Are You Sure?',
    text: text || 'You want to be able to revert this!',
    type: type || 'warning',
    showCancelButton: true,
    showCloseButton: showCloseButton || false,
    confirmButtonColor: confirmButtonColor || '#3085d6',
    cancelButtonColor: cancelButtonColor || '#d33',
    confirmButtonText: confirmButtonText || 'Yes',
    cancelButtonText: cancelButtonText || 'Cancel',
  });
};
