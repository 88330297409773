import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { createBrowserHistory } from 'history';
import 'react-toastify/dist/ReactToastify.css';

import { AppRoutes } from './route';
import FullPageLoader from './containers/Loader/FullPageLoader';
import configureStore from './redux/store';
import './App.scss';
import { AuthProvider } from './AuthContext';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));

const history = createBrowserHistory();
const store = configureStore(history);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <React.Suspense fallback={<FullPageLoader />}>
            <Switch>
              <Route
                exact
                path={AppRoutes.LOGIN}
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path={AppRoutes.FORGOT_PASSWORD}
                name="Forgot Password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path={AppRoutes.RESET_PASSWORD}
                name="Reset password"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path={AppRoutes.NOT_AVAILABLE}
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <AuthProvider>
                <Route
                  path={AppRoutes.MAIN}
                  name="Home"
                  render={(props) => <DefaultLayout {...props} />}
                />
              </AuthProvider>
            </Switch>
            <ToastContainer />
          </React.Suspense>
        </Router>
      </Provider>
    );
  }
}

export default App;
