import { handleActions } from 'redux-actions';
import { TransactionActions } from '../actions';
import { TransactionInitialStates } from '../states';

export const TransactionReducer = handleActions(
  {
    [TransactionActions.TRANSACTION_LIST_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [TransactionActions.TRANSACTION_LIST_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      transactions: action.payload.transactions,
      totalRecords: action.payload.totalRecords,
    }),
    [TransactionActions.TRANSACTION_LIST_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [TransactionActions.REQUEST_ADD_OFFLINE_TRANSACTION]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isSubmitting: true,
      isAdded: false,
    }),
    [TransactionActions.ADD_OFFLINE_TRANSACTION_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      isAdded: true,
    }),
    [TransactionActions.ADD_OFFLINE_TRANSACTION_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      error: true,
    }),

    [TransactionActions.REQUEST_DEMO_USER_REGISTER]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isSubmitting: true,
      isAdded: false,
    }),
    [TransactionActions.DEMO_USER_REGISTER_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      isAdded: true,
    }),
    [TransactionActions.DEMO_USER_REGISTER_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      error: true,
    }),

    [TransactionActions.TOTAL_SALES_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isTransactionLoading: true,
    }),
    [TransactionActions.TOTAL_SALES_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isTransactionLoading: false,
      totalSales: action.payload.totalSales,
    }),
    [TransactionActions.TOTAL_SALES_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isTransactionLoading: false,
      isSubmitting: false,
      error: true,
    }),

    [TransactionActions.USER_LISTING_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isUserLoading: true,
      users: [],
      freeDemoUser: [],
    }),
    [TransactionActions.USER_LISTING_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isUserLoading: false,
      users: action.payload.users,
      freeDemoUser: action.payload.freeDemoUser,
    }),
    [TransactionActions.USER_LISTING_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isUserLoading: false,
      error: true,
    }),
  },
  TransactionInitialStates
);
