/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { showError, showSuccess } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import {
  QuizActions,
  getQuizQuestionsByIdSuccess,
  getQuizQuestionsDataByIdFailed,
  updateQuizDataSuccess,
  updateQuizDataFailed,
  updateQuizDataOrderFailed,
  updateQuizDataOrderSuccess,
  addNewQuizParagraphSuccess,
  addNewQuizParagraphFailed,
  deleteQuizQuestionSuccess,
  deleteQuizQuestionFailed,
  deleteQuestionVideoSuccess,
  deleteQuestionVideoFailed,
} from '../actions';

const getQuizQuestionsLogic = createLogic({
  type: QuizActions.REQUEST_GET_QUIZ_QUESTIONS_BY_ID,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_QUIZ_QUESTIONS.service,
        ApiRoutes.GET_QUIZ_QUESTIONS.url,
        ApiRoutes.GET_QUIZ_QUESTIONS.method,
        ApiRoutes.GET_QUIZ_QUESTIONS.authenticate,
        action.payload,
        undefined
      );
    if (!isError) {
      dispatch(getQuizQuestionsByIdSuccess({ data: responseData.data }));
      done();
    } else {
      dispatch(getQuizQuestionsDataByIdFailed());
      done();
    }
  },
});

const updateQuizDataLogic = createLogic({
  type: QuizActions.UPDATE_QUIZ_DATA_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_QUIZ_DATA.service,
      ApiRoutes.UPDATE_QUIZ_DATA.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_QUIZ_DATA.method,
      ApiRoutes.UPDATE_QUIZ_DATA.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(updateQuizDataFailed());
      showError(messages[0]);
      done();
    }
    dispatch(updateQuizDataSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const deleteQuestionVideoLogic = createLogic({
  type: QuizActions.DELETE_QUESTION_VIDEO_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_QUESTION_VIDEO.service,
      ApiRoutes.DELETE_QUESTION_VIDEO.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_QUESTION_VIDEO.method,
      ApiRoutes.DELETE_QUESTION_VIDEO.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(deleteQuestionVideoFailed());
      showError(messages[0]);
      done();
    }
    dispatch(deleteQuestionVideoSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const updateQuizDataOrderLogic = createLogic({
  type: QuizActions.UPDATE_QUIZ_DATA_ORDER_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_QUIZ_DATA_ORDER.service,
      ApiRoutes.UPDATE_QUIZ_DATA_ORDER.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_QUIZ_DATA_ORDER.method,
      ApiRoutes.UPDATE_QUIZ_DATA_ORDER.authenticate,
      undefined,
      action.payload.values
    );
    if (isError) {
      dispatch(updateQuizDataOrderFailed());
      showError(messages[0]);
      done();
    }
    dispatch(updateQuizDataOrderSuccess());
    showSuccess(messages[0]);
    done();
  },
});

const addNewQuizParagraphLogic = createLogic({
  type: QuizActions.REQUEST_ADD_NEW_QUIZZES_PARAGRAPH,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_NEW_QUIZZES_PARAGRAPH.service,
      ApiRoutes.ADD_NEW_QUIZZES_PARAGRAPH.url,
      ApiRoutes.ADD_NEW_QUIZZES_PARAGRAPH.method,
      ApiRoutes.ADD_NEW_QUIZZES_PARAGRAPH.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addNewQuizParagraphSuccess({ data: responseData.data }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(
        addNewQuizParagraphFailed({
          validationErrors,
        })
      );
      done();
    }
  },
});

/**
 -------------------------
    DELETE Quiz Question
 -------------------------
 */
const deleteQuizQuestionLogic = createLogic({
  type: QuizActions.REQUEST_DELETE_QUIZ_QUESTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_QUIZ_QUESTION.service,
      ApiRoutes.DELETE_QUIZ_QUESTION.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_QUIZ_QUESTION.method,
      ApiRoutes.DELETE_QUIZ_QUESTION.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(deleteQuizQuestionFailed());
      done();
    }
    dispatch(deleteQuizQuestionSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const QuizLogics = [
  getQuizQuestionsLogic,
  updateQuizDataLogic,
  updateQuizDataOrderLogic,
  addNewQuizParagraphLogic,
  deleteQuizQuestionLogic,
  deleteQuestionVideoLogic,
];
