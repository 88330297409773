export const EssayInitialStates = {
  isLoading: false,
  isEssayLoading: true,
  isAdded: false,
  isUpdated: false,
  error: false,
  isSubmitting: false,
  essayData: [],
  essay: {},
  totalRecords: 0,
};
