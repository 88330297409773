/** @format */

export const StudentInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  lastVisible: {},
  firstVisible: {},
  subscriptiondata: [],
};
