/** @format */

export const GeoGraphicsInitialStates = {
  isLoading: false,
  countriesOptions: [],
  physicalCountiesOptions: [],
  mailingCountiesOptions: [],
  statesOptions: [],
  citiesOptions: [],
};
