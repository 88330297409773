import { handleActions } from 'redux-actions';
import { SchoolActions } from '../actions';
import { SchoolInitialStates } from '../states';

export const SchoolReducer = handleActions(
  {
    [SchoolActions.ADD_SCHOOL_REQUEST]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
    }),
    [SchoolActions.ADD_SCHOOL_SUCCESS]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [SchoolActions.ADD_SCHOOL_FAILED]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: action.payload.isAdded,
      error: true,
    }),
    [SchoolActions.SCHOOL_LIST_REQUEST]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SchoolActions.SCHOOL_LIST_SUCCESS]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      school: action.payload.school,
      totalRecords: action.payload.totalRecords,
    }),
    [SchoolActions.SCHOOL_LIST_FAILED]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [SchoolActions.UPDATE_SCHOOL_REQUEST]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
    }),
    [SchoolActions.UPDATE_SCHOOL_SUCCESS]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [SchoolActions.UPDATE_SCHOOL_FAILED]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [SchoolActions.VIEW_SCHOOL_REQUEST]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SchoolActions.VIEW_SCHOOL_SUCCESS]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      schoolData: action.payload.schoolData,
      subscriptiondata: action.payload.subscriptiondata,
      taxDetails: action.payload.taxDetails,
    }),
    [SchoolActions.VIEW_SCHOOL_FAILED]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [SchoolActions.UPDATE_SCHOOL_STATUS_REQUEST]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [SchoolActions.UPDATE_SCHOOL_STATUS_SUCCESS]: (
      state = SchoolInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
  },
  SchoolInitialStates
);
