import { createLogic } from 'redux-logic';
import {
  DashboardActions,
  getUsersFailed,
  getUsersSuccess,
  getPaidAccountsFailed,
  getPaidAccountsSuccess,
  getSalesFailed,
  getSalesSuccess,
  getSupportsFailed,
  getSupportsSuccess,
  getEssaysFailed,
  getEssaysSuccess,
  getDemoUsersFailed,
  getDemoUsersSuccess,
  getThisYearPaidAccountsSuccess,
  getThisYearPaidAccountsFailed,
  getDistrictDashboardSuccess,
  getDistrictDashboardFailed,
} from '../actions';
import { showError } from '../../helpers/Toast';
import { ApiRoutes } from '../../route';
import { ApiHelper } from '../../helpers';

const getUsersLogic = createLogic({
  type: DashboardActions.GET_USERS_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_USERS.service,
      ApiRoutes.GET_USERS.url,
      ApiRoutes.GET_USERS.method,
      ApiRoutes.GET_USERS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getUsersFailed());
      done();
    }

    dispatch(
      getUsersSuccess({
        usersData: responseData.data,
      })
    );
    done();
  },
});

const getPaidAccountsLogic = createLogic({
  type: DashboardActions.GET_PAID_ACCOUNTS_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PAID_ACCOUNTS.service,
      ApiRoutes.GET_PAID_ACCOUNTS.url,
      ApiRoutes.GET_PAID_ACCOUNTS.method,
      ApiRoutes.GET_PAID_ACCOUNTS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getPaidAccountsFailed());
      done();
    }

    dispatch(
      getPaidAccountsSuccess({
        paidAccountsData: responseData.data,
      })
    );
    done();
  },
});

const getSalesLogic = createLogic({
  type: DashboardActions.GET_SALES_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SALES.service,
      ApiRoutes.GET_SALES.url,
      ApiRoutes.GET_SALES.method,
      ApiRoutes.GET_SALES.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getSalesFailed());
      done();
    }

    dispatch(
      getSalesSuccess({
        salesData: responseData.data,
      })
    );
    done();
  },
});

const getSupportsLogic = createLogic({
  type: DashboardActions.GET_USERS_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUPPORTS_COUNT.service,
      ApiRoutes.GET_SUPPORTS_COUNT.url,
      ApiRoutes.GET_SUPPORTS_COUNT.method,
      ApiRoutes.GET_SUPPORTS_COUNT.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getSupportsFailed());
      done();
    }

    dispatch(
      getSupportsSuccess({
        supportsData: responseData.data,
      })
    );
    done();
  },
});

const getEssaysLogic = createLogic({
  type: DashboardActions.GET_ESSAYS_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ESSAYS.service,
      ApiRoutes.GET_ESSAYS.url,
      ApiRoutes.GET_ESSAYS.method,
      ApiRoutes.GET_ESSAYS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getEssaysFailed());
      done();
    }

    dispatch(
      getEssaysSuccess({
        essaysData: responseData.data,
      })
    );
    done();
  },
});

const getDemoUsersLogic = createLogic({
  type: DashboardActions.GET_DEMO_USERS_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DEMO_USERS.service,
      ApiRoutes.GET_DEMO_USERS.url,
      ApiRoutes.GET_DEMO_USERS.method,
      ApiRoutes.GET_DEMO_USERS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getDemoUsersFailed());
      done();
    }

    dispatch(
      getDemoUsersSuccess({
        demoUsersData: responseData.data,
      })
    );
    done();
  },
});

const getThisYearPaidAccountsLogic = createLogic({
  type: DashboardActions.GET_THIS_YEAR_PAID_ACCOUNTS_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_THIS_YEAR_PAID_ACCOUNTS.service,
      ApiRoutes.GET_THIS_YEAR_PAID_ACCOUNTS.url,
      ApiRoutes.GET_THIS_YEAR_PAID_ACCOUNTS.method,
      ApiRoutes.GET_THIS_YEAR_PAID_ACCOUNTS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getThisYearPaidAccountsFailed());
      done();
    }

    dispatch(
      getThisYearPaidAccountsSuccess({
        thisYearPaidAccountsData: responseData.data,
      })
    );
    done();
  },
});

const getDistrictDashboardLogic = createLogic({
  type: DashboardActions.GET_DISTRICT_DASHBOARD_REQUEST,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_DASHBOARD.service,
      ApiRoutes.GET_DISTRICT_DASHBOARD.url,
      ApiRoutes.GET_DISTRICT_DASHBOARD.method,
      ApiRoutes.GET_DISTRICT_DASHBOARD.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      showError(messages[0]);
      dispatch(getDistrictDashboardFailed());
      done();
    }
 if(responseData){
    dispatch(
      getDistrictDashboardSuccess({
        DistrictDashboardData: responseData.data,
      })
    );
 }
    done();
  },
});

export const DashboardLogics = [
  getUsersLogic,
  getPaidAccountsLogic,
  getSalesLogic,
  getSupportsLogic,
  getEssaysLogic,
  getDemoUsersLogic,
  getThisYearPaidAccountsLogic,
  getDistrictDashboardLogic,
];
