import { createAction } from 'redux-actions';

export const SubscriptionPlanActions = {
  REQUEST_SUBSCRIPTIONPLAN_LIST: 'Request to fetch subscription plan',
  SUBSCRIPTIONPLAN_LIST_SUCCESS: 'Subscription plan fetched successfully',
  SUBSCRIPTIONPLAN_LIST_FAILURE: 'Failed to fetched subscription plan',

  UPDATE_SUBSCRIPTIONPLAN_REQUEST: 'Request to update subscription plan',
  UPDATE_SUBSCRIPTIONPLAN_SUCCESS: 'Subscription plan updated successfully',
  UPDATE_SUBSCRIPTIONPLAN_FAILURE: 'Failed to update subscription plan',
};

// List subscription plan actions
export const requestSubscriptionPlan = createAction(
  SubscriptionPlanActions.REQUEST_SUBSCRIPTIONPLAN_LIST
);
export const SubscriptionPlanSuccess = createAction(
  SubscriptionPlanActions.SUBSCRIPTIONPLAN_LIST_SUCCESS
);
export const SubscriptionPlanFailed = createAction(
  SubscriptionPlanActions.SUBSCRIPTIONPLAN_LIST_FAILURE
);

// Update subscription plan actions
export const updateSubscriptionPlanRequest = createAction(
  SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_REQUEST
);
export const updateSubscriptionPlanSuccess = createAction(
  SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_SUCCESS
);
export const updateSubscriptionPlanFailed = createAction(
  SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_FAILURE
);
