/** @format */

import { createAction } from 'redux-actions';

export const PrivacyPolicyActions = {
  // get Privacy Policy Record
  GET_PRIVACY_POLICY_REQUEST: 'GetPrivacy Policy Record Request!',
  GET_PRIVACY_POLICY_SUCCESS: 'GetPrivacy Policy Record successfully!.',
  GET_PRIVACY_POLICY_FAILED: 'GetPrivacy Policy Record failed!',

  // update Privacy Policy Record
  UPDATE_PRIVACYPOLICY_REQUEST: 'Update Privacy Policy Record Request!',
  UPDATE_PRIVACYPOLICY_SUCCESS: 'Update Privacy Policy Record successfully!.',
  UPDATE_PRIVACYPOLICY_FAILED: 'Update Privacy Policy Record failed!',
};

// Get Privacy Policy actions
export const getPrivacyPolicyRequest = createAction(
  PrivacyPolicyActions.GET_PRIVACY_POLICY_REQUEST
);
export const getPrivacyPolicySuccess = createAction(
  PrivacyPolicyActions.GET_PRIVACY_POLICY_SUCCESS
);
export const getPrivacyPolicyFailed = createAction(
  PrivacyPolicyActions.GET_PRIVACY_POLICY_FAILED
);

// Update Privacy Policy actions
export const updatePrivacyPolicyRequest = createAction(
  PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_REQUEST
);
export const updatePrivacyPolicySuccess = createAction(
  PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_SUCCESS
);
export const updatePrivacyPolicyFailed = createAction(
  PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_FAILED
);
