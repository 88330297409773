import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';
import {
  TransactionActions,
  transactionListSuccess,
  transactionListFailed,
  userListingSuccess,
  userListingFailed,
  addOfflineTransactionFailed,
  addOfflineTransactionSuccess,
  totalSalesSuccess,
  totalSalesFailed,
  FreeDemoRegisterFailed,
  FreeDemoRegisterSuccess,
} from '../actions';

const getTransactionLogic = createLogic({
  type: TransactionActions.TRANSACTION_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TRANSACTIONS.service,
      ApiRoutes.GET_TRANSACTIONS.url,
      ApiRoutes.GET_TRANSACTIONS.method,
      ApiRoutes.GET_TRANSACTIONS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
       if (action.payload.search !== '') {
         showError('Invalid search');
       } else {
         showError(messages[0]);
       }
      dispatch(transactionListFailed());
      done();
    }

    dispatch(
      transactionListSuccess({
        transactions: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

const addOfflineTransactionLogic = createLogic({
  type: TransactionActions.REQUEST_ADD_OFFLINE_TRANSACTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_OFFLINE_TRANSACTIONS.service,
      ApiRoutes.ADD_OFFLINE_TRANSACTIONS.url,
      ApiRoutes.ADD_OFFLINE_TRANSACTIONS.method,
      ApiRoutes.ADD_OFFLINE_TRANSACTIONS.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      showError('Card number incomplete');
      dispatch(addOfflineTransactionFailed());
      done();
    } else {
      showSuccess(messages[0]);
      dispatch(addOfflineTransactionSuccess());
      done();
    }
  },
});

const freeDemoUserTransactionLogic = createLogic({
  type: TransactionActions.REQUEST_DEMO_USER_REGISTER,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.FREE_DEMO_USER_REGISTRATION.service,
      ApiRoutes.FREE_DEMO_USER_REGISTRATION.url,
      ApiRoutes.FREE_DEMO_USER_REGISTRATION.method,
      ApiRoutes.FREE_DEMO_USER_REGISTRATION.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(FreeDemoRegisterFailed());
      done();
    } else {
      showSuccess(messages[0]);
      dispatch(FreeDemoRegisterSuccess());
      done();
    }
  },
});

const getTotalSales = createLogic({
  type: TransactionActions.TOTAL_SALES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TOTAL_SALES.service,
      ApiRoutes.GET_TOTAL_SALES.url,
      ApiRoutes.GET_TOTAL_SALES.method,
      ApiRoutes.GET_TOTAL_SALES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(totalSalesFailed());
      done();
    }

    dispatch(
      totalSalesSuccess({
        totalSales: responseData.data,
      })
    );
    done();
  },
});

const getUserListingLogic = createLogic({
  type: TransactionActions.USER_LISTING_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_USER_LISTING.service,
      ApiRoutes.GET_USER_LISTING.url,
      ApiRoutes.GET_USER_LISTING.method,
      ApiRoutes.GET_USER_LISTING.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(userListingFailed());
      done();
    }

    dispatch(
      userListingSuccess({
        users: responseData.data,
        freeDemoUser: responseData.freeDemoUser,
      })
    );
    done();
  },
});

export const TransactionLogics = [
  getTransactionLogic,
  getUserListingLogic,
  freeDemoUserTransactionLogic,
  addOfflineTransactionLogic,
  getTotalSales,
];
