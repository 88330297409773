/** @format */

import { createAction } from 'redux-actions';

export const StudentActions = {
  REQUEST_ADD_STUDENT: 'Request to add student',
  ADD_STUDENT_SUCCESS: 'Student added successfully',
  ADD_STUDENT_FAILURE: 'Failed to add student',
  REQUEST_STUDENTS_LIST: 'Request to fetch students',
  STUDENT_LIST_SUCCESS: 'Students fetched successfully',
  STUDENT_LIST_FAILURE: 'Failed to fetched students',
  UPDATE_STUDENT_REQUEST: 'Request to update student',
  UPDATE_STUDENT_SUCCESS: 'Student updated successfully',
  UPDATE_STUDENT_FAILURE: 'Failed to update student',
  VIEW_STUDENT_REQUEST: 'Request to view student',
  VIEW_STUDENT_SUCCESS: 'Student details fetched successfully',
  VIEW_STUDENT_FAILURE: 'Failed to fetch student details',
  DELETE_STUDENT_REQUEST: 'Request to delete student',
  UPDATE_STUDENT_STATUS_REQUEST: 'Request to update student status',
  UPDATE_STUDENT_STATUS_SUCCESS: 'Student status updated successfully',
};

// Add student actions
export const requestAddStudent = createAction(
  StudentActions.REQUEST_ADD_STUDENT
);
export const addStudentSuccess = createAction(
  StudentActions.ADD_STUDENT_SUCCESS
);
export const addStudentFailed = createAction(
  StudentActions.ADD_STUDENT_FAILURE
);

// List student actions
export const requestStudentList = createAction(
  StudentActions.REQUEST_STUDENTS_LIST
);
export const studentListSuccess = createAction(
  StudentActions.STUDENT_LIST_SUCCESS
);
export const studentListFailed = createAction(
  StudentActions.STUDENT_LIST_FAILURE
);

// Update student actions
export const updateStudentRequest = createAction(
  StudentActions.UPDATE_STUDENT_REQUEST
);
export const updateStudentSuccess = createAction(
  StudentActions.UPDATE_STUDENT_SUCCESS
);
export const updateStudentFailed = createAction(
  StudentActions.UPDATE_STUDENT_FAILURE
);

// View student actions
export const viewStudentRequest = createAction(
  StudentActions.VIEW_STUDENT_REQUEST
);
export const viewStudentSuccess = createAction(
  StudentActions.VIEW_STUDENT_SUCCESS
);
export const viewStudentFailed = createAction(
  StudentActions.VIEW_STUDENT_FAILURE
);

export const deleteStudentRequest = createAction(
  StudentActions.DELETE_STUDENT_REQUEST
);

export const updateStudentStatusRequest = createAction(
  StudentActions.UPDATE_STUDENT_STATUS_REQUEST
);

export const updateStudentStatusSuccess = createAction(
  StudentActions.UPDATE_STUDENT_STATUS_SUCCESS
);
