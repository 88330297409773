/** @format */

import { handleActions } from 'redux-actions';
import { LessonActions } from '../actions';
import { LessonInitialStates } from '../states';
console.log("lessonAction",LessonActions);
export const LessonReducer = handleActions(
  {
    [LessonActions.REQUEST_ADD_LESSON]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: true,
      isAdded: false,
    }),
    [LessonActions.ADD_LESSON_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLessonAdding: false,
      isAdded: true,
      lessonAddedDataID: action.payload.data,
    }),
    [LessonActions.ADD_LESSON_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isLessonAdding: false,
      validationErrors: action.payload.validationErrors,
    }),
    [LessonActions.REQUEST_VIEW_LESSON]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: true,
      lessonDataFetched: false,
    }),
    [LessonActions.VIEW_LESSON_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      lessonData: action.payload.data,
      lessonDataFetched: true,
    }),
    [LessonActions.VIEW_LESSON_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      validationErrors: action.payload.validationErrors,
    }),
    [LessonActions.REQUEST_UPDATE_LESSON]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: true,
      isUpdated: false,
    }),
    [LessonActions.UPDATE_LESSON_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: false,
      isUpdated: true,
    }),
    [LessonActions.UPDATE_LESSON_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: false,
      isUpdated: false,
    }),

    [LessonActions.REQUEST_DELETE_LESSON]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: true,
      isDelete: false,
    }),
    [LessonActions.DELETE_LESSON_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: false,
      isDeleted: true,
    }),
    [LessonActions.DELETE_LESSON_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLessonAdding: false,
      isDeleted: false,
    }),

    [LessonActions.DELETE_VIDEO_REQUEST]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      validationErrors: {},
      isLoading: true,
      lessonDataFetched: false,
    }),
    [LessonActions.DELETE_VIDEO_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      lessonData: action.payload.data,
      lessonDataFetched: true,
    }),
    [LessonActions.DELETE_VIDEO_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      validationErrors: action.payload.validationErrors,
    }),

    [LessonActions.ADD_LESSON_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLessonAdding: false,
      isAdded: true,
      lessonAddedDataID: action.payload.data,
    }),
    [LessonActions.ADD_LESSON_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      // isUpdated: false,
      isLessonAdding: false,
    }),

    [LessonActions.REQUEST_LESSON_REDUCER_SET_INITIAL]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: false,
      lessonData: {},
      lessonOptions: [],
    }),
    [LessonActions.REQUEST_LESSON_OPTIONS_LIST]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: true,
      lessonOptions: [],
    }),
    [LessonActions.LESSON_OPTIONS_LIST_SUCCESS]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: false,
      lessonOptions: action.payload.data,
    }),
    [LessonActions.LESSON_OPTIONS_LIST_FAILURE]: (
      state = LessonInitialStates,
      action
    ) => ({
      ...state,
      isOptionLoading: false,
      lessonOptions: [],
    }),
  },
  LessonInitialStates
);
