import { DistrictInitialStates } from '../states';
import { handleActions } from 'redux-actions';
import { DistrictActions } from '../actions';

export const DistrictReducer = handleActions(
  {
    [DistrictActions.ADD_DISTRICT_REQUEST]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [DistrictActions.ADD_DISTRICT_SUCCESS]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),

    [DistrictActions.ADD_DISTRICT_FAILED]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [DistrictActions.DISTRICT_LIST_REQUEST]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      totalRecords: 0,
    }),
    [DistrictActions.DISTRICT_LIST_SUCCESS]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      districts: action.payload.districts,
      totalRecords: action.payload.totalRecords,
    }),
    [DistrictActions.DISTRICT_LIST_FAILED]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [DistrictActions.UPDATE_DISTRICT_RECORD_REQUEST]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [DistrictActions.UPDATE_DISTRICT_RECORD_SUCCESS]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [DistrictActions.UPDATE_DISTRICT_RECORD_FAILED]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [DistrictActions.GET_DISTRICT_RECORD_REQUEST]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [DistrictActions.GET_DISTRICT_RECORD_SUCCESS]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      districtData: action.payload.districtData,
    }),
    [DistrictActions.GET_DISTRICT_RECORD_FAILED]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [DistrictActions.UPDATE_DISTRICT_STATUS_REQUEST]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [DistrictActions.UPDATE_DISTRICT_STATUS_SUCCESS]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
    [DistrictActions.UPDATE_DISTRICT_STATUS_FAILED]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [DistrictActions.DISTRICT_OPTION_LIST_REQUEST]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [DistrictActions.DISTRICT_OPTION_LIST_SUCCESS]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      districtOptions: action.payload.districtOptions,
    }),
    [DistrictActions.DISTRICT_OPTION_LIST_FAILED]: (
      state = DistrictInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  DistrictInitialStates
);
