/** @format */

export const LessonInitialStates = {
  isLessonAdding: false,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
  error: false,
  lessonData: {},
  lessonOptions: [],
  lessonAddedDataID: '',
  lessonDataFetched: false,
  isLoading: false,
};
