/** @format */

import { createAction } from 'redux-actions';

export const LinkageActions = {
  LINKAGE_LIST_REQUEST: 'LINKAGE_LIST_REQUEST',
  LINKAGE_LIST_SUCCESS: 'LINKAGE_LIST_SUCCESS',
  LINKAGE_LIST_FAILED: 'LINKAGE_LIST_FAILED',

  LINKAGE_GENERATE_REQUEST: 'LINKAGE_GENERATE_REQUEST',
  LINKAGE_GENERATE_SUCCESS: 'LINKAGE_GENERATE_SUCCESS',
  LINKAGE_GENERATE_FAILED: 'LINKAGE_GENERATE_FAILED',

  LINKAGE_ATTEMPT_REQUEST: 'LINKAGE_ATTEMPT_REQUEST',
  LINKAGE_ATTEMPT_SUCCESS: 'LINKAGE_ATTEMPT_SUCCESS',
  LINKAGE_ATTEMPT_FAILED: 'LINKAGE_ATTEMPT_FAILED',

  LINKAGE_UNLINK_REQUEST: 'LINKAGE_UNLINK_REQUEST',
  LINKAGE_UNLINK_SUCCESS: 'LINKAGE_UNLINK_SUCCESS',
  LINKAGE_UNLINK_FAILED: 'LINKAGE_UNLINK_FAILED',

  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CANCEL_SUCCESS: 'CANCEL_SUCCESS',
  CANCEL_FAILED: 'CANCEL_FAILED',

  ORGANIZATIONS_REQUEST: 'ORGANIZATIONS_REQUEST',
  ORGANIZATIONS_SUCCESS: 'ORGANIZATIONS_SUCCESS',
  ORGANIZATIONS_FAILED: 'ORGANIZATIONS_FAILED',

  LINKED_ORGANIZATIONS_REQUEST: 'LINKED_ORGANIZATIONS_REQUEST',
  LINKED_ORGANIZATIONS_SUCCESS: 'LINKED_ORGANIZATIONS_SUCCESS',
  LINKED_ORGANIZATIONS_FAILED: 'LINKED_ORGANIZATIONS_FAILED',
};

export const listOrgRequest = createAction(
  LinkageActions.ORGANIZATIONS_REQUEST
);
export const listOrgSuccess = createAction(
  LinkageActions.ORGANIZATIONS_SUCCESS
);
export const listOrgFailed = createAction(LinkageActions.ORGANIZATIONS_FAILED);

export const listLinkageRequest = createAction(
  LinkageActions.LINKAGE_LIST_REQUEST
);
export const listLinkageSuccess = createAction(
  LinkageActions.LINKAGE_LIST_SUCCESS
);
export const listLinkageFailed = createAction(
  LinkageActions.LINKAGE_LIST_FAILED
);

export const generateLinkageRequest = createAction(
  LinkageActions.LINKAGE_GENERATE_REQUEST
);
export const generateLinkageSuccess = createAction(
  LinkageActions.LINKAGE_GENERATE_SUCCESS
);
export const generateLinkageFailed = createAction(
  LinkageActions.LINKAGE_GENERATE_FAILED
);

export const attemptLinkageRequest = createAction(
  LinkageActions.LINKAGE_ATTEMPT_REQUEST
);
export const attemptLinkageSuccess = createAction(
  LinkageActions.LINKAGE_ATTEMPT_SUCCESS
);
export const attemptLinkageFailed = createAction(
  LinkageActions.LINKAGE_ATTEMPT_FAILED
);

export const linkedOrgRequest = createAction(
  LinkageActions.LINKED_ORGANIZATIONS_REQUEST
);
export const linkedOrgSuccess = createAction(
  LinkageActions.LINKED_ORGANIZATIONS_SUCCESS
);
export const linkedOrgFailed = createAction(
  LinkageActions.LINKED_ORGANIZATIONS_FAILED
);

export const unlinkLinkageRequest = createAction(
  LinkageActions.LINKAGE_UNLINK_REQUEST
);
export const unlinkLinkageSuccess = createAction(
  LinkageActions.LINKAGE_UNLINK_SUCCESS
);
export const unlinkLinkageFailed = createAction(
  LinkageActions.LINKAGE_UNLINK_FAILED
);
