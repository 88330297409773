/** @format */

import { createAction } from 'redux-actions';

export const ContactActions = {
  REQUEST_CONTACT_LIST: 'Request to fetch Contact list',
  CONTACT_LIST_SUCCESS: 'Contact list fetched successfully',
  CONTACT_LIST_FAILURE: 'Failed to fetched Contact list',
  VIEW_CONTACT_REQUEST: 'Request to view Contact',
  VIEW_CONTACT_SUCCESS: 'Contact details fetched successfully',
  VIEW_CONTACT_FAILURE: 'Failed to fetch Contact details',
};

// List Contact actions
export const requestContactList = createAction(
  ContactActions.REQUEST_CONTACT_LIST
);
export const successContactList = createAction(
  ContactActions.CONTACT_LIST_SUCCESS
);
export const failedContactList = createAction(
  ContactActions.CONTACT_LIST_FAILURE
);

// View Contact actions
export const requestContactView = createAction(
  ContactActions.VIEW_CONTACT_REQUEST
);
export const successContactView = createAction(
  ContactActions.VIEW_CONTACT_SUCCESS
);
export const failedContactView = createAction(
  ContactActions.VIEW_CONTACT_FAILURE
);
