import { combineReducers } from 'redux';

import { AuthReducer, ResetPasswordReducer } from './Auth';
import { DistrictReducer } from './District';
import { DistrictAdminReducer } from './DistrictAdmin';
import { ModalInfoReducer } from './Modal';
import { ProgramReducer } from './Program';
import { ProgramAdminReducer } from './ProgramAdmin';
import { SchoolReducer } from './School';
import { SectionReducer } from './Section';
import { StudentReducer } from './Student';
import { ProgramSitesReducer } from './ProgramSites';
import { HomePageReducer } from './HomePage';
import { PrivacyPolicyReducer } from './PrivacyPolicy';
import { TermsConditionReducer } from './TermsCondition';
import { GeoGraphicsReducer } from './GeoGraphics';
import { TestReducer } from './Test';
import { UnitReducer } from './Course';
import { LessonReducer } from './Lesson';
import { QuizReducer } from './Quiz';
import { GlobalSettingReducer } from './GlobalSetting';
import { CouponReducer } from './Coupon';
import { BadgesAndCertificatesReducer } from './BadgeAndCertificate';
import { SupportReducer } from './Support';
import { SubscriptionPlanReducer } from './SubscriptionPlan'
import { TransactionReducer } from './Transaction';
import { ReportReducer } from './Report';
import { NotificationReducer } from './Notification';
import { EssayReducer } from './Essay';
import { DashboardReducer } from './Dashboard';
import { LinkageReducer } from './LinkageRequest';
import { ContactReducer } from './Contact';
import { FreeDemoReducer } from './FreeDemo';

export const RootReducer = combineReducers({
  authReducer: AuthReducer,
  resetPasswordReducer: ResetPasswordReducer,
  districtReducer: DistrictReducer,
  districtAdminReducer: DistrictAdminReducer,
  geoGraphicsReducer: GeoGraphicsReducer,
  lessonReducer: LessonReducer,
  modalInfoReducer: ModalInfoReducer,
  programReducer: ProgramReducer,
  programAdminReducer: ProgramAdminReducer,
  programSitesReducer: ProgramSitesReducer,
  schoolReducer: SchoolReducer,
  studentReducer: StudentReducer,
  homePageReducer: HomePageReducer,
  privacyPolicyReducer: PrivacyPolicyReducer,
  sectionReducer: SectionReducer,
  termsConditionReducer: TermsConditionReducer,
  testReducer: TestReducer,
  unitReducer: UnitReducer,
  quizReducer: QuizReducer,
  globalSettingReducer: GlobalSettingReducer,
  couponReducer: CouponReducer,
  BadgesAndCertificatesReducer: BadgesAndCertificatesReducer,
  supportReducer: SupportReducer,
  SubscriptionPlanReducer: SubscriptionPlanReducer,
  transactionReducer: TransactionReducer,
  reportReducer: ReportReducer,
  notificationReducer: NotificationReducer,
  essayReducer: EssayReducer,
  dashboardReducer: DashboardReducer,
  linkageReducer: LinkageReducer,
  contactReducer: ContactReducer,
  freeDemoReducer: FreeDemoReducer,
});
