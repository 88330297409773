/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';
import {
  ProgramSitesActions,
  programSiteAddSuccess,
  programSiteAddFailed,
  programSiteListFailed,
  programSiteListSuccess,
  updateProgramSiteSuccess,
  updateProgramSiteFailed,
  viewProgramSiteSuccess,
  viewProgramSiteFailed,
  requestProgramSiteList,
  updateProgramSiteStatusSuccess,
} from '../actions';

/** @format */

const addProgramSiteLogic = createLogic({
  type: ProgramSitesActions.ADD_PROGRAM_SITES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const responseData = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PROGRAM_SITE.service,
      ApiRoutes.ADD_PROGRAM_SITE.url,
      ApiRoutes.ADD_PROGRAM_SITE.method,
      ApiRoutes.ADD_PROGRAM_SITE.authenticate,
      undefined,
      action.payload
    );
    const { isError, messages } = responseData;
    if (!isError) {
      dispatch(programSiteAddSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(
        programSiteAddFailed({
          isAdded:
            messages[0] ===
            'Program sites added successfully, but having error while sending email.',
        })
      );
      done();
    }
  },
});
/**
 *
 */
const getProgramSitesLogic = createLogic({
  type: ProgramSitesActions.PROGRAM_SITES_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROGRAM_SITES.service,
      ApiRoutes.GET_PROGRAM_SITES.url,
      ApiRoutes.GET_PROGRAM_SITES.method,
      ApiRoutes.GET_PROGRAM_SITES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      if (action.payload.search !== '') {
        showError('Invalid search');
      } else {
        showError(messages[0]);
      }
      dispatch(programSiteListFailed());
      done();
    }

    dispatch(
      programSiteListSuccess({
        programSites: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});
/**
 *
 */
const viewProgramSiteLogic = createLogic({
  type: ProgramSitesActions.VIEW_PROGRAM_SITES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_PROGRAM_SITE.service,
      ApiRoutes.VIEW_PROGRAM_SITE.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_PROGRAM_SITE.method,
      ApiRoutes.VIEW_PROGRAM_SITE.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(viewProgramSiteFailed());
      done();
    }

    dispatch(
      viewProgramSiteSuccess({
        programSiteData: responseData.data,
        subscriptiondata: responseData.subscriptionDetails,
        taxDetails: responseData.taxDetails,
      })
    );
    done();
  },
});
/**
 *
 */
const updateProgramSitesLogic = createLogic({
  type: ProgramSitesActions.UPDATE_PROGRAM_SITES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROGRAM_SITE.service,
      ApiRoutes.UPDATE_PROGRAM_SITE.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_PROGRAM_SITE.method,
      ApiRoutes.UPDATE_PROGRAM_SITE.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateProgramSiteSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateProgramSiteFailed());
      done();
    }
  },
});

/**
 *
 */
const deleteProgramSitesLogic = createLogic({
  type: ProgramSitesActions.DELETE_PROGRAM_SITES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_PROGRAM_SITE.service,
      ApiRoutes.DELETE_PROGRAM_SITE.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_PROGRAM_SITE.method,
      ApiRoutes.DELETE_PROGRAM_SITE.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(viewProgramSiteFailed(messages[0]));
      done();
    }
    dispatch(requestProgramSiteList());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

/**
 *
 */
const updateProgramSitesStatusLogic = createLogic({
  type: ProgramSitesActions.UPDATE_PROGRAM_SITES_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROGRAM_SITE_STATUS.service,
      ApiRoutes.UPDATE_PROGRAM_SITE_STATUS.url.replace(
        ':id',
        action.payload.id
      ),
      ApiRoutes.UPDATE_PROGRAM_SITE_STATUS.method,
      ApiRoutes.UPDATE_PROGRAM_SITE_STATUS.authenticate,
      undefined,
      { status: action.payload.status }
    );
    if (isError) {
      dispatch(viewProgramSiteFailed(messages[0]));
      done();
    }
    dispatch(
      requestProgramSiteList({
        ...action.payload.query,
      })
    );
    dispatch(updateProgramSiteStatusSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const ProgramSitesLogics = [
  addProgramSiteLogic,
  getProgramSitesLogic,
  viewProgramSiteLogic,
  updateProgramSitesLogic,
  deleteProgramSitesLogic,
  updateProgramSitesStatusLogic,
];
