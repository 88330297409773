/** @format */

import { createAction } from 'redux-actions';

export const LessonActions = {
  REQUEST_ADD_LESSON: 'Request to add lesson',
  ADD_LESSON_SUCCESS: 'Lesson added successfully',
  ADD_LESSON_FAILURE: 'Failed to add lesson',
  REQUEST_UPDATE_LESSON: 'Request to update lesson',
  UPDATE_LESSON_SUCCESS: 'Lesson updated successfully',
  UPDATE_LESSON_FAILURE: 'Failed to update lesson',
  REQUEST_DELETE_LESSON: 'Request to delete lesson',
  DELETE_LESSON_SUCCESS: 'Lesson deleted successfully',
  DELETE_LESSON_FAILURE: 'Failed to delete lesson',
  REQUEST_VIEW_LESSON: 'Request to view lesson',
  VIEW_LESSON_SUCCESS: 'Lesson details fetched successfully',
  VIEW_LESSON_FAILURE: 'Failed to load lesson detail',
  REQUEST_LESSON_OPTIONS_LIST: 'Request to fetch lesson options list',
  LESSON_OPTIONS_LIST_SUCCESS: 'Section options list fetched successfully.',
  LESSON_OPTIONS_LIST_FAILURE: 'Failed to fetch lesson options list',
  DELETE_VIDEO_REQUEST: 'DELETE_VIDEO_REQUEST',
  DELETE_VIDEO_SUCCESS: 'DELETE_VIDEO_SUCCESS',
  DELETE_VIDEO_FAILURE: 'DELETE_VIDEO_FAILURE',
  REQUEST_DELETE_LESSONS: 'REQUEST_DELETE_LESSONS',
  SUCCESS_DELETE_LESSONS: 'SUCCESS_DELETE_LESSONS',
  FAILS_DELETE_LESSONS: 'FAILS_DELETE_LESSONS',
  REQUEST_LESSON_REDUCER_SET_INITIAL: 'Lesson reducer reset successfully',
};

// Add lesson actions
export const requestAddLesson = createAction(LessonActions.REQUEST_ADD_LESSON);
export const addLessonSuccess = createAction(LessonActions.ADD_LESSON_SUCCESS);
export const addLessonFailed = createAction(LessonActions.ADD_LESSON_FAILURE);

export const requestViewLesson = createAction(
  LessonActions.REQUEST_VIEW_LESSON
);
export const viewLessonSuccess = createAction(
  LessonActions.VIEW_LESSON_SUCCESS
);
export const viewLessonFailed = createAction(LessonActions.VIEW_LESSON_FAILURE);

export const updateLessonRequest = createAction(
  LessonActions.REQUEST_UPDATE_LESSON
);
export const updateLessonSuccess = createAction(
  LessonActions.UPDATE_LESSON_SUCCESS
);
export const updateLessonFailed = createAction(
  LessonActions.UPDATE_LESSON_FAILURE
);

export const deleteVideoRequest = createAction(
  LessonActions.DELETE_VIDEO_REQUEST
);
export const deleteVideoSuccess = createAction(
  LessonActions.DELETE_VIDEO_SUCCESS
);
export const deleteVideoFailed = createAction(
  LessonActions.DELETE_VIDEO_FAILURE
);

export const requestLessonOptionsList = createAction(
  LessonActions.REQUEST_LESSON_OPTIONS_LIST
);
export const lessonOptionsListSuccess = createAction(
  LessonActions.LESSON_OPTIONS_LIST_SUCCESS
);
export const lessonOptionsListFailed = createAction(
  LessonActions.LESSON_OPTIONS_LIST_FAILURE
);

export const requestLessonReducerSetInitial = createAction(
  LessonActions.REQUEST_LESSON_REDUCER_SET_INITIAL
);

export const requestDeleteLessons = createAction(
  LessonActions.REQUEST_DELETE_LESSONS
);
export const successDeleteLessons = createAction(LessonActions.SUCCESS_DELETE_LESSONS);
export const failedDeleteLessons = createAction(LessonActions.FAILS_DELETE_LESSONS);