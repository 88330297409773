/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import {
  ProgramAdminActions,
  programAdminListFailed,
  programAdminListSuccess,
  updateProgramAdminSuccess,
  updateProgramAdminFailed,
  requestProgramAdminList,
  programAdminaddFailed,
  programAdminaddSuccess,
  viewProgramAdminFailed,
  viewProgramAdminSuccess,
} from '../actions';
import { toast } from 'react-toastify';
import { showError, showSuccess } from '../../helpers/Toast';

/** @format */

const addProgramAdminLogic = createLogic({
  type: ProgramAdminActions.ADD_PROGRAM_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PROGRAM_ADMIN.service,
      ApiRoutes.ADD_PROGRAM_ADMIN.url,
      ApiRoutes.ADD_PROGRAM_ADMIN.method,
      ApiRoutes.ADD_PROGRAM_ADMIN.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(programAdminaddSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(programAdminaddFailed());
      done();
    }
  },
});
/**
 *
 */
const getProgramAdminsLogic = createLogic({
  type: ProgramAdminActions.PROGRAM_ADMIN_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_PROGRAM_ADMINS.service,
        ApiRoutes.GET_PROGRAM_ADMINS.url,
        ApiRoutes.GET_PROGRAM_ADMINS.method,
        ApiRoutes.GET_PROGRAM_ADMINS.authenticate,
        action.payload,
        undefined
      );
    if (isError) {
      dispatch(programAdminListFailed());
      done();
    } else {
      dispatch(
        programAdminListSuccess({
          programAdmins: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
    }
    done();
  },
});
/**
 *
 */
const viewProgramAdminLogic = createLogic({
  type: ProgramAdminActions.VIEW_PROGRAM_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_PROGRAM_ADMIN.service,
      ApiRoutes.VIEW_PROGRAM_ADMIN.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_PROGRAM_ADMIN.method,
      ApiRoutes.VIEW_PROGRAM_ADMIN.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(viewProgramAdminFailed());
      done();
    }

    dispatch(
      viewProgramAdminSuccess({
        programAdminData: responseData.data,
      })
    );
    done();
  },
});
/**
 *
 */
const updateProgramAdminLogic = createLogic({
  type: ProgramAdminActions.UPDATE_PROGRAM_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROGRAM_PROFILE.service,
      ApiRoutes.UPDATE_PROGRAM_PROFILE.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_PROGRAM_PROFILE.method,
      ApiRoutes.UPDATE_PROGRAM_PROFILE.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateProgramAdminSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateProgramAdminFailed());
      done();
    }
  },
});

/**
 *
 */
const deleteProgramAdminLogic = createLogic({
  type: ProgramAdminActions.DELETE_PROGRAM_ADMIN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_PROGRAM_ADMIN.service,
      ApiRoutes.DELETE_PROGRAM_ADMIN.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_PROGRAM_ADMIN.method,
      ApiRoutes.DELETE_PROGRAM_ADMIN.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      toast.error(messages[0]);
      done();
    }
    dispatch(requestProgramAdminList());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

// /**
//  *
//  */
// const updateStudentStatusLogic = createLogic({
//   type: StudentActions.UPDATE_STUDENT_STATUS_REQUEST,
//   async process(data, dispatch, done) {
//     const { action } = data;
//     const {
//       isError,
//       messages,
//       data: responseData,
//     } = await new ApiHelper().FetchFromServer(
//       ApiRoutes.UPDATE_STUDENT_STATUS.service,
//       ApiRoutes.UPDATE_STUDENT_STATUS.url.replace(':id', action.payload.id),
//       ApiRoutes.UPDATE_STUDENT_STATUS.method,
//       ApiRoutes.UPDATE_STUDENT_STATUS.authenticate,
//       undefined,
//       { status: action.payload.status },
//     );
//     if (isError) {
//       dispatch(viewStudentFailed(messages[0]));
//       done();
//     }
//     dispatch(requestStudentList());
//     dispatch(messages[0]);
//     done();
//   },
// });

export const ProgramAdminLogics = [
  addProgramAdminLogic,
  getProgramAdminsLogic,
  viewProgramAdminLogic,
  updateProgramAdminLogic,
  deleteProgramAdminLogic,
];
