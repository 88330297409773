/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers';
import { ApiRoutes } from '../../route';
import {
  counitesListSuccess,
  counitesListFailed,
  GeoGraphicsActions,
  statesListFailed,
  statesListSuccess,
  citiesListSuccess,
  countriesListFailed,
  countriesListSuccess,
  citiesListFailed,
} from '../actions';

/**
 *
 */
const getCountriesLogic = createLogic({
  type: GeoGraphicsActions.REQUEST_COUNTRIES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_COUNTRIES.service,
      ApiRoutes.GET_COUNTRIES.url,
      ApiRoutes.GET_COUNTRIES.method,
      ApiRoutes.GET_COUNTRIES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(countriesListFailed());
      done();
    }

    dispatch(
      countriesListSuccess({
        countries: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const getCountiesLogic = createLogic({
  type: GeoGraphicsActions.REQUEST_COUNTIES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_COUNTIES.service,
      ApiRoutes.GET_COUNTIES.url,
      ApiRoutes.GET_COUNTIES.method,
      ApiRoutes.GET_COUNTIES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(counitesListFailed());
      done();
    }

    dispatch(
      counitesListSuccess({
        [action.payload.name]: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const getStatesLogic = createLogic({
  type: GeoGraphicsActions.REQUEST_STATES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STATES.service,
      ApiRoutes.GET_STATES.url,
      ApiRoutes.GET_STATES.method,
      ApiRoutes.GET_STATES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(statesListFailed());
      done();
    }

    dispatch(
      statesListSuccess({
        states: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const getCitiesLogic = createLogic({
  type: GeoGraphicsActions.REQUEST_CITIES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CITIES.service,
      ApiRoutes.GET_CITIES.url,
      ApiRoutes.GET_CITIES.method,
      ApiRoutes.GET_CITIES.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(citiesListFailed());
      done();
    }

    dispatch(
      citiesListSuccess({
        cities: responseData.data,
      })
    );
    done();
  },
});

export const GeoGraphicsLogics = [
  getCountriesLogic,
  getCountiesLogic,
  getStatesLogic,
  getCitiesLogic,
];
