/** @format */

export const QuizInitialStates = {
  isLoading: false,
  isQuizLoading: true,
  error: false,
  quizQuestions: [],
  isSubmitting: false,
  isQuizUpdated: false,
  isQuizDataOrderUpdated: false,
  isParagraphAdded: false,
  isQuestionDeleted: false,
  quizDataFetched: false,
};
