/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';
import {
  addSectionSuccess,
  addSectionFailed,
  LessonActions,
  requestSectionListByUnit,
  addLessonSuccess,
  addLessonFailed,
  viewLessonSuccess,
  lessonOptionsListSuccess,
  lessonOptionsListFailed,
  updateLessonSuccess,
  updateLessonFailed,
  deleteVideoSuccess,
  deleteVideoFailed,
  successDeleteLessons,
  failedDeleteLessons,
  requestUnitList,
} from '../actions';

const addLessonLogic = createLogic({
  type: LessonActions.REQUEST_ADD_LESSON,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_LESSON.service,
      ApiRoutes.ADD_LESSON.url,
      ApiRoutes.ADD_LESSON.method,
      ApiRoutes.ADD_LESSON.authenticate,
      undefined,
      action.payload
    );

    if (!isError) {
      dispatch(addLessonSuccess({ data: responseData.data._id }));
      showSuccess(messages[0]);
      // dispatch(redirectTo({ path: AppRoutes.UNITS }));
      done();
    } else {
      showError(messages[0]);
      dispatch(
        addLessonFailed({
          validationErrors,
        })
      );
      done();
    }
  },
});

const updateLessonLogic = createLogic({
  type: LessonActions.REQUEST_UPDATE_LESSON,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages, validationErrors } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.UPDATE_LESSON.service,
        ApiRoutes.UPDATE_LESSON.url.replace(':id', action.payload.id),
        ApiRoutes.UPDATE_LESSON.method,
        ApiRoutes.UPDATE_LESSON.authenticate,
        undefined,
        action.payload.values
      );
    if (!isError) {
      dispatch(requestSectionListByUnit({ id: action.payload.values.unitID }));
      dispatch(addSectionSuccess());
      dispatch(updateLessonSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateLessonFailed());
      dispatch(
        addSectionFailed({
          validationErrors,
        })
      );
      done();
    }
  },
});

const deleteVideoLogic = createLogic({
  type: LessonActions.DELETE_VIDEO_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_LESSON_VIDEO.service,
      ApiRoutes.DELETE_LESSON_VIDEO.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_LESSON_VIDEO.method,
      ApiRoutes.DELETE_LESSON_VIDEO.authenticate,
      undefined,
      action.payload.values
    );

    if (!isError) {
      dispatch(deleteVideoSuccess({ data: responseData.data }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(deleteVideoFailed({ validationErrors }));
      done();
    }
  },
});

const viewLessonLogic = createLogic({
  type: LessonActions.REQUEST_VIEW_LESSON,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.VIEW_LESSON.service,
        ApiRoutes.VIEW_LESSON.url.replace(':id', action.payload.id),
        ApiRoutes.VIEW_LESSON.method,
        ApiRoutes.VIEW_LESSON.authenticate,
        undefined,
        action.payload
      );
    if (!isError) {
      dispatch(viewLessonSuccess({ data: responseData.data }));
      done();
    } else {
      done();
    }
  },
});

const listLessonLogic = createLogic({
  type: LessonActions.REQUEST_LESSON_OPTIONS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.LESSON_OPTIONS_LIST.service,
        ApiRoutes.LESSON_OPTIONS_LIST.url,
        ApiRoutes.LESSON_OPTIONS_LIST.method,
        ApiRoutes.LESSON_OPTIONS_LIST.authenticate,
        action.payload,
        undefined
      );
    if (!isError) {
      dispatch(lessonOptionsListSuccess({ data: responseData.data }));
      done();
    } else {
      dispatch(lessonOptionsListFailed());
      done();
    }
  },
});

const deleteLessonLogic = createLogic({
  type: LessonActions.REQUEST_DELETE_LESSONS,
  async process(data, dispatch, done) {
    const { action } = data;
    console.log(action.payload, 'action.payload.type');
    let serviceAndUrl = {
          service : ApiRoutes.DELETE_LESSONS.service,
          url: ApiRoutes.DELETE_LESSONS.url.replace(':id', action.payload.id)
    }
    if(action.payload.type === "unit"){
      serviceAndUrl = {
        service: ApiRoutes.DELETE_UNIT.service,
        url: ApiRoutes.DELETE_UNIT.url.replace(':id', action.payload.id),
      };
    }
    if (action.payload.type === 'section') {
      serviceAndUrl = {
        service: ApiRoutes.DELETE_SECTIONS.service,
        url:  ApiRoutes.DELETE_SECTIONS.url.replace(':id', action.payload.id),
      };
    }
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      serviceAndUrl.service,
      serviceAndUrl.url,
      ApiRoutes.DELETE_LESSONS.method,
      ApiRoutes.DELETE_LESSONS.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(requestUnitList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(failedDeleteLessons({ validationErrors }));
      done();
    }
  },
});

export const LessonLogics = [
  addLessonLogic,
  updateLessonLogic,
  viewLessonLogic,
  listLessonLogic,
  deleteVideoLogic,
  deleteLessonLogic,
];
