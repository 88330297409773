/** @format */

export const DistrictAdminInitialStates = {
  isLoading: false,
  isAdded: false,
  isSubmitting: false,
  error: false,
  districtAdmins: [],
  districtAdminData: {},
  totalRecords: 0,
};
