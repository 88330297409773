/** @format */

export const SectionInitialStates = {
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
  error: false,
  sectionOptions: [],
};
