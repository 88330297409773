/** @format */

import { createLogic } from 'redux-logic';
import {
  ReportActions,
  studentLoginHistoryListSuccess,
  studentLoginHistoryListFailed,
  studentTestCompletionListSuccess,
  studentTestCompletionListFailed,
  reportStudentListSuccess,
  reportStudentListFailed,
  reportSchoolListSuccess,
  reportSchoolListFailed,
  reportProgramSiteListSuccess,
  reportProgramSiteListFailed,
  reportDistrictListSuccess,
  reportDistrictListFailed,
  reportProgramListSuccess,
  reportProgramListFailed,
  districtStudentTestCompletionListFailed,
  districtStudentTestCompletionListSuccess,
  linkedOrganizationListSuccess,
  linkedOrganizationListFailed,
  studentScoreComparisonListFailed,
  studentScoreComparisonListSuccess,
  districtStudentScoreComparisonListFailed,
  districtStudentScoreComparisonListSuccess,
  studentActPerformanceListSuccess,
  studentActPerformanceListFailed,
  districtStudentActPerformanceListSuccess,
  districtStudentActPerformanceListFailed,
  studentQuizProgressListSuccess,
  studentQuizProgressListFailed,
  districtStudentQuizProgressListSuccess,
  districtStudentQuizProgressListFailed,
  studentActDetailedListSuccess,
  studentActDetailedListFailed,
  districtStudentActDetailedListSuccess,
  districtStudentActDetailedListFailed,
  studentCourseProgressOverviewListSuccess,
  studentCourseProgressOverviewListFailed,
  districtStudentCourseProgressOverviewListSuccess,
  districtStudentCourseProgressOverviewListFailed,
  studentActScoreProgressOverviewListSuccess,
  studentActScoreProgressOverviewListFailed,
  districtStudentActScoreProgressOverviewListSuccess,
  districtStudentActScoreProgressOverviewListFailed,
  studentQuizScoreProgressOverviewListSuccess,
  studentQuizScoreProgressOverviewListFailed,
  districtStudentQuizScoreProgressOverviewListSuccess,
  districtStudentQuizScoreProgressOverviewListFailed,
} from '../actions';
import { showError } from '../../helpers/Toast';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';

/**
 *
 */
const getStudentLoginHistoryLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_LOGIN_HISTORY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_LOGIN_HISTORY.service,
      ApiRoutes.GET_STUDENT_LOGIN_HISTORY.url,
      ApiRoutes.GET_STUDENT_LOGIN_HISTORY.method,
      ApiRoutes.GET_STUDENT_LOGIN_HISTORY.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentLoginHistoryListFailed());
      done();
    }
     if(responseData){
    dispatch(
      studentLoginHistoryListSuccess({
        UserList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

/**
 *
 */
const getStudentTestCompletionLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_TEST_COMPLETION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_TEST_COMPLETION.service,
      ApiRoutes.GET_STUDENT_TEST_COMPLETION.url,
      ApiRoutes.GET_STUDENT_TEST_COMPLETION.method,
      ApiRoutes.GET_STUDENT_TEST_COMPLETION.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentTestCompletionListFailed());
      done();
    }
     if(responseData){
    dispatch(
      studentTestCompletionListSuccess({
        studentTestReportList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

/**
 *
 */
const getStudentListLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_LIST.service,
      ApiRoutes.GET_STUDENT_LIST.url,
      ApiRoutes.GET_STUDENT_LIST.method,
      ApiRoutes.GET_STUDENT_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(reportStudentListFailed());
      done();
    }
    console.log('responseData', responseData);
     if(responseData){
    dispatch(
      reportStudentListSuccess({
        studentList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

const getSchoolListLogic = createLogic({
  type: ReportActions.REQUEST_SCHOOLS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SCHOOL_LIST.service,
      ApiRoutes.GET_SCHOOL_LIST.url,
      ApiRoutes.GET_SCHOOL_LIST.method,
      ApiRoutes.GET_SCHOOL_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(reportSchoolListFailed());
      done();
    }
     if(responseData){
    dispatch(
      reportSchoolListSuccess({
        schoolList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

const getProgramSiteListLogic = createLogic({
  type: ReportActions.REQUEST_PROGRAM_SITE_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROGRAM_SITE_LIST.service,
      ApiRoutes.GET_PROGRAM_SITE_LIST.url,
      ApiRoutes.GET_PROGRAM_SITE_LIST.method,
      ApiRoutes.GET_PROGRAM_SITE_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(reportProgramSiteListFailed());
      done();
    }
     if(responseData){
    dispatch(
      reportProgramSiteListSuccess({
        programsiteList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

const getDistrictListLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_LIST.service,
      ApiRoutes.GET_DISTRICT_LIST.url,
      ApiRoutes.GET_DISTRICT_LIST.method,
      ApiRoutes.GET_DISTRICT_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(reportDistrictListFailed());
      done();
    }
     if(responseData){
    dispatch(
      reportDistrictListSuccess({
        districtList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

const getProgramListLogic = createLogic({
  type: ReportActions.REQUEST_PROGRAM_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROGRAM_LIST.service,
      ApiRoutes.GET_PROGRAM_LIST.url,
      ApiRoutes.GET_PROGRAM_LIST.method,
      ApiRoutes.GET_PROGRAM_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(reportProgramListFailed());
      done();
    }
     if(responseData){
    dispatch(
      reportProgramListSuccess({
        programList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
     }
    done();
  },
});

/**
 *
 */
const getDistrictStudentTestCompletionLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_TEST_COMPLETION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_STUDENT_TEST_COMPLETION.service,
      ApiRoutes.GET_DISTRICT_STUDENT_TEST_COMPLETION.url,
      ApiRoutes.GET_DISTRICT_STUDENT_TEST_COMPLETION.method,
      ApiRoutes.GET_DISTRICT_STUDENT_TEST_COMPLETION.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentTestCompletionListFailed());
      done();
    }
    if(responseData){
    dispatch(
      districtStudentTestCompletionListSuccess({
        districtstudentTestReportList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    }
    done();
  },
});

/**
 *
 */
const getLinkedOrganizationLogic = createLogic({
  type: ReportActions.REQUEST_LINKED_ORGANIZATION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_LINKED_ORGANIZATION.service,
      ApiRoutes.GET_LINKED_ORGANIZATION.url,
      ApiRoutes.GET_LINKED_ORGANIZATION.method,
      ApiRoutes.GET_LINKED_ORGANIZATION.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(linkedOrganizationListFailed());
      done();
    }
 if(responseData){
    dispatch(
      linkedOrganizationListSuccess({
        linkedOrganizationList: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
 }
    done();
  },
});

/**
 *
 */
const getStudentScoreComparisonLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_SCORE_COMPARISON_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_SCORE_COMPARISON.service,
      ApiRoutes.GET_STUDENT_SCORE_COMPARISON.url,
      ApiRoutes.GET_STUDENT_SCORE_COMPARISON.method,
      ApiRoutes.GET_STUDENT_SCORE_COMPARISON.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentScoreComparisonListFailed());
      done();
    }
     if(responseData){
    dispatch(
      studentScoreComparisonListSuccess({
        studentScoreComparisonReportList: responseData.data,
        // totalStudent: responseData.totalStudent,
      })
    );
     }

    done();
  },
});

/**
 *
 */
const getDistrictStudentScoreComparisonLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_SCORE_COMPARISON_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_STUDENT_SCORE_COMPARISON.service,
      ApiRoutes.GET_DISTRICT_STUDENT_SCORE_COMPARISON.url,
      ApiRoutes.GET_DISTRICT_STUDENT_SCORE_COMPARISON.method,
      ApiRoutes.GET_DISTRICT_STUDENT_SCORE_COMPARISON.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentScoreComparisonListFailed());
      done();
    }
   if (responseData){
     dispatch(
       districtStudentScoreComparisonListSuccess({
         studentScoreComparisonReportList: responseData.data,
         // totalStudent: responseData.totalStudent,
       })
     );
      }

    done();
  },
});

/**
 *
 */
const getStudentActPerformanceLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_ACT_PERFORMANCE_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.service,
      ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.url,
      ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.method,
      ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentActPerformanceListFailed());
      done();
    }
     if(responseData){
    dispatch(
      studentActPerformanceListSuccess({
        studentReportData: responseData.data,
        totalStudent: responseData.totalStudent,
        testStudent: responseData.testStudent,
      })
    );
     }

    done();
  },
});

/**
 *
 */
const getDistrictStudentActPerformanceLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_PERFORMANCE_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_ACT_PERFORMANCE_REPORT.service,
      ApiRoutes.GET_DISTRICT_ACT_PERFORMANCE_REPORT.url,
      ApiRoutes.GET_DISTRICT_ACT_PERFORMANCE_REPORT.method,
      ApiRoutes.GET_DISTRICT_ACT_PERFORMANCE_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentActPerformanceListFailed());
      done();
    }
if (responseData){
    dispatch(
      districtStudentActPerformanceListSuccess({
        studentReportData: responseData.data,
        totalStudent: responseData.totalStudent,
        testStudent: responseData.testStudent,
      })
    );
}

    done();
  },
});

/**
 *
 */
const getStudentQuizProgressLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_QUIZ_PROGRESS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.service,
      ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.url,
      ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.method,
      ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentQuizProgressListFailed());
      done();
    }
 if(responseData){
    dispatch(
      studentQuizProgressListSuccess({
        studentReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

/**
 *
 */
const getDistrictStudentQuizProgressLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_QUIZ_PROGRESS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_QUIZ_PROGRESS_REPORT.service,
      ApiRoutes.GET_DISTRICT_QUIZ_PROGRESS_REPORT.url,
      ApiRoutes.GET_DISTRICT_QUIZ_PROGRESS_REPORT.method,
      ApiRoutes.GET_DISTRICT_QUIZ_PROGRESS_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentQuizProgressListFailed());
      done();
    }
 if(responseData){
    dispatch(
      districtStudentQuizProgressListSuccess({
        studentReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

// act detailed report
/**
 *
 */
const getStudentActDetailedLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_ACT_DETAILED_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.service,
      ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.url,
      ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.method,
      ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentActDetailedListFailed());
      done();
    }
 if(responseData){
    dispatch(
      studentActDetailedListSuccess({
        studentReportData: responseData.report,
        totalStudent: responseData.totalStudent,
        testStudent: responseData.testStudent,
      })
    );
 }

    done();
  },
});

/**
 *
 */
const getDistrictStudentActDetailedLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_DETAILED_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_ACT_DETAILED_REPORT.service,
      ApiRoutes.GET_DISTRICT_ACT_DETAILED_REPORT.url,
      ApiRoutes.GET_DISTRICT_ACT_DETAILED_REPORT.method,
      ApiRoutes.GET_DISTRICT_ACT_DETAILED_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentActDetailedListFailed());
      done();
    }
     if (responseData){
       dispatch(
         districtStudentActDetailedListSuccess({
           studentReportData: responseData.report,
           totalStudent: responseData.totalStudent,
           testStudent: responseData.testStudent,
         })
       );
        }

    done();
  },
});

// student Course Progress Overview report
/**
 *
 */
const getStudentCourseProgressOverviewLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.service,
      ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.url,
      ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.method,
      ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentCourseProgressOverviewListFailed());
      done();
    }
 if(responseData){
    dispatch(
      studentCourseProgressOverviewListSuccess({
        studentCourseReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

/**
 *
 */
const getDistrictStudentCourseProgressOverviewLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_COURSE_PROGRESS_OVERVIEW_REPORT.service,
      ApiRoutes.GET_DISTRICT_COURSE_PROGRESS_OVERVIEW_REPORT.url,
      ApiRoutes.GET_DISTRICT_COURSE_PROGRESS_OVERVIEW_REPORT.method,
      ApiRoutes.GET_DISTRICT_COURSE_PROGRESS_OVERVIEW_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentCourseProgressOverviewListFailed());
      done();
    }
 if(responseData){
    dispatch(
      districtStudentCourseProgressOverviewListSuccess({
        studentCourseReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

// student act score Progress Overview report
/**
 *
 */
const getStudentActScoreProgressOverviewLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.service,
      ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.url,
      ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.method,
      ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentActScoreProgressOverviewListFailed());
      done();
    }
 if(responseData){
    dispatch(
      studentActScoreProgressOverviewListSuccess({
        studentActScoreReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});
/**
 *
 */
const getDistrictStudentActScoreProgressOverviewLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.service,
      ApiRoutes.GET_DISTRICT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.url,
      ApiRoutes.GET_DISTRICT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.method,
      ApiRoutes.GET_DISTRICT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentActScoreProgressOverviewListFailed());
      done();
    }
 if(responseData){
    dispatch(
      districtStudentActScoreProgressOverviewListSuccess({
        studentActScoreReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

// student quiz progress Progress Overview report
/**
 *
 */
const getStudentQuizScoreProgressOverviewLogic = createLogic({
  type: ReportActions.REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.service,
      ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.url,
      ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.method,
      ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(studentQuizScoreProgressOverviewListFailed());
      done();
    }
 if(responseData){
    dispatch(
      studentQuizScoreProgressOverviewListSuccess({
        studentQuizScoreReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

/**
 *
 */
const getDistrictStudentQuizScoreProgressOverviewLogic = createLogic({
  type: ReportActions.REQUEST_DISTRICT_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISTRICT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.service,
      ApiRoutes.GET_DISTRICT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.url,
      ApiRoutes.GET_DISTRICT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.method,
      ApiRoutes.GET_DISTRICT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(districtStudentQuizScoreProgressOverviewListFailed());
      done();
    }
 if(responseData){
    dispatch(
      districtStudentQuizScoreProgressOverviewListSuccess({
        studentQuizScoreReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      })
    );
 }

    done();
  },
});

export const ReportLogics = [
  getStudentListLogic,
  getSchoolListLogic,
  getProgramSiteListLogic,
  getDistrictListLogic,
  getProgramListLogic,
  getStudentLoginHistoryLogic,
  getStudentTestCompletionLogic,
  getDistrictStudentTestCompletionLogic,
  getLinkedOrganizationLogic,
  getStudentScoreComparisonLogic,
  getDistrictStudentScoreComparisonLogic,
  getStudentActPerformanceLogic,
  getDistrictStudentActPerformanceLogic,
  getStudentQuizProgressLogic,
  getDistrictStudentQuizProgressLogic,
  getStudentActDetailedLogic,
  getDistrictStudentActDetailedLogic,
  getStudentCourseProgressOverviewLogic,
  getDistrictStudentCourseProgressOverviewLogic,
  getStudentActScoreProgressOverviewLogic,
  getDistrictStudentActScoreProgressOverviewLogic,
  getStudentQuizScoreProgressOverviewLogic,
  getDistrictStudentQuizScoreProgressOverviewLogic,
];
