/** @format */

import { createAction } from 'redux-actions';

export const AuthActions = {
  REQUEST_LOGIN: 'Request user login!',
  GET_USER_DETAILS: 'Request to fetch user details',
  USER_DETAILS_SUCCESS: 'User details fetched successfully!',
  USER_DETAILS_FAILED: 'Failed to fetch User details!',
  LOGIN_FAILED: 'Login failed',
  LOGOUT_REQUEST: 'Request logout',
  CHANGE_PASSWORD_REQUEST: 'Request for password change',
  CHANGE_PASSWORD_SUCCESS: 'Password updated successfully',
  CHANGE_PASSWORD_FAILURE: 'Failed to update password',
  LINK_VERIFICATION_REQUEST: 'Request for link verification',
  LINK_VERIFICATION_SUCCESS: 'Link verified successfully',
  LINK_VERIFICATION_FAILURE: 'Failed to verify link',
  SET_PASSWORD_REQUEST: 'Request for set password',
  SET_PASSWORD_SUCCESS: 'Password set successfully',
  SET_PASSWORD_FAILURE: 'Failed to set password',
  REQUEST_UPDATE_PROFILE: 'Request for update profile',
  UPDATE_PROFILE_FAILURE: 'Failed to update profile',
  REQUEST_FORGOT_PASSWORD: 'Request forgot password',
};

export const requestLogin = createAction(AuthActions.REQUEST_LOGIN);
export const requestForgotPassword = createAction(
  AuthActions.REQUEST_FORGOT_PASSWORD
);
export const getUserDetails = createAction(AuthActions.GET_USER_DETAILS);
export const userDetailsSuccess = createAction(
  AuthActions.USER_DETAILS_SUCCESS
);
export const loginFailed = createAction(AuthActions.LOGIN_FAILED);
export const requestLogout = createAction(AuthActions.LOGOUT_REQUEST);
export const changePasswordReq = createAction(
  AuthActions.CHANGE_PASSWORD_REQUEST
);
export const changePasswordSuccess = createAction(
  AuthActions.CHANGE_PASSWORD_SUCCESS
);
export const changePasswordFailed = createAction(
  AuthActions.CHANGE_PASSWORD_FAILURE
);
export const requestUpdateProfile = createAction(
  AuthActions.REQUEST_UPDATE_PROFILE
);
export const updateProfileFailure = createAction(
  AuthActions.UPDATE_PROFILE_FAILURE
);
export const linkVerificationReq = createAction(
  AuthActions.LINK_VERIFICATION_REQUEST
);
export const linkVerificationSuccess = createAction(
  AuthActions.LINK_VERIFICATION_SUCCESS
);
export const linkVerificationFailed = createAction(
  AuthActions.LINK_VERIFICATION_FAILURE
);
export const setPasswordReq = createAction(AuthActions.SET_PASSWORD_REQUEST);
export const setPasswordSuccess = createAction(
  AuthActions.SET_PASSWORD_SUCCESS
);
export const setPasswordFailed = createAction(AuthActions.SET_PASSWORD_FAILURE);
