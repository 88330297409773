/** @format */

import { handleActions } from 'redux-actions';
import { PrivacyPolicyActions } from '../actions';
import { PrivacyPolicyInitialStates } from '../states/PrivacyPolicy';

export const PrivacyPolicyReducer = handleActions(
  {
    [PrivacyPolicyActions.GET_PRIVACY_POLICY_REQUEST]: (
      state = PrivacyPolicyInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [PrivacyPolicyActions.GET_PRIVACY_POLICY_SUCCESS]: (
      state = PrivacyPolicyInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      privacyPolicyData: action.payload.privacyPolicyData,
    }),
    [PrivacyPolicyActions.GET_PRIVACY_POLICY_FAILED]: (
      state = PrivacyPolicyInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_REQUEST]: (
      state = PrivacyPolicyInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isAdded: false,
    }),
    [PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_SUCCESS]: (
      state = PrivacyPolicyInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isAdded: true,
    }),
    [PrivacyPolicyActions.UPDATE_PRIVACYPOLICY_FAILED]: (
      state = PrivacyPolicyInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  PrivacyPolicyInitialStates
);
