import { handleActions } from 'redux-actions';
import { SubscriptionPlanActions } from '../actions/SubscriptionPlan';
import { SubscriptionPlanInitialStates } from '../states/SubscriptionPlan';

export const SubscriptionPlanReducer = handleActions(
  {
    [SubscriptionPlanActions.REQUEST_SUBSCRIPTIONPLAN_LIST]: (
      state = SubscriptionPlanInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SubscriptionPlanActions.SUBSCRIPTIONPLAN_LIST_SUCCESS]: (
      state = SubscriptionPlanInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      IndividualPlans: action.payload.IndividualPlans,
      OrganizationPlans: action.payload.OrganizationPlans,
      totalRecords: action.payload.totalRecords,
    }),
    [SubscriptionPlanActions.SUBSCRIPTIONPLAN_LIST_FAILURE]: (
      state = SubscriptionPlanInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_REQUEST]: (
      state = SubscriptionPlanInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_SUCCESS]: (
      state = SubscriptionPlanInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
    [SubscriptionPlanActions.UPDATE_SUBSCRIPTIONPLAN_FAILURE]: (
      state = SubscriptionPlanInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  SubscriptionPlanInitialStates
);
