/** @format */

import { createLogic } from 'redux-logic';
import { ApiHelper } from '../../helpers/ApiHelper';
import { ApiRoutes } from '../../route';
import { showError, showSuccess } from '../../helpers/Toast';
import {
  addSectionSuccess,
  addSectionFailed,
  SectionActions,
  requestSectionListByUnit,
  unitListSuccess,
  sectionOptionsListSuccess,
  sectionOptionsListFailed,
  deleteSectionSuccess,
  deleteSectionFailed,
  requestUnitList,
} from '../actions';

const addSectionLogic = createLogic({
  type: SectionActions.REQUEST_ADD_SECTION,
  async process(data, dispatch, done) {
    const { action, getState } = data;
    const {
      isError,
      messages,
      validationErrors,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SECTION.service,
      ApiRoutes.ADD_SECTION.url,
      ApiRoutes.ADD_SECTION.method,
      ApiRoutes.ADD_SECTION.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      const { units = [] } = getState().unitReducer;
      const temp = [...units];
      let index = temp.findIndex((unit) => unit._id === action.payload.unitID);
      temp[index] = {
        ...temp[index],
        // sections: [...temp[index].sections, ...responseData.data],
        totalSections: temp[index].totalSections + 1,
        isSectionsLoading: true,
      };
      dispatch(
        unitListSuccess({
          units: temp,
        })
      );
      dispatch(requestSectionListByUnit({ id: action.payload.unitID }));
      dispatch(addSectionSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(
        addSectionFailed({
          validationErrors,
        })
      );
      done();
    }
  },
});

const updateSectionLogic = createLogic({
  type: SectionActions.REQUEST_UPDATE_SECTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      validationErrors,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SECTION.service,
      ApiRoutes.UPDATE_SECTION.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_SECTION.method,
      ApiRoutes.UPDATE_SECTION.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(requestSectionListByUnit({ id: action.payload.values.unitID }));
      dispatch(addSectionSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(
        addSectionFailed({
          validationErrors,
        })
      );
      done();
    }
  },
});

const getSectionOptionsListLogic = createLogic({
  type: SectionActions.REQUEST_SECTION_OPTION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.SECTION_OPTIONS_LIST.service,
      ApiRoutes.SECTION_OPTIONS_LIST.url,
      ApiRoutes.SECTION_OPTIONS_LIST.method,
      ApiRoutes.SECTION_OPTIONS_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(sectionOptionsListFailed());
      done();
    }

    dispatch(
      sectionOptionsListSuccess({
        sectionOptions: responseData.data,
      })
    );
    done();
  },
});

const deleteSections = createLogic({
  type: SectionActions.SECTIONS_DELETE_REQUEST,
  async process(data, dispatch, done) {
     const { action } = data;
    const {
      isError,
      messages,
      validationErrors,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SECTIONS.service,
      ApiRoutes.DELETE_SECTIONS.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_SECTIONS.method,
      ApiRoutes.DELETE_SECTIONS.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(requestUnitList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(deleteSectionFailed({ validationErrors }));
      done();
    }
  },
});

export const SectionLogics = [
  addSectionLogic,
  updateSectionLogic,
  getSectionOptionsListLogic,
  deleteSections,
];
