import { createLogic } from 'redux-logic';

import {
  loginFailed,
  AuthActions,
  userDetailsSuccess,
  redirectTo,
  changePasswordSuccess,
  changePasswordFailed,
  getUserDetails,
  linkVerificationSuccess,
  linkVerificationFailed,
  setPasswordSuccess,
  setPasswordFailed,
  requestLogout,
  modalToggleRequest,
} from '../actions';
import { ApiHelper } from '../../helpers';
import { showSuccess, showError } from '../../helpers/Toast';
import { ApiRoutes, AppRoutes } from '../../route';
import { AppConfig } from '../../config/AppConfig';
import socketIOClient from 'socket.io-client';

const loginLogic = createLogic({
  type: AuthActions.REQUEST_LOGIN,
  async process({ action }, dispatch, done) {
    const { email = '', password = '', rememberMe = '' } = action.payload || {};
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LOGIN.service,
      ApiRoutes.LOGIN.url,
      ApiRoutes.LOGIN.method,
      ApiRoutes.LOGIN.authenticate,
      undefined,
      { email, password, rememberMe }
    );
    if (!isError) {
      localStorage.setItem('token', responseData.data.token);
      const socket = socketIOClient(AppConfig.SOCKET_ENDPOINT, {
        query: {
          token: responseData.data.token,
        },
        secure: true,
      });
      dispatch(userDetailsSuccess({ user: responseData.data }));
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(loginFailed(messages[0]));
      done();
    }
  },
});

const forgotPasswordLogic = createLogic({
  type: AuthActions.REQUEST_FORGOT_PASSWORD,
  async process({ action }, dispatch, done) {
    const {
      email = '',
      role = '',
      userId = '',
      subDomainName = '',
    } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.FORGOT_PASSWORD.service,
      ApiRoutes.FORGOT_PASSWORD.url,
      ApiRoutes.FORGOT_PASSWORD.method,
      ApiRoutes.FORGOT_PASSWORD.authenticate,
      { email, role, userId, subDomainName },
      undefined
    );
    if (!isError) {
      showSuccess(messages[0]);
      if (
        role !== 'ProgramSite' &&
        role !== 'School' &&
        role !== 'DistrictSuperAdmin' &&
        role !== 'ProgramSuperAdmin'
      )
        dispatch(redirectTo({ path: AppRoutes.HOME }));
      done();
    } else {
      showError(messages[0]);
      dispatch(loginFailed(messages[0]));
      done();
    }
  },
});

const getUserLogic = createLogic({
  type: AuthActions.GET_USER_DETAILS,
  async process({ action }, dispatch, done) {
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_PROFILE_DETAILS.service,
        ApiRoutes.GET_PROFILE_DETAILS.url,
        ApiRoutes.GET_PROFILE_DETAILS.method,
        ApiRoutes.GET_PROFILE_DETAILS.authenticate,
        undefined,
        undefined
      );
    if (!isError) {
      dispatch(
        userDetailsSuccess({
          user: responseData.data,
          district: responseData.district,
        })
      );
      done();
    } else {
      dispatch(redirectTo({ path: AppRoutes.LOGIN }));
      localStorage.removeItem('token');
      done();
    }
  },
});

const changePasswordLogic = createLogic({
  type: AuthActions.CHANGE_PASSWORD_REQUEST,
  async process({ action }, dispatch, done) {
    const { oldPassword = '', password = '', role } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_PASSWORD.service,
      ApiRoutes.CHANGE_PASSWORD.url,
      ApiRoutes.CHANGE_PASSWORD.method,
      ApiRoutes.CHANGE_PASSWORD.authenticate,
      undefined,
      // { oldPassword, newPassword: password },
      action.payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(changePasswordFailed());
      done();
    } else {
      dispatch(changePasswordSuccess());
      if (role === 'ProgramSite') {
        dispatch(
          modalToggleRequest({ programSitesChangePasswordModalOpen: false })
        );
      } else {
        showSuccess('Password updated successfully');
        //dispatch(requestLogout());
      }
      done();
    }
  },
});

const updateProfileLogic = createLogic({
  type: AuthActions.REQUEST_UPDATE_PROFILE,
  async process({ action }, dispatch, done) {
    const { firstName = '', lastName = '' } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROFILE_DETAILS.service,
      ApiRoutes.UPDATE_PROFILE_DETAILS.url,
      ApiRoutes.UPDATE_PROFILE_DETAILS.method,
      ApiRoutes.UPDATE_PROFILE_DETAILS.authenticate,
      undefined,
      { firstName, lastName }
    );
    if (!isError) {
      showSuccess(messages[0]);
      dispatch(getUserDetails());
      done();
    } else {
      showError(messages[0]);
      done();
    }
  },
});

const linkVerificationLogic = createLogic({
  type: AuthActions.LINK_VERIFICATION_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_LINK.service,
      ApiRoutes.VERIFY_LINK.url,
      ApiRoutes.VERIFY_LINK.method,
      ApiRoutes.VERIFY_LINK.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(linkVerificationSuccess(responseData.data));
      done();
    } else {
      dispatch(
        linkVerificationFailed({
          message: messages[0],
        })
      );
      done();
    }
    return;
  },
});

const setPasswordLogic = createLogic({
  type: AuthActions.SET_PASSWORD_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.SET_PASSWORD.service,
      ApiRoutes.SET_PASSWORD.url,
      ApiRoutes.SET_PASSWORD.method,
      ApiRoutes.SET_PASSWORD.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(setPasswordSuccess(responseData));
      showSuccess(messages[0]);
      done();
    } else {
      dispatch(
        setPasswordFailed({
          message: messages[0],
        })
      );
      showError(messages[0]);
      done();
    }
    return;
  },
});

const logoutLogic = createLogic({
  type: AuthActions.LOGOUT_REQUEST,
  async process({ action }, dispatch, done) {
    localStorage.removeItem('token');
    dispatch(redirectTo({ path: AppRoutes.LOGIN }));
    window.location.reload();
    done();
  },
});

export const AuthLogics = [
  loginLogic,
  forgotPasswordLogic,
  logoutLogic,
  getUserLogic,
  changePasswordLogic,
  updateProfileLogic,
  linkVerificationLogic,
  setPasswordLogic,
];
