/** @format */

import { createAction } from 'redux-actions';

export const UnitActions = {
  REQUEST_UNIT_LIST: 'Request to fetch units',
  UNIT_LIST_SUCCESS: 'Units fetched successfully',
  UNIT_LIST_FAILURE: 'Failed to fetched units',
  REQUEST_UNIT_OPTION_LIST: 'Request to fetch unit options list',
  UNIT_OPTION_LIST_SUCCESS: 'Unit options fetched successfully',
  UPDATE_UNIT_OPTIONS: 'Request to update unit option',
  REQUEST_SECTION_LIST_BY_UNIT: 'Request to fetch sections by unit',
  SECTION_LIST_SUCCESS: 'Sections of units fetched successfully',
  SECTION_LIST_FAILURE: 'Failed to fetched sections of unit',
  REQUEST_LESSON_LIST_BY_UNIT: 'Request to fetch lessons by unit',
  LESSON_LIST_SUCCESS: 'Lesson of units fetched successfully',
  LESSON_LIST_FAILURE: 'Failed to fetched lessons of unit',

  UPDATE_UNIT_DATA_ORDER_REQUEST: 'Request to update course data order',
  UPDATE_UNIT_DATA_ORDER_SUCCESS: 'Course data order updated successfully',
  UPDATE_UNIT_DATA_ORDER_FAILURE: 'Failed to update Course data order',
};

export const requestUnitList = createAction(UnitActions.REQUEST_UNIT_LIST);
export const unitListSuccess = createAction(UnitActions.UNIT_LIST_SUCCESS);
export const unitListFailed = createAction(UnitActions.UNIT_LIST_FAILURE);

export const requestSectionListByUnit = createAction(
  UnitActions.REQUEST_SECTION_LIST_BY_UNIT
);
export const sectionListSuccess = createAction(
  UnitActions.SECTION_LIST_SUCCESS
);
export const sectionListFailed = createAction(UnitActions.SECTION_LIST_FAILURE);

export const requestLessonListByUnit = createAction(
  UnitActions.REQUEST_LESSON_LIST_BY_UNIT
);
export const lessonListSuccess = createAction(UnitActions.LESSON_LIST_SUCCESS);
export const lessonListFailed = createAction(UnitActions.LESSON_LIST_FAILURE);

export const requestUnitOptionList = createAction(
  UnitActions.REQUEST_UNIT_OPTION_LIST
);
export const unitOptionListSuccess = createAction(
  UnitActions.UNIT_OPTION_LIST_SUCCESS
);
// export const updateUnitOptions = createAction(UnitActions.UPDATE_UNIT_OPTIONS);

/*
----------------------------------
  Update course data order
----------------------------------
*/

export const updateUnitDataOrderRequest = createAction(
  UnitActions.UPDATE_UNIT_DATA_ORDER_REQUEST
);
export const updateUnitDataOrderSuccess = createAction(
  UnitActions.UPDATE_UNIT_DATA_ORDER_SUCCESS
);
export const updateUnitDataOrderFailed = createAction(
  UnitActions.UPDATE_UNIT_DATA_ORDER_FAILURE
);
