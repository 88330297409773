/** @format */
import { handleActions } from 'redux-actions';
import { GeoGraphicsActions } from '../actions';
import { GeoGraphicsInitialStates } from '../states';

export const GeoGraphicsReducer = handleActions(
  {
    [GeoGraphicsActions.REQUEST_COUNTRIES_LIST]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GeoGraphicsActions.COUNTRIES_LIST_SUCCESS]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      countriesOptions: action.payload.countries,
    }),
    [GeoGraphicsActions.COUNTRIES_LIST_FAILURE]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [GeoGraphicsActions.REQUEST_COUNTIES_LIST]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GeoGraphicsActions.COUNTIES_LIST_SUCCESS]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      ...action.payload,
    }),
    [GeoGraphicsActions.COUNTIES_LIST_FAILURE]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [GeoGraphicsActions.REQUEST_STATES_LIST]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GeoGraphicsActions.STATES_LIST_SUCCESS]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      statesOptions: action.payload.states,
    }),
    [GeoGraphicsActions.STATES_LIST_FAILURE]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [GeoGraphicsActions.REQUEST_CITIES_LIST]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GeoGraphicsActions.CITIES_LIST_SUCCESS]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      citiesOptions: action.payload.cities,
    }),
    [GeoGraphicsActions.CITIES_LIST_FAILURE]: (
      state = GeoGraphicsInitialStates,
      action,
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  GeoGraphicsInitialStates,
);
