/** @format */

import { handleActions } from 'redux-actions';
import { DistrictAdminActions } from '../actions';
import { DistrictAdminInitialStates } from '../states';

export const DistrictAdminReducer = handleActions(
  {
    [DistrictAdminActions.ADD_DISTRICT_ADMIN_REQUEST]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [DistrictAdminActions.ADD_DISTRICT_ADMIN_SUCCESS]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [DistrictAdminActions.ADD_DISTRICT_ADMIN_FAILED]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [DistrictAdminActions.DISTRICT_ADMIN_LIST_REQUEST]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      totalRecords: 0,
    }),
    [DistrictAdminActions.DISTRICT_ADMIN_LIST_SUCCESS]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      districtAdmins: action.payload.districtAdmins,
      totalRecords: action.payload.totalRecords,
    }),
    [DistrictAdminActions.DISTRICT_ADMIN_LIST_FAILED]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [DistrictAdminActions.UPDATE_DISTRICT_ADMIN_REQUEST]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [DistrictAdminActions.UPDATE_DISTRICT_ADMIN_SUCCESS]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [DistrictAdminActions.UPDATE_DISTRICT_ADMIN_FAILED]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
    [DistrictAdminActions.VIEW_DISTRICT_ADMIN_REQUEST]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [DistrictAdminActions.VIEW_DISTRICT_ADMIN_SUCCESS]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      districtAdminData: action.payload.districtAdminData,
    }),
    [DistrictAdminActions.VIEW_DISTRICT_ADMIN_FAILED]: (
      state = DistrictAdminInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  DistrictAdminInitialStates
);
